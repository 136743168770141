import React, { useState, useEffect } from "react";
import CompanySide from "./CompanySide";
import CompanyNavbar from "./CompanyNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { fetchHospitals } from "./CompanyFirebase";

const AllHospitals = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [hospitals, setHospitals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [hospitalsPerPage] = useState(20);
    const [nameFilter, setNameFilter] = useState("");
    const [specialistFilter, setSpecialistFilter] = useState("All");
    const [locationFilter, setLocationFilter] = useState("All");
    const [filteredHospitals, setFilteredHospitals] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const hospitalsData = await fetchHospitals();
                setHospitals(hospitalsData);
                setFilteredHospitals(hospitalsData);
            } catch (error) {
                setError("Error fetching data: " + error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        const applyFilters = () => {
            let filtered = hospitals.filter((hospital) => {
                const nameMatch = hospital.name.toLowerCase().includes(nameFilter.toLowerCase());
                const locationMatch = locationFilter === "All" || hospital.location === locationFilter;
                return nameMatch && locationMatch;
            });

            setFilteredHospitals(filtered);
            setCurrentPage(1);
        };

        applyFilters();
    }, [nameFilter, locationFilter, hospitals]);

    const handleViewProfile = (hospitalId, id) => {
        if(hospitalId === "N/A"){
            navigate(`/company/allDoctors/${id}`, { state: { hospitalId : 'N/A' } });
        }else{
            navigate(`/company/allDoctors/${id}`, { state: { hospitalId : hospitalId  } });
        }
    };

    const indexOfLastHospital = currentPage * hospitalsPerPage;
    const indexOfFirstHospital = indexOfLastHospital - hospitalsPerPage;
    const currentHospitals = filteredHospitals.slice(indexOfFirstHospital, indexOfLastHospital);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const totalPages = Math.ceil(filteredHospitals.length / hospitalsPerPage);

    const renderPagination = () => {
        if (totalPages <= 1) return null;

        const pageNumbers = [];
        for (let i = Math.max(1, currentPage - 1); i <= Math.min(totalPages, currentPage + 1); i++) {
            pageNumbers.push(i);
        }

        return (
            <div className="flex mt-4">
                {currentPage > 1 && (
                    <button
                        onClick={() => paginate(currentPage - 1)}
                        className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1"
                    >
                        &lt;
                    </button>
                )}
                {pageNumbers.map((number) => (
                    <button
                        key={number}
                        onClick={() => paginate(number)}
                        className={`px-4 py-2 ${currentPage === number
                            ? "bg-[#0d47a1] text-white"
                            : "bg-white border text-neutral-800"
                            } rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1`}
                    >
                        {number}
                    </button>
                ))}
                {currentPage < totalPages && (
                    <button
                        onClick={() => paginate(currentPage + 1)}
                        className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1"
                    >
                        &gt;
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className="flex flex-col h-screen">
            <CompanyNavbar />
            <div className="flex flex-1 mt-[4.2rem]">
                <CompanySide
                    open={sidebarOpen}
                    toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
                />
                <div
                    className={`overflow-y-auto flex-1 transition-all duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
                        }`}
                >
                    <div className="container px-4 mx-auto my-10 overflow-auto">
                        <div className="flex flex-col sm:flex-row items-center justify-between mb-4">
                            <div className="mb-2"></div>
                            <h2 className="text-xl font-bold bg-[#0D47A1] text-white p-2 rounded mb-2 sm:mb-0 sm:mr-4 w-full sm:w-auto text-center sm:text-left">
                                <button
                                    type="button"
                                    onClick={() => navigate(-1)}
                                    className="flex items-center justify-center"
                                >
                                    <IoMdArrowBack className="h-6 w-6 font-bold text-white " />
                                    {/* Doctors */}
                                </button>
                            </h2>
                            <div className="flex flex-col justify-end sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full">
                                <input
                                    type="text"
                                    placeholder="Search by name"
                                    className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                                    value={nameFilter}
                                    onChange={(e) => setNameFilter(e.target.value)}
                                />
                                <select
                                    className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                                    value={locationFilter}
                                    onChange={(e) => setLocationFilter(e.target.value)}
                                >
                                    <option value="All">All Locations</option>
                                    <option value="Bangalore">Bangalore</option>
                                    <option value="Delhi">Delhi</option>
                                    <option value="Mumbai">Mumbai</option>
                                    <option value="Kolkata">Kolkata</option>
                                    <option value="Hyderabad">Hyderabad</option>
                                    <option value="Chennai">Chennai</option>
                                </select>
                            </div>
                        </div>

                        <div className="h-[calc(100vh-200px)] overflow-y-auto">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                                {loading && <div>Loading...</div>}
                                {error && <div>Error: {error}</div>}
                                {currentHospitals.map((hospital) => (
                                    <div
                                        key={hospital.id}
                                        className="bg-white border border-gray-200 shadow flex flex-col items-center text-center relative"
                                    >
                                        <div className="p-5 flex-grow flex flex-col justify-between w-full">
                                            <div>
                                                {/* <div className="text-sm font-bold leading-7 underline">
                                                    {hospital.name}
                                                </div> */}
                                                <div className="mt-1 py-1 text-sm font-bold leading-9 bg-violet-100">
                                                    {hospital.hospitalName}
                                                </div>
                                            </div>
                                            <div className="mt-3 flex justify-between items-center">
                                                <p>{hospital.state}</p>
                                                <p>{hospital.city}</p>
                                            </div>
                                            <div className="text-center mt-2">
                                                <button
                                                    onClick={() => handleViewProfile(hospital.id, id)}
                                                    className="px-2 py-1 font-semibold text-white capitalize bg-[#0D47A1] hover:bg-[#ADBBDA]"
                                                >
                                                    View Doctors
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div
                                    className="bg-white border border-gray-200 shadow flex flex-col items-center text-center relative"
                                >
                                    <div className="p-5 flex-grow flex flex-col justify-between w-full">
                                        <div>
                                            {/* <div className="text-sm font-bold leading-7 underline">
                                                N/A
                                            </div> */}
                                            <div className="mt-1 py-1 text-sm font-bold leading-9 bg-violet-100">
                                                Individuals
                                            </div>
                                        </div>
                                        <div className="mt-3 flex justify-between items-center">

                                        </div>
                                        <div className="text-center mt-2">
                                            <button
                                                onClick={() => handleViewProfile("N/A", id)}
                                                className="px-2 py-1 font-semibold text-white capitalize bg-[#0D47A1] hover:bg-[#ADBBDA]"
                                            >
                                                View Doctors
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {renderPagination()}
                            <div className="text-gray-600 dark:text-gray-400 text-sm mb-4 text-end">
                                {`Showing ${currentHospitals.length + 1} out of ${filteredHospitals.length + 1} matches`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" />
        </div>
    );
};

export default AllHospitals;
