import { getFirestore, collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc, deleteDoc, } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject, } from "firebase/storage";
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword, } from "firebase/auth";
import { db, storage } from "../firebase";
import { format, startOfToday, endOfToday, startOfTomorrow, endOfTomorrow, startOfWeek, endOfWeek, startOfMonth, endOfMonth, } from "date-fns";

//Function to fetch Hospital name
export const fetchHospitalData = async (id) => {
  try {
    const hospitalDoc = await getDoc(doc(db, "hospitals", id));
    if (hospitalDoc.exists()) {
      return { id: hospitalDoc.id, name: hospitalDoc.data().name, hospitalName: hospitalDoc.data().hospitalName, ...hospitalDoc.data(), };
    } else {
      console.log("No such document!");
      return;
    }
  } catch (error) {
    console.error("Error fetching hospital:", error);
    return;
  }
};

//Add Doctor into Hospital
export const addDoctorInHospital = async (doctordata, id) => {
  try {
    const customId = `${doctordata.name}_${doctordata.location}`;
    const customDocRef = doc(db, "doctors", customId);
    await setDoc(customDocRef, { ...doctordata, hospitalId: id, role: "Doctor", });
    return true;
  } catch (error) {
    console.error("Error adding document:", error);
    throw error;
  }
};

//upload Image in Doctor
export const uploadImageToDoctor = async (file) => {
  const storageRef = ref(storage, `images/doctors/${file.name}`);
  try {
    const uploadTaskSnapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(uploadTaskSnapshot.ref);
    return url;
  } catch (error) {
    console.error("Error uploading image:", error);
    return null;
  }
};

//fetch Doctors
export const fetchHospitalDoctor = async (doctorId) => {
  try {
    const doctorRef = doc(db, "doctors", doctorId);
    const docSnap = await getDoc(doctorRef);
    if (docSnap.exists()) {
      const doctorData = { id: docSnap.id, ...docSnap.data() };
      if (doctorData.image) {
        const imageUrl = await getDownloadURL(ref(storage, doctorData.image));
        return { doctorData, imageUrl };
      }
      return { doctorData, imageUrl: null };
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching doctor:", error);
    throw error;
  }
};

//uploading image in edit doctor
export const editDoctorImageUpload = async (file) => {
  const storageRef = ref(storage, `images/${file.name}`);
  try {
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

//Removing Image in Edit Doctor
export const removeImageInEdits = async (imagePath) => {
  try {
    if (imagePath) { await deleteObject(ref(storage, imagePath)); return true; }
    return false;
  } catch (error) {
    console.error("Error removing image:", error);
    throw error;
  }
};

//update Doctor Data
export const updateDoctorData = async (doctorId, doctorData, imageFile) => {
  try {
    const doctorRef = doc(db, "doctors", doctorId);
    if (imageFile) {
      const storageRef = ref(storage, `images/${imageFile.name}`);
      await uploadBytes(storageRef, imageFile);
      const downloadURL = await getDownloadURL(storageRef);
      doctorData.image = `images/${imageFile.name}`;
    } else if (!doctorData.imageUrl) {
      doctorData.image = "";
    }
    await updateDoc(doctorRef, doctorData);
    return true;
  } catch (error) {
    console.error("Error updating document:", error);
    throw error;
  }
};

//Function to fetch doctors by HospitalId
export const fetchDoctorsByHospitalId = async (hospitalId) => {
  try {
    const doctorsCollection = collection(db, "doctors");
    const q = query(doctorsCollection, where("hospitalId", "==", hospitalId));
    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), }));
  } catch (error) {
    throw new Error("Error fetching doctors: " + error.message);
  }
};

// Function to delete a doctor by ID
export const deleteDoctorById = async (doctorId) => {
  try {
    await deleteDoc(doc(db, "doctors", doctorId));
  } catch (error) {
    throw new Error("Error deleting doctor profile: " + error.message);
  }
};

//fetch Hospital Meetings
export const fetchHospitalMeeting = async (id, searchDate, searchDoctorName, selectedTimeFilter) => {
  try {
    const now = new Date();

    const doctorsCollection = collection(db, "doctors");
    const doctorsQuery = query(doctorsCollection, where("hospitalId", "==", id));
    const doctorsSnapshot = await getDocs(doctorsQuery);
    const doctorIds = doctorsSnapshot.docs.map((doc) => doc.id);

    const meetingCollection = collection(db, "scheduleMeeting");
    const meetingsQuery = query(meetingCollection, where("doctorID", "in", doctorIds));
    const meetingsSnapshot = await getDocs(meetingsQuery);

    const fetchCompanyData = async (companyId) => {
      const companyDocRef = doc(db, "companies", companyId);
      const companyDocSnapshot = await getDoc(companyDocRef);
      return companyDocSnapshot.exists() ? companyDocSnapshot.data() : null;
    };

    const fetchDoctorData = async (doctorId) => {
      const doctorDocRef = doc(db, "doctors", doctorId);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      return doctorDocSnapshot.exists() ? doctorDocSnapshot.data() : null;
    };

    const fetchAssignedData = async (assigned) => {
      let assignedName, assignedRole;
      try {
        const companyDocRef = doc(db, "companies", assigned);
        const companyDocSnapshot = await getDoc(companyDocRef);
        if (companyDocSnapshot.exists()) {
          assignedName = companyDocSnapshot.data().name;
          assignedRole = companyDocSnapshot.data().role;
        } else {
          const userDocRef = doc(db, "users", assigned);
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            assignedName = `${userData.firstName} ${userData.lastName}`;
            assignedRole = userData.role;
          } else {
            console.error(`No document found with ID ${assigned}`);
          }
        }
      } catch (error) {
        console.error("Error fetching assigned data:", error);
      }
      return { assignedName, assignedRole };
    };

    const meetingsData = await Promise.all(
      meetingsSnapshot.docs.map(async (doc) => {
        const meetingData = doc.data();
        const companyData = await fetchCompanyData(meetingData.companyID);
        const companyName = companyData ? companyData.companyName : "Unknown Company";
        const { assignedName, assignedRole } = await fetchAssignedData(meetingData.assigned);
        const doctorData = await fetchDoctorData(meetingData.doctorID);
        const doctorName = doctorData ? doctorData.name : "Unknown Doctor";
        const location = doctorData ? doctorData.location : "Unknown Location";

        return {
          id: doc.id,
          companyName,
          assignedName,
          assignedRole,
          doctorName,
          location,
          ...meetingData,
        };
      })
    );

    const filteredMeetings = meetingsData.filter((meeting) => {
      const matchesDate = !searchDate || format(new Date(meeting.date), "yyyy-MM-dd") === searchDate;
      const matchesDoctorName = !searchDoctorName || meeting.doctorName.toLowerCase().includes(searchDoctorName.toLowerCase());
      return matchesDate && matchesDoctorName;
    });

    // Apply time filter based on selectedTimeFilter
    const applyTimeFilter = (meeting) => {
      const meetingDateTime = new Date(`${meeting.date} ${meeting.time}`);
      switch (selectedTimeFilter) {
        case "today":
          return (meetingDateTime >= startOfToday() && meetingDateTime <= endOfToday());
        case "tomorrow":
          return (meetingDateTime >= startOfTomorrow() && meetingDateTime <= endOfTomorrow());
        case "thisWeek":
          return (meetingDateTime >= startOfWeek(now) && meetingDateTime <= endOfWeek(now));
        case "thisMonth":
          return (meetingDateTime >= startOfMonth(now) && meetingDateTime <= endOfMonth(now));
        default:
          return true;
      }
    };

    // Filter meetings based on time filter
    const timeFilteredMeetings = filteredMeetings.filter(applyTimeFilter);

    const filteredUpcomingMeetings = timeFilteredMeetings.filter((meeting) => {
      return meeting.status !== "completed" && meeting.status !== "missed" ||
        (meeting.status === "completed" && !meeting.doctorFeedback);
    });

    const filteredMissedMeetings = timeFilteredMeetings.filter((meeting) => {
      return meeting.status === "missed";
    });

    const filteredCompletedMeetings = timeFilteredMeetings.filter((meeting) => {
      return meeting.status === "completed" && meeting.doctorFeedback;
    });

    const sortMeetings = (meetings) =>
      meetings.sort((a, b) => new Date(`${a.date} ${a.time}`) - new Date(`${b.date} ${b.time}`));

    return {
      upcomingMeetings: sortMeetings(filteredUpcomingMeetings),
      missedMeetings: sortMeetings(filteredMissedMeetings),
      completedMeetings: sortMeetings(filteredCompletedMeetings),
    };
  } catch (error) {
    console.error("Error fetching meetings:", error);
    throw error;
  }
};

//update meeting
export const updateHospitalMeeting = async (meetingId, selectedDate, selectedTime, meetingType) => {
  try {
    const meetingDocRef = doc(db, "scheduleMeeting", meetingId);

    const meetingDocSnapshot = await getDoc(meetingDocRef);
    if (!meetingDocSnapshot.exists()) {
      throw new Error(`Document with ID ${meetingId} does not exist`);
    }

    const adjustedDate = new Date(selectedDate);
    const ISTOffset = 330;
    adjustedDate.setMinutes(adjustedDate.getMinutes() + ISTOffset);
    const formattedDate = adjustedDate.toISOString().split("T")[0];

    await updateDoc(meetingDocRef, {
      date: formattedDate,
      time: selectedTime,
      status: "Rescheduled",
      meetingType: meetingType,
    });
  } catch (error) {
    console.error("Error updating schedule meeting:", error);
    throw error;
  }
};

//delete meeting
export const deleteHospitalMeeting = async (meetingId) => {
  try {
    await deleteDoc(doc(db, "scheduleMeeting", meetingId));
  } catch (error) {
    console.error("Error deleting Meeting:", error);
    throw error;
  }
};

//accept meeting
export const acceptHospitalMeeting = async (meetingId) => {
  try {
    const meetingDocRef = doc(db, "scheduleMeeting", meetingId);
    await updateDoc(meetingDocRef, { status: "Accepted" });
  } catch (error) {
    console.error("Error accepting meeting:", error);
    throw error;
  }
};

//function to send reply
export const sendHospitalReply = async (currentConversation, replyMessage, id) => {
  if (!replyMessage.trim()) {
    throw new Error("Message cannot be empty!");
  }

  try {
    if (!currentConversation || !currentConversation.companyID) {
      throw new Error("Conversation or company ID not found.");
    }

    const replyData = {
      companyID: currentConversation.companyID,
      doctorID: currentConversation.doctorID,
      messageId: currentConversation.messages[0].messageId,
      sentId: id,
      messages: replyMessage,
      sentBy: "doctor",
      timestamp: new Date(),
    };

    const customId = `${currentConversation.doctorID}_${currentConversation.companyID}_${Date.now()}`;
    const customDocRef = doc(db, "messages", customId);
    await setDoc(customDocRef, replyData);

    const timestamp = replyData.timestamp;
    const date = format(timestamp, "dd/MM/yyyy");
    const time = format(timestamp, "hh:mm:ss a");

    const newMessage = {
      messageId: replyData.messageId,
      id: customId,
      message: replyData.messages,
      sentBy: replyData.sentBy,
      date,
      time,
    };

    return {
      newMessage,
      updatedReplyData: { ...replyData, timestamp, date, time },
    };
  } catch (error) {
    console.error("Error sending reply:", error);
    throw new Error("Failed to send reply. Please try again.");
  }
};

//function to fetch messages
export const fetchHospitalMessages = async (id, doctorIdFromState) => {
  try {
    const doctorsCollection = collection(db, "doctors");
    const doctorsQuery = query(doctorsCollection, where("hospitalId", "==", id));
    const doctorsSnapshot = await getDocs(doctorsQuery);
    const doctorIds = doctorsSnapshot.docs.map((doc) => doc.id);

    const messageRef = collection(db, "messages");
    let q = query(messageRef, where("doctorID", "in", doctorIds));

    const querySnapshot = await getDocs(q);

    const fetchCompanyData = async (companyID) => {
      const companyDocRef = doc(db, "companies", companyID);
      const companyDocSnapshot = await getDoc(companyDocRef);
      return companyDocSnapshot.exists() ? companyDocSnapshot.data() : null;
    };

    const fetchDoctorData = async (doctorId) => {
      const doctorDocRef = doc(db, "doctors", doctorId);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      return doctorDocSnapshot.exists() ? doctorDocSnapshot.data() : null;
    };

    const fetchAssignedData = async (messageId) => {
      let assignedName;

      try {
        const companyDocRef = doc(db, "companies", messageId);
        const companyDocSnapshot = await getDoc(companyDocRef);

        if (companyDocSnapshot.exists()) {
          assignedName = companyDocSnapshot.data().name;
        } else {
          const userDocRef = doc(db, "users", messageId);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            assignedName = `${userData.firstName} ${userData.lastName}`;
          } else {
            console.error(`No document found with ID ${messageId}`);
          }
        }
      } catch (error) {
        console.error("Error fetching assigned data:", error);
      }

      return assignedName;
    };

    const groupedMessages = {};
    const promises = querySnapshot.docs.map(async (doc) => {
      const messageData = doc.data();
      const companyData = await fetchCompanyData(messageData.companyID);
      const assignedName = await fetchAssignedData(messageData.messageId);
      const companyName = companyData ? companyData.companyName : "Unknown Company Name";
      const representativeName = companyData ? companyData.name : "Unknown Name";
      const doctorData = await fetchDoctorData(messageData.doctorID);
      const doctorName = doctorData ? doctorData.name : "Unknown Doctor";

      const key = `${messageData.doctorID}_${messageData.companyID}`;
      if (!groupedMessages[key]) {
        groupedMessages[key] = {
          companyName,
          representativeName,
          doctorName,
          assignedName,
          doctorID: messageData.doctorID,
          companyID: messageData.companyID,
          messages: [],
          recentMessage: {
            text: "",
            isCompany: false,
            date: "",
            time: "",
          },
        };
      }

      const timestamp = messageData.timestamp?.toDate();
      const date = timestamp ? format(timestamp, "dd/MM/yyyy") : "N/A";
      const time = timestamp ? format(timestamp, "hh:mm:ss a") : "N/A";

      groupedMessages[key].messages.push({
        messageId: messageData.messageId,
        id: doc.id,
        message: messageData.messages,
        sentBy: messageData.sentBy,
        sentId: messageData.sentId,
        date,
        time,
      });

      if (!groupedMessages[key].recentMessage.timestamp || timestamp > groupedMessages[key].recentMessage.timestamp) {
        groupedMessages[key].recentMessage = {
          text: messageData.messages,
          isDoctor: messageData.sentBy === "doctor",
          date,
          time,
          timestamp,
        };
      }
    });

    await Promise.all(promises);

    const messagesArray = Object.keys(groupedMessages).map(
      (key) => groupedMessages[key]
    );
    const sortedMessages = sortMessagesByTimestamp(messagesArray);

    if (doctorIdFromState) {
      const conversation = sortedMessages.find((conv) => conv.companyID === doctorIdFromState);
      if (conversation) {
        return { sortedMessages, conversation };
      }
    }
    return { sortedMessages };
  } catch (error) {
    console.error("Error fetching schedule messages:", error);
  }
};

//sorted messages
const sortMessagesByTimestamp = (messagesArray) => {
  return messagesArray.sort((a, b) => {
    const timestampA = a.recentMessage.timestamp;
    const timestampB = b.recentMessage.timestamp;
    if (timestampA && timestampB) {
      return timestampB - timestampA;
    }
    return 0;
  });
};

// Update hospital data
export const updateHospitalData = async (id, newData) => {
  const db = getFirestore();
  const hospitalRef = doc(db, "hospitals", id);
  await updateDoc(hospitalRef, newData);
};

//update password
export const updateHospitalPassword = async (user, oldPassword, newPassword) => {
  try {
    const credential = EmailAuthProvider.credential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
    return true;
  } catch (error) {
    console.error("Error updating password:", error);
    return false;
  }
};

export const updateMeetingStatus = async (selectedMeetingId, doctorFeedback) => {
  const meetingDocRef = doc(db, "scheduleMeeting", selectedMeetingId);
  const updateData = { doctorFeedback: doctorFeedback, };
  await updateDoc(meetingDocRef, updateData);
};
