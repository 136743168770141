import React, { useState, useEffect } from "react";
import HospitalSide from "./HospitalSide";
import HospitalNavbar from "./HospitalNavbar";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import { FaEye, FaRegEdit } from "react-icons/fa";
import { RiLoginCircleFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { FaArrowRight } from "react-icons/fa";
import { fetchDoctorsByHospitalId, deleteDoctorById } from "./HospitalFirebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HospitalDoctor = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(30);
  const [nameFilter, setNameFilter] = useState("");
  const [specialistFilter, setSpecialistFilter] = useState("All");
  const [locationFilter, setLocationFilter] = useState("All");
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { id } = useParams();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const doctorsData = await fetchDoctorsByHospitalId(id);
        setDoctors(doctorsData);
      } catch (error) {
        setError("Error fetching doctors: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDoctors();
  }, [id]);

  const handleDeleteProfile = async (doctorId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this profile?"
    );

    if (confirmed) {
      try {
        await deleteDoctorById(doctorId);
        setDoctors((prevDoctors) =>
          prevDoctors.filter((doctor) => doctor.id !== doctorId)
        );
      } catch (error) {
        console.error("Error deleting doctor profile:", error);
      }
    }
  };

  useEffect(() => {
    const applyFilters = () => {
      let filtered = doctors.filter((doctor) => {
        const nameMatch = doctor.name
          .toLowerCase()
          .includes(nameFilter.toLowerCase());

        const specialistMatch =
          specialistFilter === "All" || doctor.specialist === specialistFilter;

        const locationMatch =
          locationFilter === "All" || doctor.location === locationFilter;

        return nameMatch && specialistMatch && locationMatch;
      });

      setFilteredDoctors(filtered);
    };

    applyFilters();
  }, [nameFilter, specialistFilter, locationFilter, doctors]);

  const handleViewProfile = (doctorId) => {
    navigate(`/doctor/${doctorId}`);
  };

  const handleEditProfile = async (doctorId) => {
    navigate(`/hospital/edit-doctor/${id}`, { state: { doctorId } });
  };

  const indexOfLastDoctor = currentPage * perPage;
  const indexOfFirstDoctor = indexOfLastDoctor - perPage;
  const currentDoctors = filteredDoctors.slice(
    indexOfFirstDoctor,
    indexOfLastDoctor
  );

  let serialNumber = indexOfFirstDoctor + 1;

  const renderDoctors = currentDoctors.map((doctor) => {
    const sn = serialNumber++;
    return (
      <tr className="border-b border-gray-200" key={doctor.id}>
        <td scope="row" className="px-6 py-4">
          {sn}
        </td>{" "}
        {/* Add S.N. here */}
        <td
          scope="row"
          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50"
        >
          {doctor.name}
        </td>
        <td className="px-6 py-4">{doctor.location}</td>
        <td className="px-6 py-4 bg-gray-50">{doctor.specialist}</td>
        <td className="px-6 py-4">
          <button
            type="button"
            className="text-white bg-[#0D47A1] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2"
            onClick={() => handleViewProfile(doctor.id)}
          >
            <FaEye />
          </button>
          <button
            type="button"
            className="text-white bg-[#0D47A1] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2"
            onClick={() => handleEditProfile(doctor.id)}
          >
            <FaRegEdit />
          </button>
          <button
            type="button"
            className="text-white bg-[#0D47A1] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2"
            onClick={() => handleDeleteProfile(doctor.id)}
          >
            <MdDelete />
          </button>
          <button
            type="button"
            onClick={() => navigateToDoctorDashboard(doctor.id)}
            className="text-white bg-[#0D47A1] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2"
          >
            <RiLoginCircleFill />
          </button>
        </td>
      </tr>
    );
  });

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredDoctors.length / perPage);

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    for (
      let i = Math.max(1, currentPage - 1);
      i <= Math.min(totalPages, currentPage + 1);
      i++
    ) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex mt-4">
        {currentPage > 1 && (
          <button
            onClick={() => paginate(currentPage - 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-[#0D47A1] focus:ring-opacity-50 mx-1"
          >
            &lt;
          </button>
        )}
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-4 py-2 ${currentPage === number
                ? "bg-gradient-to-br from-[#0D47A1] to-[#8595C3] text-white"
                : "bg-white border text-neutral-800"
              } rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-[#0D47A1] focus:ring-opacity-50 mx-1`}
          >
            {number}
          </button>
        ))}
        {currentPage < totalPages && (
          <button
            onClick={() => paginate(currentPage + 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-[#0D47A1] focus:ring-opacity-50 mx-1"
          >
            &gt;
          </button>
        )}
      </div>
    );
  };

  const navigateToDoctorDashboard = async(doctorId) => {
    toast.success("Going to Doctors Dashboard!", {autoClose: 2000, });
    setTimeout(() => {
      navigate(`/doctorDashboard/${doctorId}`, { state: { from: 'hospital', userId: id } });
    }, 2000);
  }

  return (
    <div className="flex flex-col h-screen">
      <HospitalNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <HospitalSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container px-4 mx-auto my-10">
            <div className="border mt-4 p-2">
              <div className="flex justify-between items-center mb-5">
                <h2 className="text-center text-3xl font-bold">Doctor</h2>
                <Link
                  to={`/hospital/add-doctor/${id}`}
                  className="bg-[#0D47A1] hover:bg-[#8595C3] text-white font-bold py-2 px-4 rounded"
                >
                  Add Doctor
                </Link>
              </div>

              <div className="flex justify-end items-center py-2.5 pr-2.5 pl-5 mt-6 bg-white rounded max-md:flex-wrap max-md:max-w-full">
                <div className="flex flex-col justify-end sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full">
                  <input
                    type="text"
                    placeholder="Search by name"
                    className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                  />
                  <select
                    className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                    value={specialistFilter}
                    onChange={(e) => setSpecialistFilter(e.target.value)}
                  >
                    <option value="All">All Specializations</option>
                    <option value="Orthopaedic">Orthopaedic</option>
                    <option value="Cardiologist">Cardiologist</option>
                    <option value="Gynaecologist">Gynaecologist</option>
                    <option value="Radiologist">Radiologist</option>
                    <option value="Dermatologist">Dermatologist</option>
                    <option value="Oncology">Oncology</option>
                    <option value="Neurology">Neurology</option>
                    <option value="Urology">Urology</option>
                    <option value="Ophthalmology">Ophthalmology</option>
                    <option value="Paediatric">Paediatric</option>
                  </select>
                  <select
                    className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                    value={locationFilter}
                    onChange={(e) => setLocationFilter(e.target.value)}
                  >
                    <option value="All">All Locations</option>
                    <option value="Bangalore">Bangalore</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Mumbai">Mumbai</option>
                    <option value="Kolkata">Kolkata</option>
                    <option value="Hyderabad">Hyderabad</option>
                    <option value="Chennai">Chennai</option>
                  </select>
                </div>
              </div>

              <div className="overflow-auto mt-3">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="text-xs text-gray-700 font-bold border-t border-gray-200 text-left uppercase">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm tracking-wider"
                      >
                        S.N.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm bg-gray-50 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm uppercase tracking-wider"
                      >
                        Location
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm bg-gray-50 uppercase tracking-wider"
                      >
                        Specialist
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {renderDoctors}
                  </tbody>
                </table>
              </div>

              <div>{renderPagination()}</div>
              <div className="text-gray-600 text-sm mb-4 text-end">
                {`Showing ${currentDoctors.length} out of ${filteredDoctors.length} matches`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default HospitalDoctor;
