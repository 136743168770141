import React, { useState, useEffect } from "react";
import CompanyNavbar from "./CompanyNavbar";
import CompanySide from "./CompanySide";
import { MdCreditScore } from "react-icons/md";
import { IoMdArrowBack } from "react-icons/io";
import { useParams, useNavigate } from "react-router-dom";
import "../style/Company.css";
import { storage } from "../firebase";
import { fetchCompany, fetchMeetings } from "./CompanyFirebase";

const CompanyCredits = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [totalCredits, setTotalCredits] = useState(0);
  const [consumedCredits, setConsumedCredits] = useState(0);
  const [scheduleCredits, setScheduleCredits] = useState(0);
  const [availableCredits, setAvailableCredits] = useState(0);
  const { id } = useParams();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const loadData = async () => {
      const { companyData, imageUrl } = await fetchCompany(id, storage);

      const currenttotalcredits = companyData.credits || 0;

      const { completedMeetings, upcomingMeetings} = await fetchMeetings(id);
      const currentconsumedCredits = completedMeetings.length;
      const currentscheduledCredits = upcomingMeetings.length;

      const currentavailablecredits = Number(
        currenttotalcredits - currentconsumedCredits - currentscheduledCredits
      );

      setTotalCredits(currenttotalcredits);
      setAvailableCredits(currentavailablecredits);
      setConsumedCredits(currentconsumedCredits);
      setScheduleCredits(currentscheduledCredits);
    };

    loadData();
  }, [id]);

  return (
    <div className="flex flex-col h-screen">
      <CompanyNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <CompanySide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          <div className="container px-4 mx-auto mt-3 mb-5">
            <div className="flex items-center justify-between" id="edittexts">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
              >
                <IoMdArrowBack className="h-6 w-6 font-bold text-[#0D47A1]" />
              </button>
              <h2 className="flex-grow text-[1.5rem] my-5 font-bold text-center uppercase">
                Credits
              </h2>
            </div>
            <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
              <div className="bg-white border shadow-sm rounded p-3">
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-gray-50 rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <MdCreditScore className="text-[#0D47A1] h-6 w-6" />
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-400">Total Credit Purchase</div>
                    <div className="text-2xl font-bold text-black">
                      {totalCredits}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white border shadow-sm rounded p-3">
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-gray-50 rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <MdCreditScore className="text-[#0D47A1] h-6 w-6" />
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-400">Available Credits</div>
                    <div className="text-2xl font-bold text-black">
                      {availableCredits}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white border shadow-sm rounded p-3">
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-gray-50 rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <MdCreditScore className="text-[#0D47A1] h-6 w-6" />
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-400">Schedule Credits</div>
                    <div className="text-2xl font-bold text-black">
                      {scheduleCredits}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white border shadow-sm rounded p-3">
                <div className="flex space-x-4 items-center">
                  <div>
                    <div className="bg-gray-50 rounded-full w-12 h-12 text-rose-300 flex justify-center items-center">
                      <MdCreditScore className="text-[#0D47A1] h-6 w-6" />
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-400">Consumed Credits</div>
                    <div className="text-2xl font-bold text-black">
                      {consumedCredits}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white border shadow-sm rounded p-3">
                <div className="flex space-x-4 items-center justify-center m-auto">
                  <button className="px-4 py-2 bg-[#0D47A1] text-white rounded-md">
                    Buy Credits
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyCredits;
