import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MrNavbar from "./MrNavbar";
import MrSide from "./MrSide";
import { FaChartLine } from "react-icons/fa";
import { PiChartPieSliceFill } from "react-icons/pi";
import { useParams } from "react-router-dom";
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, BarElement, PointElement, Title, Tooltip, Legend, } from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { fetchMeetingsChartData, fetchAssignedUsersChart } from "./MrFirebase";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const MrAnalytics = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [chartData, setChartData] = useState({});
  const [userData, setUserData] = useState({});
  const { id } = useParams();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {

    const fetchMeetingsChart = async () => {
      try {
        const { meetingsByMonth, missedMeetingsByMonth } = await fetchMeetingsChartData(id);
        const currentDate = new Date();
        const months = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        // Calculate the range: previous month, current month, and next 4 months
        const desiredMonths = [];
        for (let i = -1; i < 5; i++) {
          const date = new Date(currentDate.getFullYear(), currentDate.getMonth() + i, 1);
          const month = date.toLocaleString("default", { month: "short" });
          const year = date.getFullYear();
          desiredMonths.push(`${month} ${year}`);
        }

        const totalMeetingCounts = desiredMonths.map(
          (label) => meetingsByMonth[label] || 0
        );

        const missedMeetingCounts = desiredMonths.map(
          (label) => missedMeetingsByMonth[label] || 0
        );

        const updatedChartData = {
          labels: desiredMonths,
          datasets: [
            {
              label: "Total Meetings",
              data: totalMeetingCounts,
              fill: false,
              borderColor: "rgba(119,150,230,1)",
              borderWidth: 2,
              tension: 0.1,
              pointStyle: "rectRounded",
              pointBorderWidth: 2,
            },
            {
              label: "Missed Meetings",
              data: missedMeetingCounts,
              fill: false,
              borderColor: "rgba(255,99,132,1)",
              borderWidth: 2,
              tension: 0.1,
              pointStyle: "rectRounded",
              pointBorderWidth: 2,
            },
          ],
        };
        setChartData(updatedChartData);
      } catch (error) {
        console.error("Error fetching schedule meetings:", error);
      }
    };

    const fetchAssignedUsersData = async () => {
      try {
        const assignedData = await fetchAssignedUsersChart(id);

        const userData = {
          labels: assignedData.map((data) => data.assignedName),
          datasets: [
            {
              label: "Scheduled Meetings",
              data: assignedData.map((data) => data.count),
              backgroundColor: ["rgba(119,150,230,1)", "rgba(61,82,161,1)"],
              borderColor: ["rgba(119,150,230,1)", "rgba(61,82,161,1)"],
              borderWidth: 1,
            },
          ],
        };
        setUserData(userData);
      } catch (error) {
        console.error("Error fetching assigned users data:", error);
      }
    };

    fetchMeetingsChart();
    fetchAssignedUsersData();

    return () => { };
  }, [id]);

  return (
    <div className="flex flex-col h-screen">
      <MrNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <MrSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container px-4 mx-auto my-4 grid grid-cols-1 lg:grid-cols-2 gap-4">


            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <FaChartLine className="mr-2" /> Meetings
                </h2>
              </div>
              {/* <Line data={chartData} /> */}
              {Object.keys(chartData).length > 0 && <Line data={chartData} />}
            </div>

            <div className="bg-white p-2 rounded shadow-md">
              <div className="bg-[#0D47A1] text-white p-2">
                <h2 className="text-lg font-bold flex items-center">
                  <PiChartPieSliceFill className="mr-2" /> Doctors
                </h2>
              </div>
              {Object.keys(userData).length > 0 && (
                <Bar
                  data={userData}
                  options={{
                    indexAxis: "y",
                    elements: {
                      bar: {
                        barPercentage: 0.8,
                        categoryPercentage: 1.0,
                      },
                    },
                    scales: {
                      y: {
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MrAnalytics;
