import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DoctorNavbar from "./DoctorNavbar";
import DoctorSide from "./DoctorSide";
import Image from "../../assets/img/female-doctor.png";
import { IoMdArrowBack } from "react-icons/io";
import { fetchDoctor } from "./DoctorFirebase";
import { auth, storage } from "../firebase";

const ProfileDoctor = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [doctor, setDoctor] = useState(null);
  const { id } = useParams();

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchDoctorsData = async () => {
      try {
        const { doctorData } = await fetchDoctor(id, storage);
        setDoctor(doctorData);
      } catch (error) {
        console.log("Error fetching doctor :", error);
      }
    };

    fetchDoctorsData();
  }, [id]);


  return (
    <div className="flex flex-col h-screen">
      <DoctorNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <DoctorSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          {/* Your content goes here */}
          <div className="container px-4 mx-auto my-5">
            <div className="flex items-center justify-between">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
              >
                <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
              </button>
            </div>
            {doctor && (
              <div className="overflow-hidden mt-[10px]">
                <div className="flex flex-col pt-[4rem] px-2 bg-[#0D47A1] rounded">
                  <div className="flex flex-col bg-[#E3E9E9] px-2 pb-2 rounded text-base text-neutral-800 max-md:max-w-full mb-4">
                    <div className="py-2 font-sans leading-8 max-md:max-w-full grid grid-cols-1 md:grid-cols-5 gap-4">
                      {/* <div className="grid grid-cols-5 gap-4"> */}
                      <div className="col-span-2 ...">
                        <div className="flex">
                          <div>
                            <div className="flex-shrink w-16 h-16 rounded">
                              {doctor.image ? (
                                <img
                                  src={doctor.image}
                                  alt={`Profile of ${doctor.name}`}
                                  className="w-full h-full"
                                />
                              ) : (
                                <div className="flex-shrink w-16 h-16 rounded">
                                  {/* Placeholder image */}
                                  <img
                                    src={Image}
                                    className="w-full h-full "
                                    alt="User Profile"
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="px-4 md:flex-grow my-auto">
                            <h1 className="text-lg font-semibold text-gray-800 ">
                              {doctor.name}
                            </h1>
                            <p className="text-sm text-gray-500">
                              {doctor.specialist}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-3 ... my-auto">
                        <div className="flex-grow">
                          <div className="flex">
                            <h1 className="text-lg font-semibold text-gray-800 ">
                              Current Position
                            </h1>
                          </div>

                          <p className="text-sm text-gray-500">
                            {doctor.currentPosition}
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col p-4 mt-[3rem] bg-white border shadow-2xl rounded">
                  <div className="flex-grow">
                    <h1 className="text-xl font-semibold text-gray-800 mb-3">
                      About
                    </h1>
                    <p className="text-md text-gray-500 mb-2">{doctor.about}</p>
                  </div>
                </div>

                <div className="grid gap-4 md:grid-cols-2 mt-4">
                  <div className="bg-white border shadow-2xl rounded p-4">
                    <div className="flex-grow mb-[2rem] mt-4 ">
                      <h1 className="text-xl font-semibold">Experience</h1>

                      {doctor.experience_1 && (
                        <div className="mt-5 flex items-center">
                          <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                            A
                          </button>
                          <div className="px-5">
                            <p className="text-sm ">{doctor.experience_1}</p>
                          </div>
                        </div>
                      )}

                      {doctor.experience_2 && (
                        <div className="mt-5 flex items-center">
                          <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                            B
                          </button>
                          <div className="px-5">
                            <p className="text-sm ">{doctor.experience_2}</p>
                          </div>
                        </div>
                      )}

                      {doctor.experience_3 && (
                        <div className="mt-5 flex items-center">
                          <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                            C
                          </button>
                          <div className="px-5">
                            <p className="text-sm ">{doctor.experience_3}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="bg-white border shadow-2xl rounded p-4">
                    <div className="flex-grow mb-[2rem] mt-4 ">
                      <h1 className="text-xl font-semibold">Education</h1>
                      {doctor.education_1 && (
                        <div className="mt-5 flex items-center">
                          <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                            A
                          </button>
                          <div className="px-5">
                            <p className="text-sm ">{doctor.education_1}</p>
                          </div>
                        </div>
                      )}

                      {doctor.education_2 && (
                        <div className="mt-5 flex items-center">
                          <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                            B
                          </button>
                          <div className="px-5">
                            <p className="text-sm ">{doctor.education_2}</p>
                          </div>
                        </div>
                      )}

                      {doctor.education_3 && (
                        <div className="mt-5 flex items-center">
                          <button className="bg-white shadow px-3 border text-[#8697C4] font-semibold rounded-full h-8 w-8 flex items-center justify-center">
                            C
                          </button>
                          <div className="px-5">
                            <p className="text-sm ">{doctor.education_3}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDoctor;
