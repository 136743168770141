import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import MrSide from "./MrSide";
import MrNavbar from "./MrNavbar";
import Calendar from "react-calendar";
import Swal from "sweetalert2";
import { FaEdit, FaCheck } from "react-icons/fa";
import { SiGooglemeet } from "react-icons/si";
import { IoSearchSharp } from "react-icons/io5";
import "../style/Company.css";
import { format } from "date-fns";
import { LuCalendarClock } from "react-icons/lu";
import { RiCalendarCloseLine } from "react-icons/ri";
import { FaRegCalendarCheck, FaClock, FaCalendarAlt } from "react-icons/fa";
import {
  fetchMeetings,
  updateMeeting,
  acceptMeeting,
  updateMeetingStatus,
  fetchUserCompanyId,
  checkConflictMeetings,
} from "./MrFirebase";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MrMeetingsInfo = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [missedMeetings, setMissedMeetings] = useState([]);
  const [completedMeetings, setCompletedMeetings] = useState([]);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedMeetingId, setSelectedMeetingId] = useState(null);
  const { id } = useParams();
  const [currentUpcomingPage, setCurrentUpcomingPage] = useState(1);
  const [currentMissedPage, setCurrentMissedPage] = useState(1);
  const [currentCompletedPage, setCurrentCompletedPage] = useState(1);
  const meetingsPerPage = 10;
  const [searchDate, setSearchDate] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [meetingType, setMeetingType] = useState("virtual");
  const [tab, setTab] = useState("meeting");
  const [meetingStatus, setMeetingStatus] = useState("");
  const [mrFeedback, setMrFeedback] = useState("");
  const [calendarTab, setCalendarTab] = useState("date");
  const [companyId, setCompanyId] = useState("");
  const [conflictDates, setConflictDates] = useState({});
  const [selectedMeeting, setSelectedMeeting] = useState([]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const times = [
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
  ];

  useEffect(() => {
    if (selectedMeeting) {
      const doctorId = selectedMeeting.doctorID;
      const fetchConflicts = async () => {
        const conflicts = await checkConflictMeetings(doctorId, companyId);
        setConflictDates(conflicts);
        console.log(conflicts);
      };
      fetchConflicts();
    }
  }, [selectedMeeting, companyId]);

  const toggleCalendar = (meeting = null) => {
    setSelectedMeeting(meeting);
    if (meeting) {
      setSelectedMeetingId(meeting.id);
      setSelectedDate(new Date(meeting.date));
      setSelectedTime(meeting.time);
      setMeetingType(meeting.meetingType);
      setCalendarTab("date");
    } else {
      setSelectedMeetingId(null);
      setSelectedDate(new Date());
      setSelectedTime(null);
      setMeetingType("virtual");
      setCalendarTab("date");
    }
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    const fetchMeetingsData = async () => {
      try {
        const meetings = await fetchMeetings(id, searchDate, searchLocation);
        setUpcomingMeetings(meetings.upcoming);
        setMissedMeetings(meetings.missed);
        setCompletedMeetings(meetings.completed);

        const companyId = await fetchUserCompanyId(id);
        setCompanyId(companyId);
      } catch (error) {
        console.error("Error fetching meetings data:", error);
      }
    };

    fetchMeetingsData();
  }, [id, searchDate, searchLocation]);

  const handleModify = async () => {
    if (!selectedMeetingId) return;

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, save it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateMeeting(
            selectedMeetingId,
            selectedDate,
            selectedTime,
            meetingType,
            id
          );
          toggleCalendar();
          setTimeout(() => {
            window.location.reload();
          }, 900);
        } catch (error) {
          console.error("Error updating schedule meeting:", error);
          Swal.fire({
            title: "Error",
            text: "An error occurred while updating the schedule. Please try again later.",
            icon: "error",
          });
        }
      }
    });
  };

  const handleAccept = async (meetingId) => {
    try {
      await acceptMeeting(meetingId);
      Swal.fire({
        title: "Accepted",
        text: "Meeting has been accepted.",
        icon: "success",
      });
      setUpcomingMeetings(
        upcomingMeetings.filter((meeting) => meeting.id !== meetingId)
      );
    } catch (error) {
      console.error("Error accepting meeting:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while accepting the meeting. Please try again later.",
        icon: "error",
      });
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleMeetingStatusChange = (value) => {
    setMeetingStatus(value);
    if (value === "missed") {
      setMrFeedback("");
    }
  };

  const handleSubmitStatus = async () => {
    if (!meetingStatus) {
      toast.error("Please select a meeting status.", {
        autoClose: 1000,
      });
      return;
    }

    if (meetingStatus === "completed" && !mrFeedback.trim()) {
      toast.error("Feedback is required!", {
        autoClose: 1000,
      });
      return;
    }

    try {
      await updateMeetingStatus(selectedMeetingId, meetingStatus, mrFeedback);
      Swal.fire({
        title: "Success",
        text: "Meeting has been updated.",
        icon: "success",
        timer: 2000,
      });
      toggleCalendar();
      setTimeout(() => {
        window.location.reload();
      }, 900);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "An error occurred while updating the meeting status. Please try again later.",
        icon: "error",
      });
    }
  };

  const isFeedbackButtonDisabled = () => {
    if (selectedMeetingId) {
      const meeting = upcomingMeetings.find(
        (meeting) => meeting.id === selectedMeetingId
      );
      if (meeting) {
        const meetingDateTime = `${meeting.date} ${meeting.time}`;
        return !isTimePassed(meetingDateTime);
      }
    }
    return true;
  };

  const indexOfLastUpcomingMeeting = currentUpcomingPage * meetingsPerPage;
  const indexOfFirstUpcomingMeeting =
    indexOfLastUpcomingMeeting - meetingsPerPage;
  const currentUpcomingMeetings = upcomingMeetings.slice(
    indexOfFirstUpcomingMeeting,
    indexOfLastUpcomingMeeting
  );

  const indexOfLastMissedMeeting = currentMissedPage * meetingsPerPage;
  const indexOfFirstMissedMeeting = indexOfLastMissedMeeting - meetingsPerPage;
  const currentMissedMeetings = missedMeetings.slice(
    indexOfFirstMissedMeeting,
    indexOfLastMissedMeeting
  );

  const indexOfLastCompletedMeeting = currentCompletedPage * meetingsPerPage;
  const indexOfFirstCompletedMeeting =
    indexOfLastCompletedMeeting - meetingsPerPage;
  const currentCompletedMeetings = completedMeetings.slice(
    indexOfFirstCompletedMeeting,
    indexOfLastCompletedMeeting
  );

  const renderPagination = (totalMeetings, currentPage, setPage) => {
    const totalPages = Math.ceil(totalMeetings / meetingsPerPage);

    if (totalPages <= 1) return null;

    const pageNumbers = [];
    for (
      let i = Math.max(1, currentPage - 1);
      i <= Math.min(totalPages, currentPage + 1);
      i++
    ) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex my-2">
        {currentPage > 1 && (
          <button
            onClick={() => setPage(currentPage - 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1"
          >
            &lt;
          </button>
        )}
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => setPage(number)}
            className={`px-4 py-2 ${
              currentPage === number
                ? "bg-indigo-400 text-white"
                : "bg-white border text-neutral-800"
            } rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1`}
          >
            {number}
          </button>
        ))}
        {currentPage < totalPages && (
          <button
            onClick={() => setPage(currentPage + 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1"
          >
            &gt;
          </button>
        )}
      </div>
    );
  };

  const isTimePassed = (meetingDateTime) =>
    new Date(meetingDateTime) < new Date();

  const isMeetingDisabled = (meeting) => {
    const meetingDateTime = `${meeting.date} ${meeting.time}`;
    return (
      isTimePassed(meetingDateTime) &&
      meeting.status !== "completed" &&
      meeting.status !== "missed"
    );
  };

  const onChange = (date) => {
    setSelectedDate(date);
    if (calendarTab === "date") {
      setCalendarTab("time");
    }
  };

  const formatDateToISO = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const isTimeConflicting = (time) => {
    const dateStr = formatDateToISO(selectedDate);
    return conflictDates[dateStr] && conflictDates[dateStr].includes(time);
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      // Normalize the date to avoid time issues
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      const dateStr = formatDateToISO(date);
  
      if (date < today) {
        console.log(date);
        return "react-calendar__tile--passed"; 
      }
  
      if (conflictDates[dateStr]) {
        return "react-calendar__tile--conflict";
      }
    }
    return null;
  };

  return (
    <div className="flex flex-col h-screen">
      <MrNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <MrSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          <div className="container px-4 mx-auto my-10">
            <div className="flex overflow-x-auto no-scrollbar space-x-2 mb-4">
              <h1
                className={`flex-shrink-0 w-40 text-center text-md py-4 cursor-pointer border ${
                  activeTab === "upcoming"
                    ? "bg-[#0D47A1] text-white font-bold text-[1.25rem]"
                    : "bg-white text-gray-800 text-[1.25rem]"
                }`}
                onClick={() => handleTabClick("upcoming")}
              >
                <LuCalendarClock className="inline-block align-middle mr-2" />
                <span>Upcoming</span>
              </h1>
              <h1
                className={`flex-shrink-0 w-40 text-center py-4 cursor-pointer border ${
                  activeTab === "missed"
                    ? "bg-[#0D47A1] text-white font-bold text-[1.25rem]"
                    : "bg-white text-gray-800 text-[1.25rem]"
                }`}
                onClick={() => handleTabClick("missed")}
              >
                <RiCalendarCloseLine className="inline-block align-middle mr-2" />
                <span>Missed</span>
              </h1>
              <h1
                className={`flex-shrink-0 w-40 text-center py-4 cursor-pointer border ${
                  activeTab === "completed"
                    ? "bg-[#0D47A1] text-white font-bold text-[1.25rem]"
                    : "bg-white text-gray-800 text-[1.25rem]"
                }`}
                onClick={() => handleTabClick("completed")}
              >
                <FaRegCalendarCheck className="inline-block align-middle mr-2" />
                <span>Completed</span>
              </h1>
            </div>

            <div className="flex justify-end items-center flex-col sm:flex-row mb-5">
              <div className="flex flex-col mx-2 justify-center self-stretch my-auto border">
                <input
                  type="date"
                  value={searchDate}
                  onChange={(e) => setSearchDate(e.target.value)}
                  className="p-2 focus:outline-none"
                />
              </div>
              <div className="flex flex-col mx-2 justify-center self-stretch my-auto border">
                <div className="flex">
                  <input
                    type="text"
                    id="searchLocation"
                    value={searchLocation}
                    onChange={(e) => setSearchLocation(e.target.value)}
                    placeholder="Search Location"
                    className="p-2 focus:outline-none"
                  />
                  <button
                    type="button"
                    className="flex-shrink-0 inline-flex px-2 items-center text-white bg-[#0D47A1]"
                  >
                    <IoSearchSharp />
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-5">
              {activeTab === "upcoming" && (
                <div className="overflow-auto shadow-md sm:rounded-lg mt-3 table-container">
                  <table className="divide-y border divide-gray-300 w-full text-left rtl:text-right">
                    <thead className="text-sm text-gray-700 uppercase">
                      <tr>
                        <th scope="col"
                          className="px-2 py-3 font-bold tracking-wider bg-gray-50"
                        >
                          M:Id
                        </th>
                        <th scope="col" className="px-6 py-3 tracking-wider">
                          Doctor Name
                        </th>
                        <th scope="col"
                          className="px-6 py-3 tracking-wider bg-gray-50"
                        >
                          Assigned
                        </th>
                        <th scope="col" className="px-6 py-3 tracking-wider">
                          Date
                        </th>
                        <th scope="col"
                          className="px-6 py-3 tracking-wider bg-gray-50"
                        >
                          Time
                        </th>
                        <th scope="col" className="px-6 py-3 tracking-wider">
                          Location
                        </th>
                        <th scope="col"
                          className="px-6 py-3 tracking-wider bg-gray-50"
                        >
                          Status
                        </th>
                        <th scope="col" className="px-6 py-3 tracking-wider">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentUpcomingMeetings.length === 0 ? (
                        <tr className="bg-white border-b">
                          <td colSpan="8" className="text-center py-4">
                            <p className="text-lg">No Upcoming meetings.</p>
                          </td>
                        </tr>
                      ) : (
                        currentUpcomingMeetings.map((meeting, index) => (
                          <tr key={meeting.id} className="bg-white border-b">
                            <td
                              scope="row"
                              className="px-2 py-3 bg-gray-50 text-center font-bold"
                            >
                              {meeting.meetingId}
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {meeting.doctorName}
                            </td>
                            <td className="px-2 py-3 bg-gray-50">
                              {meeting.assignedName}
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {format(new Date(meeting.date), "dd/MM/yyyy")}
                            </td>
                            <td className="px-2 py-3 bg-gray-50">
                              {meeting.time}
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {meeting.location}
                            </td>
                            <td className="px-2 py-3 bg-gray-50 capitalize">
                              <p>{meeting.status}</p>
                              <p>({meeting.meetingType})</p>
                            </td>
                            <td className="px-2 py-3 font-medium capitalize">
                              <div className="flex space-x-2">
                                <button
                                  onClick={() => toggleCalendar(meeting)}
                                  className="text-white bg-[#0D47A1] rounded-lg px-2 py-2 text-center"
                                >
                                  <FaEdit />
                                </button>
                                {meeting.meetingType !== "offline" ? (
                                  <Tippy content="Join Meeting">
                                    <button
                                      className={`text-white rounded-lg px-2 py-2 text-center ${
                                        isMeetingDisabled(meeting)
                                          ? "bg-gray-400 cursor-not-allowed"
                                          : "bg-[#0D47A1]"
                                      }`}
                                      disabled={isMeetingDisabled(meeting)}
                                    >
                                      <Link
                                        to={meeting.meetingLink}
                                        target="_blank"
                                      >
                                        <SiGooglemeet />
                                      </Link>
                                    </button>
                                  </Tippy>
                                ) : (
                                  <button
                                    type="button"
                                    className="text-white bg-gray-400 rounded-lg px-2 py-2 text-center cursor-not-allowed"
                                    disabled
                                  >
                                    <SiGooglemeet />
                                  </button>
                                )}
                                {meeting.status === "Rescheduled" && (
                                  <button
                                    type="button"
                                    onClick={() => handleAccept(meeting.id)}
                                    className={`text-white rounded-lg px-2 py-2 text-center ${
                                      isMeetingDisabled(meeting)
                                        ? "bg-gray-400 cursor-not-allowed"
                                        : "bg-[#0D47A1]"
                                    }`}
                                    disabled={isMeetingDisabled(meeting)}
                                  >
                                    <FaCheck />
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      )}

                      {showCalendar && (
                        <div className="overlay">
                          <div className="overlay-content">
                            <div className="tabs flex justify-center border-b-2">
                              <button
                                className={`tab px-4 py-2 text-lg font-bold focus:outline-none ${
                                  tab === "meeting"
                                    ? "active border-b-2 border-indigo-500 text-indigo-500"
                                    : "text-gray-600"
                                }`}
                                onClick={() => setTab("meeting")}
                              >
                                Meeting
                              </button>
                              <button
                                className={`tab px-4 py-2 text-lg font-bold focus:outline-none ${
                                  tab === "feedback"
                                    ? "active border-b-2 border-indigo-500 text-indigo-500"
                                    : "text-gray-600"
                                }`}
                                onClick={() => setTab("feedback")}
                              >
                                Feedback
                              </button>
                            </div>
                            {tab === "meeting" && (
                              <div className="meeting-content">
                                <div>
                                  <div className="tabs flex border-b-2">
                                    <button
                                      className={`tab w-1/2 text-center px-4 py-2 text-lg font-bold focus:outline-none ${
                                        calendarTab === "date"
                                          ? "active border-b-2 border-indigo-500 text-indigo-500"
                                          : "text-gray-600"
                                      }`}
                                      onClick={() => setCalendarTab("date")}
                                    >
                                      <FaCalendarAlt className="m-auto" />
                                    </button>
                                    <button
                                      className={`tab w-1/2 text-center px-4 py-2 text-lg font-bold focus:outline-none ${
                                        calendarTab === "time"
                                          ? "active border-b-2 border-indigo-500 text-indigo-500"
                                          : "text-gray-600"
                                      }`}
                                      onClick={() => setCalendarTab("time")}
                                    >
                                      <FaClock className="m-auto" />
                                    </button>
                                  </div>

                                  {calendarTab === "date" && (
                                    <div className="date-picker">
                                      <Calendar
                                        onChange={onChange}
                                        value={selectedDate}
                                        minDate={new Date()}
                                        className="custom-calendar"
                                        tileClassName={tileClassName}
                                      />
                                    </div>
                                  )}
                                  {calendarTab === "time" && (
                                    <div className="time-picker mt-2 p-2 shadow-lg">
                                      <div className="grid grid-cols-5 gap-2">
                                        {times.map((time) => (
                                          <button
                                            key={time}
                                            onClick={() =>
                                              setSelectedTime(time)
                                            }
                                            className={`py-3 px-1 rounded-md ${
                                              selectedTime === time
                                                ? "bg-[#11c911] text-white"
                                                : isTimeConflicting(time)
                                                ? "bg-[#fa313191] text-white cursor-not-allowed"
                                                : "bg-gray-200"
                                            }`}
                                            disabled={isTimeConflicting(time)}
                                          >
                                            {time}
                                          </button>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="flex justify-between mt-3">
                                  <div className="flex">
                                    <label className="inline-flex items-center mr-4">
                                      <input
                                        type="checkbox"
                                        name="meetingType"
                                        value="virtual"
                                        checked={meetingType === "virtual"}
                                        onChange={(e) =>
                                          setMeetingType(e.target.value)
                                        }
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                      />
                                      <span className="ml-2 text-gray-700">
                                        Virtual
                                      </span>
                                    </label>
                                    <label className="inline-flex items-center">
                                      <input
                                        type="checkbox"
                                        name="meetingType"
                                        value="offline"
                                        checked={meetingType === "offline"}
                                        onChange={(e) =>
                                          setMeetingType(e.target.value)
                                        }
                                        className="form-checkbox h-5 w-5 text-blue-600"
                                      />
                                      <span className="ml-2 text-gray-700">
                                        Offline
                                      </span>
                                    </label>
                                  </div>
                                  <div className="flex justify-end">
                                    <button
                                      onClick={() => toggleCalendar(null)}
                                      className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                                    >
                                      Close
                                    </button>
                                    <button
                                      onClick={handleModify}
                                      className="px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 transition-all duration-300 ease-in-out"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {tab === "feedback" && (
                              <div className="feedback-content my-5">
                                <div className="flex">
                                  <label className="inline-flex items-center mr-4">
                                    <input
                                      type="checkbox"
                                      name="meetingStatus"
                                      value="completed"
                                      checked={meetingStatus === "completed"}
                                      onChange={() =>
                                        handleMeetingStatusChange("completed")
                                      }
                                      className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-2 font-medium text-gray-700">
                                      Meeting Ended
                                    </span>
                                  </label>
                                  <label className="inline-flex items-center">
                                    <input
                                      type="checkbox"
                                      name="meetingStatus"
                                      value="missed"
                                      checked={meetingStatus === "missed"}
                                      onChange={() =>
                                        handleMeetingStatusChange("missed")
                                      }
                                      className="form-checkbox h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-2 font-medium text-gray-700">
                                      Meeting Not Happened
                                    </span>
                                  </label>
                                </div>
                                <div className="mt-4">
                                  <label
                                    htmlFor="feedback"
                                    className="font-bold text-lg"
                                  >
                                    Mr Feedback :
                                  </label>
                                  <textarea
                                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
                                    placeholder="Enter your feedback"
                                    name="feedback"
                                    rows="5"
                                    value={mrFeedback}
                                    onChange={(e) =>
                                      setMrFeedback(e.target.value)
                                    }
                                    disabled={meetingStatus === "missed"}
                                    required={meetingStatus === "completed"}
                                  />
                                </div>
                                <div className="mt-4 flex justify-end">
                                  <button
                                    onClick={handleSubmitStatus}
                                    className={`px-4 py-2 rounded-md ${
                                      isFeedbackButtonDisabled()
                                        ? "bg-gray-400 cursor-not-allowed"
                                        : "bg-[#0D47A1] text-white"
                                    }`}
                                    disabled={isFeedbackButtonDisabled()}
                                  >
                                    Submit
                                  </button>
                                  <button
                                    onClick={() => toggleCalendar(null)}
                                    className="ml-2 bg-gray-300 text-black px-4 py-2 rounded-md"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </tbody>
                  </table>
                  {renderPagination(
                    upcomingMeetings.length,
                    currentUpcomingPage,
                    setCurrentUpcomingPage
                  )}
                  <div className="text-gray-600 dark:text-gray-400 text-sm mb-2 px-3 text-end">
                    {`Showing ${currentUpcomingMeetings.length} out of ${upcomingMeetings.length} matches`}
                  </div>
                </div>
              )}
              {activeTab === "missed" && (
                <div className="overflow-auto shadow-md sm:rounded-lg mt-3 table-container">
                  <table className="divide-y border divide-gray-300 w-full text-left rtl:text-right">
                    <thead className="text-sm text-gray-700 uppercase ">
                      <tr>
                        <th scope="col"
                          className="px-2 py-3 tracking-wider bg-gray-50"
                        >
                          S.N.
                        </th>
                        <th scope="col" className="px-6 py-3 tracking-wider">
                          Doctor Name
                        </th>
                        <th scope="col"
                          className="px-6 py-3 tracking-wider bg-gray-50"
                        >
                          Company Name
                        </th>
                        <th scope="col" className="px-6 py-3 tracking-wider">
                          Date
                        </th>
                        <th scope="col"
                          className="px-6 py-3 tracking-wider bg-gray-50"
                        >
                          Time
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentMissedMeetings.length === 0 ? (
                        <tr className="bg-white border-b">
                          <td colSpan="5" className="text-center py-4">
                            <p className="text-lg">No Missed meetings.</p>
                          </td>
                        </tr>
                      ) : (
                        currentMissedMeetings.map((meeting, index) => (
                          <tr key={meeting.id} className="bg-white border-b">
                            <td
                              scope="row"
                              className="px-2 py-3 bg-gray-50 text-center font-medium"
                            >
                              {index + 1}.
                            </td>
                            <td className="px-4 py-3 font-medium">
                              {meeting.doctorName}
                            </td>
                            <td className="px-4 py-3 bg-gray-50">
                              {meeting.companyName}
                            </td>
                            <td className="px-4 py-3 font-medium">
                              {format(new Date(meeting.date), "dd/MM/yyyy")}
                            </td>
                            <td className="px-4 py-3 bg-gray-50">
                              {meeting.time}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  {renderPagination(
                    missedMeetings.length,
                    currentMissedPage,
                    setCurrentMissedPage
                  )}
                  <div className="text-gray-600 dark:text-gray-400 text-sm mb-2 px-3 text-end">
                    {`Showing ${currentMissedMeetings.length} out of ${missedMeetings.length} matches`}
                  </div>
                </div>
              )}

              {activeTab === "completed" && (
                <div className="overflow-auto shadow-md sm:rounded-lg mt-3 table-container">
                  <table className="divide-y border divide-gray-300 w-full text-left rtl:text-right">
                    <thead className="text-sm text-gray-700 uppercase">
                      <tr>
                        <th scope="col"
                          className="px-2 py-3 tracking-wider bg-gray-50"
                        >
                          S.N.
                        </th>
                        <th scope="col" className="px-6 py-3 tracking-wider">
                          Doctor Name
                        </th>
                        <th scope="col"
                          className="px-6 py-3 tracking-wider bg-gray-50"
                        >
                          Company Name
                        </th>
                        <th scope="col" className="px-6 py-3 tracking-wider">
                          Date
                        </th>
                        <th scope="col"
                          className="px-6 py-3 tracking-wider bg-gray-50"
                        >
                          Time
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentCompletedMeetings.length === 0 ? (
                        <tr className="bg-white border-b">
                          <td colSpan="5" className="text-center py-4">
                            <p className="text-lg">No completed meetings.</p>
                          </td>
                        </tr>
                      ) : (
                        currentCompletedMeetings.map((meeting, index) => (
                          <tr key={meeting.id} className="bg-white border-b">
                            <td
                              scope="row"
                              className="px-2 py-3 bg-gray-50 text-center font-medium"
                            >
                              {index + 1}.
                            </td>
                            <td className="px-4 py-3 font-medium">
                              {meeting.doctorName}
                            </td>
                            <td className="px-4 py-3 bg-gray-50">
                              {meeting.companyName}
                            </td>
                            <td className="px-4 py-3 font-medium">
                              {format(new Date(meeting.date), "dd/MM/yyyy")}
                            </td>
                            <td className="px-4 py-3 bg-gray-50">
                              {meeting.time}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  {renderPagination(
                    completedMeetings.length,
                    currentCompletedPage,
                    setCurrentCompletedPage
                  )}
                  <div className="text-gray-600 dark:text-gray-400 text-sm mb-2 px-3 text-end">
                    {`Showing ${currentCompletedMeetings.length} out of ${completedMeetings.length} matches`}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MrMeetingsInfo;
