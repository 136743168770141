import React, { useState, useEffect } from "react";
import MrSide from "./MrSide";
import MrNavbar from "./MrNavbar";
import defaultAvatar from "../../assets/img/defaultAvatar.png";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GoPlusCircle } from "react-icons/go";
import { RxCrossCircled } from "react-icons/rx";
import { MdPreview } from "react-icons/md";
import { fetchDoctorsByHospitalId, fetchDoctorsWithoutHospitalId, fetchUserCompanyId, fetchSelectedMrDoctors, toggleMrDoctorSelection } from "./MrFirebase";

const MrAllDoctors = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [doctorsPerPage] = useState(20);
  const [nameFilter, setNameFilter] = useState("");
  const [specialistFilter, setSpecialistFilter] = useState("All");
  const [locationFilter, setLocationFilter] = useState("All");
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [companyId, setCompanyId] = useState(null);
  const location = useLocation();

  const { hospitalId } = location.state || {};

  useEffect(() => {
    if (hospitalId) {
      sessionStorage.setItem("hospitalsId", hospitalId);
    }

    const fetchMrData = async () => {
      try {
        const companyId = await fetchUserCompanyId(id);
        setCompanyId(companyId);

        const hospitalId = sessionStorage.getItem("hospitalsId");

        if (hospitalId === "N/A") {
          const doctorsData = await fetchDoctorsWithoutHospitalId();
          setDoctors(doctorsData);
          setFilteredDoctors(doctorsData);
        } else {
          const doctorsData = await fetchDoctorsByHospitalId(hospitalId);
          setDoctors(doctorsData);
          setFilteredDoctors(doctorsData);
        }

        const selectedDoctorsData = await fetchSelectedMrDoctors(id);
        setSelectedDoctors(selectedDoctorsData);
      } catch (error) {

      }
    }
    fetchMrData();
  }, [id]);

  const handleStarClick = async (doctorId) => {
    try {
      const { action } = await toggleMrDoctorSelection(doctorId, id, companyId, selectedDoctors);
      if (action == "removed") {
        setSelectedDoctors((prev) => prev.filter((doc) => doc !== doctorId));
        toast.error("Selected Doctor Removed Successfully!", { autoClose: 2000 });
      } else if (action == "added") {
        setSelectedDoctors((prev) => [...prev, doctorId]);
        toast.success("Doctor Selected Successfully!", {
          autoClose: 2000,
        });
      }
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const applyFilters = () => {
      let filtered = doctors.filter((doctor) => {
        const nameMatch = doctor.name.toLowerCase().includes(nameFilter.toLowerCase());
        const specialistMatch = specialistFilter === "All" || doctor.specialist === specialistFilter;
        const locationMatch = locationFilter === "All" || doctor.location === locationFilter;

        return nameMatch && specialistMatch && locationMatch;
      });

      setFilteredDoctors(filtered);
      setCurrentPage(1);
    };

    applyFilters();
  }, [nameFilter, specialistFilter, locationFilter, doctors]);

  const handleViewProfile = (doctorId) => {
    navigate(`/mr/viewProfile/${id}`, { state: { doctorId } });
  };

  const indexOfLastDoctor = currentPage * doctorsPerPage;
  const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;
  const currentDoctors = filteredDoctors.slice(indexOfFirstDoctor, indexOfLastDoctor);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredDoctors.length / doctorsPerPage);

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    for ( let i = Math.max(1, currentPage - 1); i <= Math.min(totalPages, currentPage + 1); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex mt-4">
        {currentPage > 1 && (
          <button
            onClick={() => paginate(currentPage - 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1"
          >
            &lt;
          </button>
        )}
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-4 py-2 ${currentPage === number
              ? "bg-indigo-400 text-white"
              : "bg-white border text-neutral-800"
              } rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1`}
          >
            {number}
          </button>
        ))}
        {currentPage < totalPages && (
          <button
            onClick={() => paginate(currentPage + 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1"
          >
            &gt;
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-screen">
      <MrNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <MrSide
          open={sidebarOpen}
          toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
        />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container px-4 mx-auto my-10 overflow-auto">
            <div className="flex flex-col sm:flex-row items-center justify-between mb-4">
              <div className="mb-2"></div>
              <h2 className="text-xl font-bold bg-[#0D47A1] text-white p-2 rounded mb-2 sm:mb-0 sm:mr-4 w-full sm:w-auto text-center sm:text-left">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="flex items-center justify-center"
                >
                  <IoMdArrowBack className="h-6 w-6 font-bold text-white " />
                  {/* Doctors */}
                </button>
              </h2>
              <div className="flex flex-col justify-end sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full">
                <input
                  type="text"
                  placeholder="Search by name"
                  className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                />
                <select
                  className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                  value={specialistFilter}
                  onChange={(e) => setSpecialistFilter(e.target.value)}
                >
                  <option value="All">All Specializations</option>
                  <option value="Orthopaedic">Orthopaedic</option>
                  <option value="Cardiologist">Cardiologist</option>
                  <option value="Gynaecologist">Gynaecologist</option>
                  <option value="Radiologist">Radiologist</option>
                  <option value="Dermatologist">Dermatologist</option>
                  <option value="Oncology">Oncology</option>
                  <option value="Neurology">Neurology</option>
                  <option value="Urology">Urology</option>
                  <option value="Ophthalmology">Ophthalmology</option>
                  <option value="Paediatric">Paediatric</option>
                </select>
                <select
                  className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                  value={locationFilter}
                  onChange={(e) => setLocationFilter(e.target.value)}
                >
                  <option value="All">All Locations</option>
                  <option value="Bangalore">Bangalore</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Mumbai">Mumbai</option>
                  <option value="Kolkata">Kolkata</option>
                  <option value="Hyderabad">Hyderabad</option>
                  <option value="Chennai">Chennai</option>
                </select>
              </div>
            </div>

            <div className="h-[calc(100vh-200px)] overflow-y-auto">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {/* {loading && <div>Loading...</div>} */}
                {error && <div>Error: {error}</div>}
                {currentDoctors.length === 0 ? (
                  <div className="bg-white p-2 border border-gray-200 shadow flex flex-col items-center text-center relative">
                    No Doctors in This Hospital
                  </div>
                ) : (
                  currentDoctors.map((doctor) => (

                    <div
                      key={doctor.id}
                      className="bg-white border border-gray-200 shadow flex flex-col items-center text-center relative"
                    >
                      <Tippy content="Click here to add/remove">
                        <div
                          onClick={() => handleStarClick(doctor.id)}
                          className="absolute top-2 right-2 cursor-pointer"
                        >
                          {selectedDoctors.includes(doctor.id) ? (
                            <RxCrossCircled size={20} />
                          ) : (
                            <GoPlusCircle size={20} />
                          )}
                        </div>
                      </Tippy>
                      <img
                        loading="lazy"
                        src={doctor.image || defaultAvatar}
                        alt={`Profile of ${doctor.name}`}
                        className="items-center aspect-square w-[90px] mt-3 rounded-full"
                      />
                      <div className="p-5 flex-grow flex flex-col justify-between w-full">
                        <div>
                          <div className="text-sm font-bold leading-7 underline">
                            {doctor.name}
                          </div>
                          <div className="mt-3 py-1 text-sm leading-9 bg-violet-100">
                            {doctor.specialist}
                          </div>
                        </div>
                        <div className="mt-3 flex justify-between items-center">
                          <p>{doctor.location}</p>
                          <button
                            onClick={() => handleViewProfile(doctor.id, id)}
                            className="px-2 py-1 font-semibold text-white capitalize bg-[#0D47A1] hover:bg-[#ADBBDA]"
                          >
                            <MdPreview className="w-5 h-5" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))

                )}
              </div>
              {renderPagination()}
              <div className="text-gray-600 dark:text-gray-400 text-sm mb-4 text-end">
                {`Showing ${currentDoctors.length} out of ${filteredDoctors.length} matches`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default MrAllDoctors;
