import React, { useState, useEffect } from "react";
import MrSide from "./MrSide";
import { useAuth } from "../AuthContext";
import MrNavbar from "./MrNavbar";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { FaUserDoctor } from "react-icons/fa6";
import { FaClock, FaCalendarAlt } from "react-icons/fa";
import { LuMessageCircle } from "react-icons/lu";
import { AiOutlineSchedule } from "react-icons/ai";
import { RiTimeLine } from "react-icons/ri";
import Calendar from "react-calendar";
import Swal from "sweetalert2";
import "../style/Calendar.css";
import "../style/Company.css";
import { MdAutoDelete } from "react-icons/md";
import { fetchMrMyDoctors, messageOfMrMyDoctors, sendMrMessages, meetingOfMrMyDoctor, deleteMrMyDoctors, fetchUserCompanyId, checkIfMeetingExists, checkConflictMeetings } from "./MrFirebase";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const MrMyDoctors = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [doctorsPerPage] = useState(15);
  const [nameFilter, setNameFilter] = useState("");
  const [specialistFilter, setSpecialistFilter] = useState("All");
  const [locationFilter, setLocationFilter] = useState("All");
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [meetingType, setMeetingType] = useState("virtual");
  const [companyId, setCompanyId] = useState("");
  const [calendarTab, setCalendarTab] = useState("date");
  const [conflictDates, setConflictDates] = useState({});
  const [showMessaging, setShowMessaging] = useState(false);
  const [message, setMessage] = useState("");

  const times = [
    "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
    "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM",
    "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM",
    "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM", "09:00 PM",
  ];

  useEffect(() => {
    if (selectedDoctor) {
      const fetchConflicts = async () => {
        const conflicts = await checkConflictMeetings(selectedDoctor.id, companyId);
        setConflictDates(conflicts);
        console.log(conflicts);
      };
      fetchConflicts();
    }
  }, [selectedDoctor, companyId]);

  const toggleCalendar = async (doctor) => {
    setSelectedDoctor(doctor);
    const meeting = await checkIfMeetingExists(doctor.id, companyId, id);

    if (meeting) {
      const existingMeeting = meeting[0];
      Swal.fire({
        title: "Meeting Exists",
        text: `A meeting is already scheduled for ${doctor.name}. Do you want to Reschedule a meeting?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          setSelectedDate(new Date(existingMeeting.date));
          setSelectedTime(existingMeeting.time);
          setMeetingType(existingMeeting.meetingType);
          setCalendarTab("date");
          setShowCalendar(!showCalendar);
        }
      });
    } else {
      setSelectedDate(new Date());
      setSelectedTime("");
      setMeetingType("virtual");
      setCalendarTab("date");
      setShowCalendar(!showCalendar);
    }
  };

  const fetchDoctors = async () => {
    try {
      const doctorsData = await fetchMrMyDoctors(id);
      setDoctors(doctorsData);
      setFilteredDoctors(doctorsData);

      const companyId = await fetchUserCompanyId(id);
      setCompanyId(companyId);
    } catch (error) {
      setError("Error fetching doctors: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDoctors();
  }, [id]);

  const handleMessageClick = async (doctor) => {
    const doctorId = doctor.id;
    const messagesSnapshot = await messageOfMrMyDoctors(id, doctorId);

    if (messagesSnapshot.empty) {
      setSelectedDoctor(doctor);
      setShowMessaging(true);
    } else {
      navigate(`/mr/message/${id}`, { state: { doctorId } });
    }
  };

  const handleSendMessage = async () => {
    if (!selectedDoctor) {
      return;
    }

    try {
      await sendMrMessages({
        doctorId: selectedDoctor.id,
        companyId: companyId,
        message: message,
        id: id,
      });

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Message sent successfully!",
        showConfirmButton: false,
        timer: 2000,
      });
      navigate(`/mr/message/${id}`);
    } catch (error) {
      console.error("Error sending messages:", error);
      alert("Failed to send message. Please try again.");
    }
  };

  const handleBookSchedule = async () => {
    if (!selectedDoctor || !selectedTime || !id) return;
    try {
      const result = await meetingOfMrMyDoctor(
        selectedDoctor,
        selectedTime,
        selectedDate,
        id,
        meetingType
      );
      if (result) {
        Swal.fire(
          "Scheduled!",
          "Your scheduled meeting sent to the doctor successfully!",
          "success"
        );

        navigate(`/mr/meetingsinfo/${id}`);
      }
    } catch (error) {
      console.error("Error scheduling meeting:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to Schedule Meeting",
        text: "Please try again.",
      });
    }
  };

  const handleDeleteDoctor = async (doctorId) => {
    const confirmed = window.confirm("Do you want to remove this Doctor?");
    if (confirmed) {
      try {
        const deleteResult = await deleteMrMyDoctors(id, doctorId);

        if (deleteResult) {
          Swal.fire("", "Doctor removed successfully!", "success");
        }

        // Fetch doctors again to reflect the changes
        fetchDoctors();
      } catch (error) {
        console.error("Error deleting doctor:", error);
        Swal.fire({
          icon: "error",
          title: "Failed to Remove Doctor",
          text: "Please try again.",
        });
      }
    }
  };

  useEffect(() => {
    const applyFilters = () => {
      let filtered = doctors.filter((doctor) => {
        // Ensure doctor.name, doctor.specialist, and doctor.location are defined
        const nameMatch = doctor.name
          ? doctor.name.toLowerCase().includes(nameFilter.toLowerCase())
          : false;
        const specialistMatch =
          specialistFilter === "All" || doctor.specialist === specialistFilter;
        const locationMatch =
          locationFilter === "All" || doctor.location === locationFilter;

        return nameMatch && specialistMatch && locationMatch;
      });

      setFilteredDoctors(filtered);
      setCurrentPage(1);
    };

    applyFilters();
  }, [nameFilter, specialistFilter, locationFilter, doctors]);

  const indexOfLastDoctor = currentPage * doctorsPerPage;
  const indexOfFirstDoctor = indexOfLastDoctor - doctorsPerPage;
  const currentDoctors = filteredDoctors.slice(
    indexOfFirstDoctor,
    indexOfLastDoctor
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredDoctors.length / doctorsPerPage);

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    for (
      let i = Math.max(1, currentPage - 1);
      i <= Math.min(totalPages, currentPage + 1);
      i++
    ) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex mt-4">
        {currentPage > 1 && (
          <button
            onClick={() => paginate(currentPage - 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1"
          >
            &lt;
          </button>
        )}
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-4 py-2 ${currentPage === number
                ? "bg-indigo-400 text-white"
                : "bg-white border text-neutral-800"
              } rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1`}
          >
            {number}
          </button>
        ))}
        {currentPage < totalPages && (
          <button
            onClick={() => paginate(currentPage + 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 mx-1"
          >
            &gt;
          </button>
        )}
      </div>
    );
  };

  const handleAllDoctors = () => {
    navigate(`/mr/allHospitals/${id}`);
  };

  const handleTimeline = async (doctorId) => {
    navigate(`/mr/timeline/${id}`, { state: { doctorId } });
  };

  const onChange = (date) => {
    setSelectedDate(date);
    if (calendarTab === "date") {
      setCalendarTab("time");
    }
  };

  const formatDateToISO = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const isTimeConflicting = (time) => {
    const dateStr = formatDateToISO(selectedDate);
    return conflictDates[dateStr] && conflictDates[dateStr].includes(time);
  };

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      // Normalize the date to avoid time issues
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const dateStr = formatDateToISO(date);

      if (date < today) {
        console.log(date);
        return "react-calendar__tile--passed";
      }

      if (conflictDates[dateStr]) {
        return "react-calendar__tile--conflict";
      }
    }
    return null;
  };

  return (
    <div className="flex flex-col h-screen">
      <MrNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <MrSide
          open={sidebarOpen}
          toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
        />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container px-4 mx-auto my-10 overflow-auto">
            <div className="flex flex-col sm:flex-row items-center justify-between mb-4">
              <Tippy content="Click here to view the doctor list">
                <h2
                  onClick={handleAllDoctors}
                  className="text-xl text-center cursor-pointer font-bold bg-[#0D47A1] text-white p-2 rounded mb-2 sm:mb-0 sm:mr-4 w-[12rem]"
                >
                  Add Doctor
                </h2>
              </Tippy>
              <div className="flex flex-col justify-end sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full">
                <input
                  type="text"
                  placeholder="Search by name"
                  className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                />
                <select
                  className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                  value={specialistFilter}
                  onChange={(e) => setSpecialistFilter(e.target.value)}
                >
                  <option value="All">All Specializations</option>
                  <option value="Orthopaedic">Orthopaedic</option>
                  <option value="Cardiologist">Cardiologist</option>
                  <option value="Gynaecologist">Gynaecologist</option>
                  <option value="Radiologist">Radiologist</option>
                  <option value="Dermatologist">Dermatologist</option>
                  <option value="Oncology">Oncology</option>
                  <option value="Neurology">Neurology</option>
                  <option value="Urology">Urology</option>
                  <option value="Ophthalmology">Ophthalmology</option>
                  <option value="Paediatric">Paediatric</option>
                </select>
                <select
                  className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                  value={locationFilter}
                  onChange={(e) => setLocationFilter(e.target.value)}
                >
                  <option value="All">All Locations</option>
                  <option value="Bangalore">Bangalore</option>
                  <option value="Delhi">Delhi</option>
                  <option value="Mumbai">Mumbai</option>
                  <option value="Kolkata">Kolkata</option>
                  <option value="Hyderabad">Hyderabad</option>
                  <option value="Chennai">Chennai</option>
                </select>
              </div>
            </div>

            <div className="overflow-x-auto">
              {loading && <div>Loading...</div>}
              {error && <div>Error: {error}</div>}
              {filteredDoctors.length === 0 ? (
                <div className="flex-1 flex flex-col items-center justify-center text-gray-500 bg-white rounded-lg shadow-lg p-10 m-4">
                  <FaUserDoctor className="text-gray-400 text-6xl mb-4" />
                  <p className="text-lg font-semibold">
                    No Selected Doctors Found
                  </p>
                  <p className="text-sm text-gray-400 mt-2">
                    Click on View All Doctors button to select the doctors.
                  </p>
                </div>
              ) : (
                <div className="overflow-auto shadow-md sm:rounded-lg mt-3 table-container">
                  <table className="divide-y border divide-gray-300 w-full text-center rtl:text-right">
                    <thead className="text-sm text-gray-700 uppercase">
                      <tr>
                        <th scope="col" className="px-2 py-3 bg-gray-50">
                          S.N
                        </th>
                        <th scope="col" className="px-2 py-3">
                          Doctor Name
                        </th>
                        <th scope="col" className="px-2 py-3 bg-gray-50">
                          Specialist
                        </th>
                        <th scope="col" className="px-2 py-3">
                          Location
                        </th>
                        <th scope="col" className="px-2 py-3 bg-gray-50">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentDoctors.map((doctor, index) => (
                        <tr
                          key={doctor.id}
                          className="bg-white border-b dark:border-gray-200"
                        >
                          <td className="px-2 py-3 font-semibold bg-gray-50">
                            {index + 1}.
                          </td>
                          <td className="px-2 py-3 font-medium">
                            {doctor.name}
                          </td>
                          <td className="px-2 py-3 bg-gray-50">
                            {doctor.specialist}
                          </td>
                          <td className="px-2 py-3 ">{doctor.location}</td>
                          <td className="px-2 py-3 bg-gray-50">
                            <Tippy content="Message">
                              <button
                                type="button"
                                onClick={() => handleMessageClick(doctor)}
                                className="text-white bg-[#0D47A1] rounded-lg px-3 py-2 font-bold text-center me-2 mb-2"
                              >
                                <LuMessageCircle />
                              </button>
                            </Tippy>
                            <Tippy content="Schedule Meeting">
                              <button
                                type="button"
                                onClick={() => toggleCalendar(doctor)}
                                className="text-white bg-[#0D47A1] rounded-lg px-3 py-2 font-bold text-center me-2 mb-2"
                              >
                                <AiOutlineSchedule />
                              </button>
                            </Tippy>
                            <Tippy content="Growth Timeline">
                              <button
                                onClick={() => {
                                  handleTimeline(doctor.id);
                                }}
                                type="button"
                                className="text-white bg-[#0D47A1] rounded-lg px-3 py-2 font-bold text-center me-2 mb-2"
                              >
                                <RiTimeLine />
                              </button>
                            </Tippy>
                            <button
                              type="button"
                              onClick={() => handleDeleteDoctor(doctor.id)}
                              disabled={
                                doctor.meetingCount > 0 ||
                                doctor.messageCount > 0
                              }
                              className={`text-white rounded-lg px-3 py-2 text-center me-2 mb-2 ${doctor.meetingCount > 0 ||
                                  doctor.messageCount > 0
                                  ? "bg-gray-400 cursor-not-allowed"
                                  : "bg-[#0D47A1] hover:bg-[#6c7d93]"
                                } `}
                            >
                              <MdAutoDelete />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
              {renderPagination()}
              <div className="text-gray-600 dark:text-gray-400 text-sm mb-4 text-end">
                {`Showing ${currentDoctors.length} out of ${filteredDoctors.length} matches`}
              </div>

              {showCalendar && selectedDoctor && (
                <div className="overlay">
                  <div className="overlay-content">
                    <div>
                      <div className="tabs flex border-b-2">
                        <button
                          className={`tab w-1/2 text-center px-4 py-2 text-lg font-bold focus:outline-none ${calendarTab === "date"
                              ? "active border-b-2 border-indigo-500 text-indigo-500"
                              : "text-gray-600"
                            }`}
                          onClick={() => setCalendarTab("date")}
                        >
                          <FaCalendarAlt className="m-auto" />
                        </button>
                        <button
                          className={`tab w-1/2 text-center px-4 py-2 text-lg font-bold focus:outline-none ${calendarTab === "time"
                              ? "active border-b-2 border-indigo-500 text-indigo-500"
                              : "text-gray-600"
                            }`}
                          onClick={() => setCalendarTab("time")}
                        >
                          <FaClock className="m-auto" />
                        </button>
                      </div>

                      {calendarTab === "date" && (
                        <div className="date-picker">
                          <Calendar
                            onChange={onChange}
                            value={selectedDate}
                            minDate={new Date()}
                            className="custom-calendar"
                            tileClassName={tileClassName}
                          />
                        </div>
                      )}
                      {calendarTab === "time" && (
                        <div className="time-picker mt-2 p-2 shadow-lg">
                          <div className="grid grid-cols-5 gap-2">
                            {times.map((time) => (
                              <button
                                key={time}
                                onClick={() => setSelectedTime(time)}
                                className={`py-3 px-1 rounded-md ${selectedTime === time
                                    ? "bg-[#11c911] text-white"
                                    : isTimeConflicting(time)
                                      ? "bg-[#fa313191] text-white cursor-not-allowed"
                                      : "bg-gray-200"
                                  }`}
                                disabled={isTimeConflicting(time)}
                              >
                                {time}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="flex">
                        <label className="inline-flex items-center mr-4">
                          <input
                            type="checkbox"
                            name="meetingType"
                            value="virtual"
                            checked={meetingType === "virtual"}
                            onChange={(e) => setMeetingType(e.target.value)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 font-bold text-gray-700">
                            Virtual
                          </span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            name="meetingType"
                            value="offline"
                            checked={meetingType === "offline"}
                            onChange={(e) => setMeetingType(e.target.value)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 font-bold text-gray-700">
                            Offline
                          </span>
                        </label>
                      </div>
                      <div className="flex justify-end">
                        <button
                          onClick={() => setShowCalendar(false)}
                          className="px-2 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                        >
                          Close
                        </button>
                        <button
                          onClick={handleBookSchedule}
                          disabled={!selectedTime}
                          className={`px-3 py-2 text-base font-bold text-center text-white uppercase ${selectedTime
                              ? "bg-blue-800 hover:bg-blue-600 cursor-pointer"
                              : "bg-gray-400 cursor-not-allowed"
                            }`}
                        >
                          Schedule Meeting
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {showMessaging && selectedDoctor && (
                <div className="overlay">
                  <div className="overlay-content">
                    <div className="bg-gray-200 p-4 rounded-md">
                      <p className="text-md font-semibold mb-2">
                        Send a Message to {selectedDoctor ? selectedDoctor.name : "the doctor"}
                      </p>
                      <textarea
                        placeholder="Type your message here..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="w-full h-32 p-2 border border-gray-300 rounded-md resize-none focus:outline-none"
                      ></textarea>
                      <div className="flex justify-end mt-3">
                        <button
                          onClick={() => setShowMessaging(false)}
                          className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400"
                        >
                          Close
                        </button>
                        <button
                          onClick={handleSendMessage}
                          className="px-4 py-2 bg-indigo-800 text-white rounded-md font-semibold"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MrMyDoctors;
