import React, { useState, useEffect } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { FaRegEdit } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { fetchHospitals } from "./AdminFirebase";
import { RiDashboard3Fill } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Hospital = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const { isAdminLoggedIn } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchHospitalsData = async () => {
      try {
        const hospitalsData = await fetchHospitals();
        setHospitals(hospitalsData);
      } catch (error) {
        setError("Error fetching hospitals: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchHospitalsData();
  }, []);

  const handleViewHospital = (hospitalId) => {
    navigate(`/admin/viewhospital/${hospitalId}`);
  };

  const navigateToHospitalDashboard = async (hospitalId) => {
    toast.success("Going to Hospital Dashboard!", {
      autoClose: 2000,
    });
    setTimeout(() => {
      navigate(`/hospitalDashboard/${hospitalId}`, {
        state: { from: "admin" },
      });
    }, 2000);
  };

  const handleEditProfile = (hospitalId) => {
    navigate(`/admin/edit-hospital/${hospitalId}`);
  };

  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  const filteredhospitals = hospitals.filter(
    (hospital) =>
      hospital.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      hospital.hospitalName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderHospital = filteredhospitals.map((hospital, index) => (
    <tr className="border-b border-gray-200" key={hospital.id}>
      <td scope="row" className="px-6 py-4">
        {index + 1 + (currentPage - 1) * perPage}
      </td>
      <td
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50"
      >
        {hospital.hospitalName}
      </td>
      <td className="px-6 py-4">{hospital.name}</td>
      <td className="px-6 py-4 bg-gray-50">{hospital.state}</td>
      <td className="px-6 py-4">{hospital.city}</td>

      <td className="px-6 py-4 bg-gray-50">
        <button
          type="button"
          className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2"
          onClick={() => handleEditProfile(hospital.id)}
        >
          <FaRegEdit />
        </button>
        <button
          type="button"
          onClick={() => navigateToHospitalDashboard(hospital.id)}
          className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2"
          >
          <RiDashboard3Fill />
        </button>
        <button
          type="button"
          className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2"
          onClick={() => handleViewHospital(hospital.id)}
        >
          <FaArrowRight />
        </button>
      </td>
    </tr>
  ));

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredhospitals.length / perPage);

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const pageNumbers = [];
    for (
      let i = Math.max(1, currentPage - 1);
      i <= Math.min(totalPages, currentPage + 1);
      i++
    ) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex mt-4">
        {currentPage > 1 && (
          <button
            onClick={() => paginate(currentPage - 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-[#8697C4] focus:ring-opacity-50 mx-1"
          >
            &lt;
          </button>
        )}
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-4 py-2 ${
              currentPage === number
                ? "bg-gradient-to-br from-[#8697C4] to-[#8595C3] text-white"
                : "bg-white border text-neutral-800"
            } rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-[#8697C4] focus:ring-opacity-50 mx-1`}
          >
            {number}
          </button>
        ))}
        {currentPage < totalPages && (
          <button
            onClick={() => paginate(currentPage + 1)}
            className="px-4 py-2 bg-white border text-neutral-800 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-[#8697C4] focus:ring-opacity-50 mx-1"
          >
            &gt;
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          <div className="container mx-auto px-5 md:px-3 h-full">
            <div className="border mt-4 p-2">
              <div className="flex justify-between items-center mb-5">
                <h2 className="text-center text-3xl font-bold">
                  Hospital List
                </h2>
                <Link
                  to="/admin/add-hospital"
                  className="bg-[#8697C4] hover:bg-[#8595C3] text-white font-bold py-2 px-4 rounded"
                >
                  Add Hospital
                </Link>
              </div>

              <div className="flex justify-end items-center py-2.5 pr-2.5 pl-5 mt-6 bg-white rounded max-md:flex-wrap max-md:max-w-full">
                <div className="flex flex-col justify-end sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full">
                  <input
                    type="text"
                    placeholder="Search by Hospital or Name"
                    className="px-4 py-2 border border-gray-300 rounded-lg w-full sm:w-1/3 lg:w-auto"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>
              <div className="overflow-auto mt-3">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="text-xs text-gray-700 font-bold border-t border-gray-200 text-left uppercase">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm tracking-wider"
                      >
                        S.N.
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-50 text-sm uppercase tracking-wider"
                      >
                        Hospital Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 bg-gray-50 text-sm uppercase tracking-wider"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm uppercase tracking-wider"
                      >
                        City
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-sm uppercase tracking-wider bg-gray-50"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {renderHospital}
                  </tbody>
                </table>
              </div>
              <div>{renderPagination()}</div>
              <div className="text-gray-600 text-sm mb-4 text-end">
                {`Showing ${filteredhospitals.length} out of ${hospitals.length} matches`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Hospital;
