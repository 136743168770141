import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import {
  getDownloadURL,
  ref,
  getStorage,
  uploadBytes,
} from "firebase/storage";
import { getAuth, signOut } from "firebase/auth";
import "../style/profilecomplete.css";
import Side1 from "../../assets/img/Side1.jpg";
import Side2 from "../../assets/img/Side2.jpg";
import Side3 from "../../assets/img/Side3.jpg";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css";

const DoctorProfileComplete = () => {
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const storage = getStorage();
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const auth = getAuth();
  const [useWebcam, setUseWebcam] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const navigate = useNavigate();

  const fetchDoctorProfile = async () => {
    try {
      const db = getFirestore();
      const doctorRef = doc(db, "doctors", id);
      const docSnap = await getDoc(doctorRef);
      if (docSnap.exists()) {
        setDoctor({ id: docSnap.id, ...docSnap.data() });
        if (docSnap.data().image) {
          const url = await getDownloadURL(ref(storage, docSnap.data().image));
          setImageUrl(url);
        }
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching doctor:", error);
    }
  };

  useEffect(() => {
    fetchDoctorProfile();
  }, [id, storage]);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleTakeSelfie = async () => {
    setUseWebcam(true);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        videoRef.current.srcObject = stream;
      } catch (error) {
        console.error("Error accessing webcam:", error);
      }
    }
  };

  const captureSelfie = () => {
    if (canvasRef.current) {
      const context = canvasRef.current.getContext("2d");
      if (context && videoRef.current) {
        context.drawImage(videoRef.current, 0, 0, 300, 225);
        const data = canvasRef.current.toDataURL("image/png");
        setImageUrl(data);
        setUseWebcam(false);
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    }
  };

  const removeImage = () => {
    setImageUrl(null);
    setImageFile(null);
  };

  const cancelSelfie = () => {
    setUseWebcam(false);
    const stream = videoRef.current.srcObject;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const db = getFirestore();
      const doctorRef = doc(db, "doctors", id);
      const newData = {
        ...doctor,
        profileComplete: true,
      };

      if (imageFile) {
        const imageRef = ref(storage, `images/${imageFile.name}`);
        await uploadBytes(imageRef, imageFile);
        const downloadURL = await getDownloadURL(imageRef);
        newData.image = downloadURL;
      } else if (imageUrl) {
        const blob = await fetch(imageUrl).then((res) => res.blob());
        const imageRef = ref(storage, `images/selfie_${Date.now()}.png`);
        await uploadBytes(imageRef, blob);
        const downloadURL = await getDownloadURL(imageRef);
        newData.image = downloadURL;
      } else {
        newData.image = "";
      }

      await updateDoc(doctorRef, newData);

      console.log("Document successfully updated!");
      alert("Data successfully updated!");
      // toast.success("Welcome to Medivirt!", {
      //   autoClose: 3000,
      // });

      signOut(auth)
        .then(() => {
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error signing out:", error);
        });

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  // Fetch doctor profile on component mount
  useEffect(() => {
    const fetchDoctorProfile = async () => {
      try {
        const db = getFirestore();
        const doctorRef = doc(db, "doctors", id);
        const docSnap = await getDoc(doctorRef);
        if (docSnap.exists()) {
          setDoctor({ id: docSnap.id, ...docSnap.data() });
          if (docSnap.data().image) {
            const url = await getDownloadURL(
              ref(storage, docSnap.data().image)
            );
            setImageUrl(url);
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching doctor:", error);
      }
    };

    fetchDoctorProfile();
  }, [id, storage]);

  const nextStep = () => {
    // setCurrentStep((prevStep) => prevStep + 1);
    if (currentStep < 3) setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    // setCurrentStep((prevStep) => prevStep - 1);
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  if (!doctor) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex items-center justify-center min-h-screen px-4 bg-gray-100">
      <div className="w-full max-w-6xl shadow rounded-xl border border-gray-100 p-2 bg-white">
        <div className="formcard">
          <form
            onSubmit={handleSubmit}
            className="bg-white"
          >
            {currentStep === 1 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="col-span-1 md:col-span-1">
                  <img src={Side1} className="w-full h-full object-cover" alt="Profile" />
                </div>
                <div className="col-span-1 md:col-span-2 mt-5 p-4">
                  <h2 className="text-center font-[K2D] text-2xl mb-3 font-bold">
                    Update Doctor Profile
                  </h2>

                  <div className="relative">
                    <div className="relative flex justify-between text-xs text-gray-600 my-7">
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep >= 1 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          1
                        </span>
                        <span className={`ml-2 ${currentStep >= 2 ? 'text-[#7191e6]' : 'text-gray-500'}`}>Basic Information</span>
                      </div>
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep >= 2 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          2
                        </span>
                        <span className={`ml-2 ${currentStep >= 3 ? 'text-[#7191e6]' : 'text-gray-500'}`}>Education & Experience</span>
                      </div>
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep === 3 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          3
                        </span>
                        <span className="ml-2 text-gray-500">Additional Information</span>
                      </div>
                    </div>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={doctor.name || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, name: e.target.value })
                      }
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="name"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Name
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={doctor.email || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, email: e.target.value })
                      }
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="email"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Email
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <select
                      name="gender"
                      id="gender"
                      value={doctor.gender || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, gender: e.target.value })
                      }
                      required
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    <label
                      htmlFor="gender"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Gender
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <select
                      name="specialist"
                      value={doctor.specialist || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, specialist: e.target.value })
                      }
                      required
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                    >
                      <option value="">Select Specialist</option>
                      <option value="Orthopaedic">Orthopaedic</option>
                      <option value="Cardiologist">Cardiologist</option>
                      <option value="Gynaecologist">Gynaecologist</option>
                      <option value="Radiologist">Radiologist</option>
                      <option value="Dermatologist">Dermatologist</option>
                      <option value="Oncology">Oncology</option>
                      <option value="Neurology">Neurology</option>
                      <option value="Urology">Urology</option>
                      <option value="Ophthalmology">Ophthalmology</option>
                      <option value="Paediatric">Paediatric</option>
                    </select>
                    <label
                      htmlFor="specialist"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Specialist
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      name="currentPosition"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={doctor.currentPosition || ""}
                      onChange={(e) =>
                        setDoctor({
                          ...doctor,
                          currentPosition: e.target.value,
                        })
                      }
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="currentPosition"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Current Position
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <select
                      name="location"
                      value={doctor.location || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, location: e.target.value })
                      }
                      required
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                    >
                      <option value="">Select Location</option>
                      <option value="Bangalore">Bangalore</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Kolkata">Kolkata</option>
                      <option value="Hyderabad">Hyderabad</option>
                      <option value="Chennai">Chennai</option>
                    </select>
                    <label
                      htmlFor="location"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Location
                    </label>
                  </div>

                  <div style={{ textAlign: "end" }}>
                    <button
                      type="button"
                      onClick={nextStep}
                      className="bg-[#7191e6] text-white px-6 py-1 font-bold border rounded-full"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}

            {currentStep === 2 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="col-span-1 md:col-span-1">
                  <img src={Side2} className="w-full h-full object-cover" alt="Profile" />
                </div>
                <div className="col-span-1 md:col-span-2 mt-5 p-4">
                  <h2 className="text-center font-[K2D] text-2xl mb-3 font-bold">
                    Update Doctor Profile
                  </h2>

                  <div className="relative">
                    <div className="relative flex justify-between text-xs text-gray-600 my-7">
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep >= 1 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          1
                        </span>
                        <span className={`ml-2 ${currentStep >= 2 ? 'text-[#7191e6]' : 'text-gray-500'}`}>Basic Information</span>
                      </div>
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep >= 2 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          2
                        </span>
                        <span className={`ml-2 ${currentStep >= 3 ? 'text-[#7191e6]' : 'text-gray-500'}`}>Education & Experience</span>
                      </div>
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep === 3 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          3
                        </span>
                        <span className="ml-2 text-gray-500">Additional Information</span>
                      </div>
                    </div>
                  </div>

                  <div className="relative z-0 w-full mb-6 mt-6 group">
                    <input
                      name="education_1"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={doctor.education_1 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, education_1: e.target.value })
                      }
                      placeholder=" "

                    />
                    <label
                      htmlFor="education_1"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Education 1
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      name="education_2"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={doctor.education_2 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, education_2: e.target.value })
                      }
                      placeholder=" "

                    />
                    <label
                      htmlFor="education_2"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Education 2
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6  group">
                    <input
                      name="education_3"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={doctor.education_3 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, education_3: e.target.value })
                      }
                      placeholder=" "

                    />
                    <label
                      htmlFor="education_3"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Education 3
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 mt-6 group">
                    <input
                      name="experience_1"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={doctor.experience_1 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, experience_1: e.target.value })
                      }
                      placeholder=" "

                    />
                    <label
                      htmlFor="experience_1"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Experience 1
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      name="experience_2"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={doctor.experience_2 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, experience_2: e.target.value })
                      }
                      placeholder=" "

                    />
                    <label
                      htmlFor="experience_2"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Experience 2
                    </label>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      name="experience_3"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={doctor.experience_3 || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, experience_3: e.target.value })
                      }
                      placeholder=" "

                    />
                    <label
                      htmlFor="experience_3"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Experience 3
                    </label>
                  </div>

                  <div className="flex justify-between mt-4">
                    <button
                      type="button"
                      onClick={prevStep}
                      className="bg-gray-400 text-white font-bold py-1 px-4 rounded-full mr-2"
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      onClick={nextStep}
                      className="bg-[#7191e6] text-white px-6 py-1 font-bold border rounded-full"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="col-span-1 md:col-span-1">
                  <img src={Side3} className="w-full h-full object-cover" alt="Profile" />
                </div>
                <div className="col-span-1 md:col-span-2 mt-5 p-4">
                  <h2 className="text-center font-[K2D] text-2xl mb-3 font-bold">
                    Update Doctor Profile
                  </h2>

                  <div className="relative">
                    <div className="relative flex justify-between text-xs text-gray-600 my-7">
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep >= 1 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          1
                        </span>
                        <span className={`ml-2 ${currentStep >= 2 ? 'text-[#7191e6]' : 'text-gray-500'}`}>Basic Information</span>
                      </div>
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep >= 2 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          2
                        </span>
                        <span className={`ml-2 ${currentStep >= 3 ? 'text-[#7191e6]' : 'text-gray-500'}`}>Education & Experience</span>
                      </div>
                      <div className="flex items-center">
                        <span className={`w-6 h-6 rounded-full ${currentStep === 3 ? 'bg-[#7191e6] text-white' : 'bg-gray-200 text-gray-500'} flex items-center justify-center`}>
                          3
                        </span>
                        <span className="ml-2 text-gray-500">Additional Information</span>
                      </div>
                    </div>
                  </div>

                  <div className="relative z-0 w-full mb-6 group">
                    <input
                      name="about"
                      className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                      value={doctor.about || ""}
                      onChange={(e) =>
                        setDoctor({ ...doctor, about: e.target.value })
                      }
                      placeholder=" "
                      required
                    />
                    <label
                      htmlFor="about"
                      className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      About
                    </label>
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="image"
                      className="block text-md font-bold text-gray-700"
                    >
                      Profile Image
                    </label>
                    {imageUrl ? (
                      <div className="relative">
                        <img
                          src={imageUrl}
                          alt="Profile"
                          className="w-40 h-40 rounded-full mx-auto my-4"
                        />
                        <button
                          type="button"
                          className="absolute top-2 right-2 bg-red-600 text-white rounded-full p-1"
                          onClick={removeImage}
                        >
                          Remove
                        </button>
                      </div>
                    ) : (
                      <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                          {/* <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                          >
                            <path
                              d="M28 8H20C19.4696 8 18.9609 8.21071 18.5858 8.58579C18.2107 8.96086 18 9.46957 18 10V38C18 38.5304 18.2107 39.0391 18.5858 39.4142C18.9609 39.7893 19.4696 40 20 40H28C28.5304 40 29.0391 39.7893 29.4142 39.4142C29.7893 39.0391 30 38.5304 30 38V10C30 9.46957 29.7893 8.96086 29.4142 8.58579C29.0391 8.21071 28.5304 8 28 8Z"
                              strokeWidth="4"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg> */}
                          <div className="flex text-sm text-gray-600">
                            <label
                              htmlFor="file-upload"
                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                            >
                              <span>Upload a file</span>
                              <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                onChange={handleImageUpload}
                              />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                          </div>
                          <p className="text-xs text-gray-500">
                            PNG, JPG
                          </p>
                        </div>
                      </div>
                    )}
                    {useWebcam && (
                      <div className="mt-4 text-center">
                        <video
                          ref={videoRef}
                          autoPlay
                          className="w-64 h-48 mx-auto"
                        />
                        <canvas ref={canvasRef} className="hidden" />
                        <button
                          type="button"
                          className="bg-[#EC447D] text-white px-6 py-1 font-bold border rounded-full"
                          onClick={captureSelfie}
                        >
                          Capture
                        </button>
                        <button
                          type="button"
                          className="bg-gray-400 text-white font-bold py-1 px-4 rounded-full ml-2"
                          onClick={cancelSelfie}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                    {!useWebcam && (
                      <div className="mt-4 text-center">
                        <button
                          type="button"
                          className="bg-[#3071E6] text-white px-6 py-1 font-bold border rounded-full"
                          onClick={handleTakeSelfie}
                        >
                          Take a Selfie
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        name="comment"
                        className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                        value={doctor.comment || ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, comment: e.target.value })
                        }
                        placeholder=" "

                      />
                      <label
                        htmlFor="comment"
                        className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Comment If Any
                      </label>
                    </div>

                    <div className="relative z-0 w-full mb-6 group">
                      <input
                        name="other"
                        className="block py-2 px-0 w-full text-md text-black bg-transparent border-0 border-b border-gray-300 appearance-none dark:border-gray-600 dark:focus:border-[#7191e6] focus:outline-none focus:ring-0 focus:border-[#7191e6] peer"
                        value={doctor.other || ""}
                        onChange={(e) =>
                          setDoctor({ ...doctor, other: e.target.value })
                        }
                        placeholder=" "

                      />
                      <label
                        htmlFor="other"
                        className="peer-focus:font-medium absolute text-md text-black-800 dark:text-black-800 font-bold duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#7191e6] peer-focus:dark:text-[#7191e6] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                      >
                        Other
                      </label>
                    </div>
                  </div>

                  <div className="flex justify-between mt-4">
                    <button
                      type="button"
                      onClick={prevStep}
                      className="bg-gray-400 text-white font-bold py-1 px-4 rounded-full mr-2"
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      className="bg-[#7191e6] text-white px-6 py-1 font-bold border rounded-full"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      <ToastContainer  position="top-right"/>
    </div>
  );
};

export default DoctorProfileComplete;
