import React, { useState, useEffect } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import Calendar from "react-calendar";
import Swal from "sweetalert2";
import { FaEdit, FaCheck, FaClock, FaCalendarAlt } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { SiGooglemeet } from "react-icons/si";
import { MdAutoDelete } from "react-icons/md";
import { format } from "date-fns";
import {
  fetchMeetings,
  modifyMeeting,
  acceptMeeting,
  deleteMeeting,
} from "./AdminFirebase";

const ScheduleList = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [scheduleMeeting, setScheduleMeeting] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [searchDate, setSearchDate] = useState("");
  // const [searchTime, setSearchTime] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedMeetingId, setSelectedMeetingId] = useState(null);
  const [meetingType, setMeetingType] = useState("virtual");
  const { isAdminLoggedIn } = useAuth();
  const [calendarTab, setCalendarTab] = useState("date");

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const times = [
    "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
    "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM",
    "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM",
    "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM", "09:00 PM",
  ];

  const toggleCalendar = (meeting = null) => {
    if (meeting) {
      setSelectedMeetingId(meeting.id);
      setSelectedDate(new Date(meeting.date));
      setSelectedTime(meeting.time);
      setMeetingType(meeting.meetingType);
      setCalendarTab("date");
    } else {
      setSelectedMeetingId(null);
      setSelectedDate(new Date());
      setSelectedTime(null);
      setMeetingType("virtual");
      setCalendarTab("date");
    }
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const meetings = await fetchMeetings(searchDate, "upcoming");

        const sortMeetings = (meetings) =>
          meetings.sort((a, b) => new Date(`${a.date} ${a.time}`) - new Date(`${b.date} ${b.time}`));

        setScheduleMeeting(sortMeetings(meetings));
      } catch (error) {
        setError("Error fetching meetings: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchDate]);

  const handleModify = async () => {
    if (!selectedMeetingId) return;

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, save it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await modifyMeeting(
            selectedMeetingId,
            selectedDate,
            selectedTime,
            meetingType
          );

          setScheduleMeeting((prevMeetings) =>
            prevMeetings.map((meeting) =>
              meeting.id === selectedMeetingId
                ? {
                  ...meeting,
                  date: selectedDate.toISOString().split("T")[0],
                  time: selectedTime,
                  status: "Rescheduled",
                  meetingType: meetingType,
                }
                : meeting
            )
          );

          toggleCalendar();
          setTimeout(() => {
            window.location.reload();
          }, 900);
        } catch (error) {
          console.error("Error updating schedule meeting:", error);
          Swal.fire({
            title: "Error",
            text: "An error occurred while updating the schedule. Please try again later.",
            icon: "error",
          });
        }
      }
    });
  };

  const handleAccept = async (meetingId) => {
    try {
      await acceptMeeting(meetingId);

      Swal.fire({
        title: "Accepted",
        text: "Meeting has been accepted.",
        icon: "success",
        timer: 2000,
      });

      setScheduleMeeting((prevMeetings) =>
        prevMeetings.map((meeting) =>
          meeting.id === meetingId
            ? { ...meeting, status: "Accepted" }
            : meeting
        )
      );
    } catch (error) {
      console.error("Error accepting meeting:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while accepting the meeting. Please try again later.",
        icon: "error",
      });
    }
  };

  const handleDeleteMeeting = async (meetingId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this meeting?"
    );

    if (confirmed) {
      try {
        await deleteMeeting(meetingId);

        setScheduleMeeting((prevMeetings) =>
          prevMeetings.filter(
            (scheduleMeetings) => scheduleMeetings.id !== meetingId
          )
        );
      } catch (error) {
        console.error("Error deleting Meeting:", error);
      }
    }
  };

  const indexOfLastMeeting = currentPage * itemsPerPage;
  const indexOfFirstMeeting = indexOfLastMeeting - itemsPerPage;
  const currentMeetings = scheduleMeeting.slice(
    indexOfFirstMeeting,
    indexOfLastMeeting
  );

  const sortedMeetings = [...currentMeetings].sort((a, b) => {
    const dateComparison = new Date(a.date) - new Date(b.date);
    if (dateComparison !== 0) {
      return dateComparison;
    }

    const timeA = a.time.split(" ")[0];
    const timeB = b.time.split(" ")[0];
    return new Date(`1970/01/01 ${timeA}`) - new Date(`1970/01/01 ${timeB}`);
  });

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  const onChange = (date) => {
    setSelectedDate(date);
    if (calendarTab === "date") {
      setCalendarTab("time");
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container mx-auto px-5 md:px-3 h-full">
            <div className="border mt-4 p-2">
              <div className="flex justify-between items-center mb-5">
                <h2 className="text-center text-3xl font-bold">
                  Schedule List
                </h2>
              </div>

              <div className="flex justify-end items-center mb-5">
                <div className="flex flex-col mx-2 justify-center self-stretch my-auto border rounded-md">
                  <input
                    type="date"
                    value={searchDate}
                    onChange={(e) => setSearchDate(e.target.value)}
                    className="p-2"
                  />
                </div>
                <button
                  onClick={() => console.log("Search logic here")}
                  className="p-2 rounded bg-[#8697C4] text-white  hover:bg-[#3D52A1] focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50"
                >
                  Search
                </button>
              </div>

              <div className="overflow-auto mt-3 table-container">
                <table className="divide-y border divide-gray-300 w-full text-left rtl:text-right">
                  <thead className="text-sm text-gray-700 uppercase">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 tracking-wider bg-gray-50"
                      >
                        S.N.
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 uppercase tracking-wider"
                      >
                        Company Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 bg-gray-50 uppercase tracking-wider"
                      >
                        Doctor Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 uppercase tracking-wider"
                      >
                        Assigned
                      </th>
                      <th
                        scope="col"
                        className="px-2 bg-gray-50 py-2 uppercase tracking-wider"
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 uppercase tracking-wider"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-2 bg-gray-50 py-2 uppercase tracking-wider"
                      >
                        Time
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-2 bg-gray-50 py-2 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedMeetings.map((meeting, index) => (
                      <tr key={meeting.id} className="bg-white border-b">
                        <td
                          scope="row"
                          className="px-2 py-2 bg-gray-50 text-center font-medium"
                        >
                          {index + 1}.
                        </td>
                        <td scope="row" className="px-2 py-2 font-medium">
                          {meeting.companyName}
                        </td>
                        <td className="px-2 py-2 bg-gray-50">
                          {meeting.doctorName}
                        </td>
                        <td className="px-2 py-2">{meeting.assignedName}</td>
                        <td className="px-2 py-2 bg-gray-50">
                          {meeting.assignedRole}
                        </td>
                        <td className="px-2 py-2">
                          {format(
                            new Date(`${meeting.date} ${meeting.time}`),
                            "dd/MM/yyyy"
                          )}
                        </td>
                        <td className="px-2 py-2 bg-gray-50">{meeting.time}</td>
                        <td className="px-2 py-2 capitalize">
                          <p>{meeting.status}</p>
                          <p>({meeting.meetingType})</p>
                        </td>
                        <td className="px-2 py-2 bg-gray-50">
                          <button
                            onClick={() => toggleCalendar(meeting)}
                            type="button"
                            className="text-white bg-[#8697C4] rounded-lg p-2 text-center me-1 mb-2"
                          >
                            <FaEdit />
                          </button>

                          {meeting.status !== "Accepted" ? (
                            <button
                              type="button"
                              onClick={() => handleAccept(meeting.id)}
                              className="text-white bg-[#8697C4] rounded-lg px-2 py-2 me-1 mb-2 text-center"
                            >
                              <FaCheck />
                            </button>
                          ) : (
                            <button
                              className="text-white bg-gray-400 rounded-lg px-2 py-2 me-1 mb-2 text-center cursor-not-allowed"
                              disabled
                            >
                              <FaCheck />
                            </button>
                          )}

                          {meeting.meetingType !== "offline" ? (
                            <button className="text-white bg-[#8697C4] rounded-lg px-2 py-2 me-1 mb-2 text-center">
                              <Link to={meeting.meetingLink} target="_blank">
                                <SiGooglemeet />
                              </Link>
                            </button>
                          ) : (
                            <button
                              className="text-white bg-gray-400 rounded-lg px-2 py-2 me-1 mb-2 text-center cursor-not-allowed"
                              disabled
                            >
                              <Link to={meeting.meetingLink} target="_blank">
                                <SiGooglemeet />
                              </Link>
                            </button>
                          )}

                          <button
                            onClick={() => handleDeleteMeeting(meeting.id)}
                            type="button"
                            className="text-white bg-[#8697C4] rounded-lg p-2 text-center me-1 mb-2"
                          >
                            <MdAutoDelete /> {/* Delete */}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {showCalendar && (
                <div className="overlay">
                  <div className="overlay-content">
                    <div>
                      <div className="tabs flex border-b-2">
                        <button
                          className={`tab w-1/2 text-center px-4 py-2 text-lg font-bold focus:outline-none ${calendarTab === "date"
                              ? "active border-b-2 border-indigo-500 text-indigo-500"
                              : "text-gray-600"
                            }`}
                          onClick={() => setCalendarTab("date")}
                        >
                          <FaCalendarAlt className="m-auto" />
                        </button>
                        <button
                          className={`tab w-1/2 text-center px-4 py-2 text-lg font-bold focus:outline-none ${calendarTab === "time"
                              ? "active border-b-2 border-indigo-500 text-indigo-500"
                              : "text-gray-600"
                            }`}
                          onClick={() => setCalendarTab("time")}
                        >
                          <FaClock className="m-auto" />
                        </button>
                      </div>

                      {calendarTab === "date" && (
                        <div className="date-picker">
                          <Calendar
                            onChange={onChange}
                            value={selectedDate}
                            minDate={new Date()}
                            className="custom-calendar"
                            tileClassName={({ date, view }) => {
                              if (view === "month") {
                                const today = new Date();
                                today.setHours(0, 0, 0, 0); // Normalize today's date to avoid time issues
                                if (date < today) {
                                  return "react-calendar__tile--passed"; // Apply passed date class
                                }
                              }
                              return null;
                            }}
                          />
                        </div>
                      )}
                      {calendarTab === "time" && (
                        <div className="time-picker mt-2 p-2 shadow-lg">
                          <div className="grid grid-cols-5 gap-2">
                            {times.map((time) => (
                              <button
                                key={time}
                                onClick={() => setSelectedTime(time)}
                                className={`py-3 px-1 rounded-md ${selectedTime === time
                                    ? "bg-[#11c911] text-white"
                                    : "bg-gray-200"
                                  }`}
                              >
                                {time}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex justify-between mt-4">
                      <div className="flex">
                        <label className="inline-flex items-center mr-4">
                          <input
                            type="checkbox"
                            name="meetingType"
                            value="virtual"
                            checked={meetingType === "virtual"}
                            onChange={(e) => setMeetingType(e.target.value)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 font-bold text-gray-700">Virtual</span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            name="meetingType"
                            value="offline"
                            checked={meetingType === "offline"}
                            onChange={(e) => setMeetingType(e.target.value)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 font-bold text-gray-700">Offline</span>
                        </label>
                      </div>
                      <div className="flex justify-end">
                        <button
                          onClick={() => toggleCalendar(null)}
                          className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400 transition-all duration-300 ease-in-out"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={handleModify}
                          className="px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 transition-all duration-300 ease-in-out"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Pagination */}
              <div className="flex justify-end my-4">
                {Array.from(
                  { length: Math.ceil(scheduleMeeting.length / itemsPerPage) },
                  (_, i) => (
                    <button
                      key={i}
                      className={`px-3 py-2 mx-1 rounded-md font-bold ${currentPage === i + 1
                          ? "bg-transparent text-gray-800 border border-[#8697C4] hover:bg-[#8697C4] hover:text-white"
                          : "bg-transparent text-gray-800 border border-gray-300 hover:bg-gray-300"
                        }`}
                      onClick={() => handlePageClick(i + 1)}
                    >
                      {i + 1}
                    </button>
                  )
                )}
              </div>
              <div className="text-gray-600 text-sm mb-4 text-end">
                {`Showing ${sortedMeetings.length} out of ${scheduleMeeting.length} matches`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleList;
