import React, { useState, useEffect } from "react";
import AdminSide from "./AdminSide";
import { Navigate } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import { useAuth } from "../AuthContext";
import { FaEdit } from "react-icons/fa";
import { MdAutoDelete } from "react-icons/md";
import { format } from "date-fns";
import { fetchMeetings, deleteMeeting } from "./AdminFirebase";

const AdminMissedMeeting = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { isAdminLoggedIn } = useAuth();
  const [scheduleMeeting, setScheduleMeeting] = useState([]);
  const [missedMeetings, setMissedMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchMissedMeetings = async () => {
      try {
        const missedMeetings = await fetchMeetings('', 'missed');

        const sortMeetings = (missedMeetings) =>
          missedMeetings.sort((a, b) => new Date(`${a.date} ${a.time}`) - new Date(`${b.date} ${b.time}`));

        setMissedMeetings(sortMeetings(missedMeetings));

      } catch (error) {
        setError("Error fetching missed meetings: " + error.message);
        console.error("Error fetching missed meetings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMissedMeetings();
  }, []);

  const handleDeleteMeeting = async (meetingId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this meeting?"
    );

    if (confirmed) {
      try {
        await deleteMeeting(meetingId);
        setMissedMeetings((prevMeetings) =>
          prevMeetings.filter((meeting) => meeting.id !== meetingId)
        );
      } catch (error) {
        console.error("Error deleting Meeting:", error);
      }
    }
  };

  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  const indexOfLastMeeting = currentPage * itemsPerPage;
  const indexOfFirstMeeting = indexOfLastMeeting - itemsPerPage;
  const currentMeetings = missedMeetings.slice(
    indexOfFirstMeeting,
    indexOfLastMeeting
  );

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container px-5 mx-auto my-10">
            <div className="border mt-4 p-2">
              <div className="flex justify-between items-center mb-5">
                <h2 className="text-center text-3xl font-bold">
                  Missed Meetings
                </h2>
              </div>
              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p className="text-red-500">{error}</p>
              ) : missedMeetings.length === 0 ? (
                <p>No missed meetings found.</p>
              ) : (
                <table className="min-w-full divide-y divide-gray-200 border">
                  <thead className="text-xs text-gray-700 font-bold border-t border-gray-200 text-left uppercase">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 text-sm tracking-wider"
                      >
                        S.N.
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-sm bg-gray-50 uppercase tracking-wider"
                      >
                        Doctor Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-sm uppercase tracking-wider"
                      >
                        Company Name
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-sm bg-gray-50 uppercase tracking-wider"
                      >
                        Assigned
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-sm uppercase tracking-wider"
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-sm bg-gray-50 uppercase tracking-wider"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-sm uppercase tracking-wider"
                      >
                        Time
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-sm bg-gray-50 uppercase tracking-wider"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentMeetings.map((meeting, index) => (
                      <tr key={meeting.id} className="border-b border-gray-200">
                        <td scope="row" className="px-2 py-2">
                          {index + 1}
                        </td>
                        <td className="px-2 py-2 font-medium text-gray-900 bg-gray-50">
                          {meeting.doctorName}
                        </td>
                        <td className="px-2 py-2 text-gray-900">
                          {meeting.companyName}
                        </td>
                        <td className="px-2 py-2 bg-gray-50">
                          {meeting.assignedName}
                        </td>
                        <td className="px-2 py-2">{meeting.assignedRole}</td>
                        <td className="px-2 py-2 bg-gray-50">
                          {format(new Date(meeting.date), "dd/MM/yyyy")}
                        </td>
                        <td className="px-2 py-2">
                          {meeting.time}
                        </td>
                        <td className="px-2 py-2 bg-gray-50">
                          <button
                            // onClick={() => toggleCalendar(meeting.id)}
                            type="button"
                            className="text-white bg-[#8697C4] rounded-lg p-2 text-center me-1 mb-2"
                          >
                            <FaEdit /> {/* Modify */}
                          </button>

                          <button
                            onClick={() => handleDeleteMeeting(meeting.id)}
                            type="button"
                            className="text-white bg-[#8697C4] rounded-lg p-2 text-center me-1 mb-2"
                          >
                            <MdAutoDelete /> {/* Delete */}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div className="flex justify-end my-4">
                {Array.from(
                  { length: Math.ceil(missedMeetings.length / itemsPerPage) },
                  (_, i) => (
                    <button
                      key={i}
                      className={`px-3 py-2 mx-1 rounded-md font-bold ${currentPage === i + 1
                          ? "bg-transparent text-gray-800 border border-[#8697C4] hover:bg-[#8697C4] hover:text-white"
                          : "bg-transparent text-gray-800 border border-gray-300 hover:bg-gray-300"
                        }`}
                      onClick={() => setCurrentPage(i + 1)}
                    >
                      {i + 1}
                    </button>
                  )
                )}
              </div>
              <div className="text-gray-600 text-sm mb-4 text-end">
                {`Showing ${currentMeetings.length} out of ${missedMeetings.length} matches`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminMissedMeeting;
