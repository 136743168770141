import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import CompanySide from "./CompanySide";
import CompanyNavbar from "./CompanyNavbar";
import Swal from "sweetalert2";
import Chatbox from "./Chatbox";
import "../style/Company.css";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaCommentSlash } from "react-icons/fa";
import { IoSearchSharp } from "react-icons/io5";
import { fetchMessages, sendReply } from "./CompanyFirebase";

const CompanyMessage = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [messages, setMessages] = useState([]);
  const [replyMessage, setReplyMessage] = useState("");
  const [currentConversation, setCurrentConversation] = useState(null);
  const [showChatbox, setShowChatbox] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { id } = useParams();
  const location = useLocation();

  const predefinedMessages = ["Welcome!", "Thank You."];

  const doctorIdFromState = location.state?.doctorId;

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleReplyMessageChange = (e) => {
    setReplyMessage(e.target.value);
  };

  const handleSendReply = async () => {
    if (!replyMessage.trim()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Message cannot be empty!",
      });
      return;
    }

    try {
      const { newMessage, replyData } = await sendReply(replyMessage, currentConversation, id);

      setReplyMessage("");

      setCurrentConversation((prev) => {
        return {
          ...prev,
          messages: [...prev.messages, newMessage],
          recentMessage: {
            text: replyData.messages,
            isCompany: replyData.sentBy === "company",
            date: newMessage.date,
            time: newMessage.time,
            timestamp: replyData.timestamp,
          },
        };
      });

      // Update the main messages array to reflect the new message in the conversation
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.map((conv) => {
          if (
            conv.doctorID === currentConversation.doctorID &&
            conv.companyID === currentConversation.companyID
          ) {
            return {
              ...conv,
              messages: [...conv.messages, newMessage],
              recentMessage: {
                text: replyData.messages,
                isCompany: replyData.sentBy === "company",
                date: newMessage.date,
                time: newMessage.time,
                timestamp: replyData.timestamp,
              },
            };
          }
          return conv;
        });
        return updatedMessages;
      });
    } catch (error) {
      console.error("Error sending reply:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Failed to send reply. Please try again.",
      });
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const { sortedMessages, conversation } = await fetchMessages(id, doctorIdFromState);
      setMessages(sortedMessages);

      if (doctorIdFromState) {
        setCurrentConversation(conversation);
      } else if (sortedMessages.length > 0) {
        setCurrentConversation(sortedMessages[0]);
      }
      setShowChatbox(conversation || sortedMessages.length > 0);
    };
    fetch();
  }, [id]);

  const handleReply = (conversation) => {
    setCurrentConversation(conversation);
    setShowChatbox(true);
  };

  const handleCloseChat = () => {
    setShowChatbox(false);
  };

  const handlePredefinedMessageClick = (message) => {
    setReplyMessage(message);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterMessages = (messages) => {
    return messages.filter((conversation) =>
      conversation.doctorName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <div className="flex flex-col h-screen">
      <CompanyNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <CompanySide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div
            className="flex mt-5 px-3"
            style={{ maxHeight: "calc(100vh - 6rem)" }}
          >
            <div
              className={`conversation-list w-full md:w-1/3 ${showChatbox && "hidden md:block"
                }`}
              style={{ borderRadius: "5% 0 0 0", boxShadow: "0 0 4px #aeafb2" }}
            >
              <div style={{ background: "#0D47A1", borderRadius: "0 0 0% 0" }}>
                <h2 className="text-[1.5rem] font-bold font-[K2D] text-center text-white py-4">
                  Chat
                </h2>
              </div>
              <div
                className="conversation-container px-2 overflow-y-auto"
                style={{
                  maxHeight: "calc(100vh - 10rem)",
                  overflowY: "auto",
                  background: "#F9F9FB",
                  borderRadius: "5% 0 0 0",
                }}
              >
                <div className="flex flex-col relative justify-center self-stretch border mt-2">
                  <div className="flex rounded-lg">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      placeholder="Search doctor..."
                      className="w-full px-3 py-2 border-b focus:outline-none focus:border-[#aeafb2]"
                    />
                    <button
                      type="button"
                      className="flex-shrink-0 inline-flex px-2 items-center bg-[#0D47A1] text-white"
                    >
                      <IoSearchSharp />
                    </button>
                  </div>
                </div>
                {filterMessages(messages).map((conversation, index) => (
                  <div
                    key={index}
                    className="px-4 py-2 border-b my-2 shadow-lg cursor-pointer hover:bg-gray-100"
                    onClick={() => handleReply(conversation)}
                    style={{ background: "white" }}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        <div
                          id="condocname"
                          className="font-bold text-gray-700"
                        >
                          {conversation.doctorName}
                        </div>
                        <div
                          id="conrecent"
                          className="text-sm font-bold text-gray-600 mt-1"
                        >
                          {conversation.recentMessage.text}
                        </div>
                      </div>
                      <div className="text-xs text-gray-400 text-right">
                        <div>{conversation.recentMessage.date}</div>
                        <div>{conversation.recentMessage.time}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {showChatbox && currentConversation ? (
              <div className="chatbox-container w-full md:w-2/3 flex flex-col border">
                <div className="shadow-lg p-4 flex items-center justify-between">
                  <h3 className="font-bold text-xl">
                    {currentConversation?.doctorName}
                  </h3>
                  <button
                    className="hover:text-gray-700"
                    onClick={handleCloseChat}
                  >
                    <IoIosCloseCircleOutline size={24} />
                  </button>
                </div>
                <div className="flex-1 overflow-y-auto bg-[#fafafa] p-4 chat-content">
                  <Chatbox
                    conversation={currentConversation}
                    replyMessage={replyMessage}
                    handleReplyMessageChange={handleReplyMessageChange}
                    currentUserId={id}
                    setCurrentConversation={setCurrentConversation}
                  />
                </div>
                <div className="px-2 py-2 border-t bg-white">
                  <div className="flex flex-wrap">
                    {predefinedMessages.map((message, index) => (
                      <button
                        key={index}
                        className="px-2 py-1 bg-[#4BCB5D] text-white rounded-md mr-2 mb-2"
                        onClick={() => handlePredefinedMessageClick(message)}
                      >
                        {message}
                      </button>
                    ))}
                  </div>
                  <textarea
                    className="w-full p-2 border rounded"
                    rows="2"
                    placeholder="Type your message..."
                    value={replyMessage}
                    onChange={handleReplyMessageChange}
                  />
                  <button
                    className="bg-[#7191E6] text-white float-end px-4 py-2 rounded"
                    onClick={handleSendReply}
                  >
                    Send
                  </button>
                </div>
              </div>
            ) : (
              <div
                id="hide-on-small-screen"
                className="flex-1 flex flex-col items-center justify-center text-gray-500 bg-white rounded-lg shadow-lg p-10 m-4"
              >
                <FaCommentSlash className="text-gray-400 text-6xl mb-4" />
                <p className="text-lg font-semibold">No conversation found</p>
                <p className="text-sm text-gray-400 mt-2">
                  Start a conversation by selecting a contact or sending a
                  message.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyMessage;
