import React, { useState, useEffect } from "react";
import { useNavigate, Link, Navigate, useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { IoMdArrowBack } from "react-icons/io";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import { fetchHospitalDataByID, deleteHospital } from "./AdminFirebase";

const ViewHsopitalDetails = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [hospitals, setHospitals] = useState({});
  const [hospitalName, setHospitalName] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [hospitalSettings, setHospitalSetting] = useState(false);
  const { isAdminLoggedIn } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const hospitalDoctors = () => {
    navigate(`/admin/hospitals/${id}/doctors`);
  };

  const hospitalMeetings = () => {
    navigate(`/admin/hospitals/${id}/meetings`);
  };

  const hospitalEarnings = () => {
    navigate(`/admin/hospitals/${id}/earnings`);
  };

  const toggleHospitalSettings = () => {
    setHospitalSetting(!hospitalSettings);
  };

  useEffect(() => {
    const fetchData = async () => {
      const hospitalData = await fetchHospitalDataByID(id);
      setHospitals(hospitalData);
      setHospitalName(hospitalData.hospitalName);
    };

    fetchData();
  }, []);

  const handleDeleteProfile = async () => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this profile?"
    );

    if (confirmed) {
      try {
        await deleteHospital(id);
        navigate(`/admin/hospital`);
      } catch (error) {
        console.error("Error deleting hospital profile:", error);
      }
    }
  };

  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${
            sidebarOpen ? "ml-60" : "ml-20"
          }`}
        >
          <div className="container mx-auto px-5 md:px-3 h-full">
            <div className="overflow-hidden px-10 md:px-5" id="companyedits">
              <div className="flex items-center justify-between mb-4">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                >
                  <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
                </button>
              </div>

              <div className="border shadow rounded-lg">
                <div id="companydetails" className="md:h-auto">
                  <div className="flex items-center justify-between my-5">
                    <h1
                      className="text-xl font-bold uppercase px-6 md:px-2"
                      id="companyNames"
                    >
                      {hospitalName} Details
                    </h1>
                  </div>
                  <div
                    className="flex items-center justify-between mb-2 font-medium px-6 md:px-2"
                    onClick={hospitalDoctors}
                  >
                    <p className="cursor-pointer">Doctors </p>
                    <button type="button">
                      <IoIosArrowRoundForward size={30} />
                    </button>
                  </div>
                  <hr className="mb-3 p-0 border-gray-300"></hr>
                  <div
                    className="flex items-center justify-between mb-2 font-medium px-6 md:px-2"
                    onClick={hospitalMeetings}
                  >
                    <p className="cursor-pointer"> Meetings </p>
                    <button type="button">
                      <IoIosArrowRoundForward size={30} />
                    </button>
                  </div>
                  <hr className="mb-3 p-0 border-gray-300"></hr>
                  <div
                    className="flex items-center justify-between mb-2 font-medium px-6 md:px-2"
                    onClick={hospitalEarnings}
                  >
                    <p className="cursor-pointer"> Earnings </p>
                    <button type="button">
                      <IoIosArrowRoundForward size={30} />
                    </button>
                  </div>
                  <hr className="mb-3 p-0 border-gray-300"></hr>
                  <div
                    className="flex items-center justify-between mb-2 font-medium px-6 md:px-2"
                    onClick={toggleHospitalSettings}
                  >
                    <p className="cursor-pointer"> Setting </p>
                    <button type="button">
                      <IoIosArrowRoundForward size={30} />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {hospitalSettings && (
              <div className="px-10 md:px-5 mt-2">
                <div className="shadow border-2 p-2 rounded-lg">
                  <div className="flex justify-between">
                    <p className="font-medium flex items-center">
                      Delete Hospital
                    </p>
                    <button
                      onClick={handleDeleteProfile}
                      className="px-2 py-1 bg-[#fa3131] text-white rounded-md hover:bg-[#cf1e1e] focus:outline-none focus:bg-gray-400"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewHsopitalDetails;
