import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { IoMdArrowBack } from "react-icons/io";
import { FaRegCreditCard } from "react-icons/fa";
import { useAuth } from "../AuthContext";
import { fetchMeetingsByDoctorId, fetchDoctorDataByID } from "./AdminFirebase";

const ViewDoctorsEarning = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const { id } = useParams();
    const [projectedEarning, setProjectedEarning] = useState(0);
    const [finalEarning, setFinalEarning] = useState(0);
    const [doctorName, setDoctorName] = useState([]);
    const navigate = useNavigate();
    const { isAdminLoggedIn } = useAuth();


    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            const doctorData = await fetchDoctorDataByID(id);
            setDoctorName(doctorData.name);

            const {AllMeetings, completedMeetings } = await fetchMeetingsByDoctorId(id);

            const projected = AllMeetings.length * 150;
            const final = completedMeetings.length * 150;

            setProjectedEarning(projected);
            setFinalEarning(final);
        }

        fetchData();
    }, [id]);

    if (!isAdminLoggedIn) {
        return <Navigate to="/admin" />;
    }


    return (
        <div className="flex flex-col h-screen">
            <AdminNavbar />
            <div className="flex flex-1 mt-[4.2rem]">
                <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
                <div
                    className={`overflow-y-auto flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
                        }`}
                >
                    <div className="container mx-auto px-5 md:px-3 h-full">
                        <div className="overflow-hidden px-10 md:px-5" id="companyedits">
                            <div className="flex items-center justify-between mb-5">
                                <button
                                    type="button"
                                    onClick={() => navigate(-1)}
                                    className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                                >
                                    <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
                                </button>
                                <h2 className="flex-grow text-xl mt-2 font-bold text-center uppercase">
                                    {doctorName} Earnings
                                </h2>
                            </div>

                            <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4 mt-5">
                                <div className="bg-gray-100 border shadow-sm rounded p-5">
                                    <div className="flex space-x-4 items-center">
                                        <div>
                                            <div className="bg-white rounded-full w-12 h-12 flex justify-center items-center">
                                                <FaRegCreditCard className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                                            </div>
                                        </div>
                                        <div className="cursor-pointer">
                                            <div className="">Projected Earning</div>
                                            <div className="text-2xl font-bold text-gray-900">
                                                {projectedEarning}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-100 border shadow-sm rounded p-5">
                                    <div className="flex space-x-4 items-center">
                                        <div>
                                            <div className="bg-white rounded-full w-12 h-12 flex justify-center items-center">
                                                <FaRegCreditCard className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                                            </div>
                                        </div>
                                        <div className="cursor-pointer">
                                            <div className="">Final Earning</div>
                                            <div className="text-2xl font-bold text-gray-900">
                                                {finalEarning}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewDoctorsEarning