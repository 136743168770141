import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { IoMdArrowBack } from "react-icons/io";
import { FaRegCreditCard } from "react-icons/fa";
import { format } from "date-fns";
import Swal from "sweetalert2";
import { useAuth } from "../AuthContext";
import { fetchMeetingsByCompanyId, fetchCompanyDataByID, updateCompanyCredits } from "./AdminFirebase";

const ViewCompanyCredits = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const { id } = useParams();
    const [companies, setCompanies] = useState({});
    const [totalCredits, setTotalCredits] = useState(0);
    const [consumedCredits, setConsumedCredits] = useState(0);
    const [availableCredits, setAvailableCredits] = useState(0);
    const [companyName, setCompanyName] = useState([]);
    const navigate = useNavigate();
    const { isAdminLoggedIn } = useAuth();


    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        const fetchData = async () => {
            const companyData = await fetchCompanyDataByID(id);
            setCompanyName(companyData.companyName);

            const { completedMeetings } = await fetchMeetingsByCompanyId(id);

            const currentTotalCredits = companyData.credits || 0;
            const currentConsumedCredits = completedMeetings.length;
            const currentAvailableCredits = currentTotalCredits - currentConsumedCredits;

            setTotalCredits(currentTotalCredits);
            setConsumedCredits(currentConsumedCredits);
            setAvailableCredits(currentAvailableCredits);
        }

        fetchData();
    }, [id]);

    const handleAddCredits = async () => {
        const { value: newCredits } = await Swal.fire({
            title: "Enter New Credits",
            input: "number",
            inputLabel: "Number of credits to add",
            inputPlaceholder: "Enter number of credits",
            showCancelButton: true,
        });

        if (newCredits) {
            const additionalCredits = parseInt(newCredits);
            const updatedTotalCredits = totalCredits + additionalCredits;
            const updatedAvailableCredits = updatedTotalCredits - consumedCredits;

            const updatedCompany = {
                ...companies,
                credits: (companies.credits || 0) + additionalCredits,
            };

            setTotalCredits(updatedTotalCredits);
            setAvailableCredits(updatedAvailableCredits);
            setCompanies(updatedCompany);

            try {
                await updateCompanyCredits(id, additionalCredits);
            } catch (error) {
                console.error("Error updating credits:", error);
            }
        }
    };

    if (!isAdminLoggedIn) {
        return <Navigate to="/admin" />;
    }


    return (
        <div className="flex flex-col h-screen">
            <AdminNavbar />
            <div className="flex flex-1 mt-[4.2rem]">
                <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
                <div
                    className={`overflow-y-auto flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
                        }`}
                >
                    <div className="container mx-auto px-5 md:px-3 h-full">
                        <div className="overflow-hidden px-10 md:px-5" id="companyedits">
                            <div className="flex items-center justify-between mb-5">
                                <button
                                    type="button"
                                    onClick={() => navigate(-1)}
                                    className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                                >
                                    <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
                                </button>
                                <h2 className="flex-grow text-xl mt-2 font-bold text-center uppercase">
                                    {companyName} Credits
                                </h2>
                            </div>

                            <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4 mt-5">
                                <div className="bg-gray-100 border shadow-sm rounded p-5">
                                    <div className="flex space-x-4 items-center">
                                        <div>
                                            <div className="bg-white rounded-full w-12 h-12 flex justify-center items-center">
                                                <FaRegCreditCard className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                                            </div>
                                        </div>
                                        <div className="cursor-pointer">
                                            <div className="">Total Credits</div>
                                            <div className="text-2xl font-bold text-gray-900">
                                                {totalCredits}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-100 border shadow-sm rounded p-5">
                                    <div className="flex space-x-4 items-center">
                                        <div>
                                            <div className="bg-white rounded-full w-12 h-12 flex justify-center items-center">
                                                <FaRegCreditCard className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                                            </div>
                                        </div>
                                        <div className="cursor-pointer">
                                            <div className="">Available Credits</div>
                                            <div className="text-2xl font-bold text-gray-900">
                                                {availableCredits}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-100 border shadow-sm rounded p-5">
                                    <div className="flex space-x-4 items-center">
                                        <div>
                                            <div className="bg-white rounded-full w-12 h-12 flex justify-center items-center">
                                                <FaRegCreditCard className="h-6 w-6 text-[#8697C4] cursor-pointer" />
                                            </div>
                                        </div>
                                        <div className="cursor-pointer">
                                            <div className="">Consumed Credits</div>
                                            <div className="text-2xl font-bold text-gray-900">
                                                {consumedCredits}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-gray-100 border shadow-sm rounded p-5">
                                    <div className="flex justify-center items-center">
                                        <button
                                            onClick={handleAddCredits}
                                            className="px-4 py-2 bg-[#8697C4] text-white rounded-md"
                                        >
                                            Add Credit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewCompanyCredits