import { getFirestore, collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc, deleteDoc, } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject, } from "firebase/storage";
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword, createUserWithEmailAndPassword, sendEmailVerification, } from "firebase/auth";
import { auth, db, storage } from "../firebase";
import { format } from "date-fns";
import { v4 as uuid } from "uuid";

//Add users
export const addUser = async (email, password, userData) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    await sendEmailVerification(user);

    const customId = `${userData.firstName}_${userData.lastName}_${userData.companyId}`;
    const customDocRef = doc(db, "users", customId);
    await setDoc(customDocRef, {
      ...userData,
    });
    return true;
  } catch (error) {
    console.error("Error adding document :", error);
    throw error;
  }
};

//Add Product
export const addProduct = async (productName, productDetails, imageUrl, companyId) => {
  try {
    const customId = `${productName}_${companyId}`;
    const customDocRef = doc(db, "products", customId);
    await setDoc(customDocRef, { productName, productDetails, image: imageUrl, companyId, });
    return true;
  } catch (error) {
    console.log("Error adding document :", error);
    return false;
  }
};

//fetch users
export const fetchCompanyUsers = async (id) => {
  try {
    const q = query(collection(db, "users"), where("companyId", "==", id));
    const querySnapshot = await getDocs(q);
    const usersList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // Categorize users by their role
    const salesUsers = usersList.filter(user => user.role === 'Sales Head');
    const mrUsers = usersList.filter(user => user.role === 'Medical Representative');

    return { usersList, salesUsers, mrUsers };
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

//fetch Products
export const fetchCompanyProducts = async (id) => {
  try {
    const q = query(collection(db, "products"), where("companyId", "==", id));
    const querySnapshot = await getDocs(q);
    const productsList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return productsList;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

//upload images for products
export const uploadImage = async (file) => {
  const storageRef = ref(storage, `images/products/${file.name}`);
  try {
    const uploadTaskSnapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(uploadTaskSnapshot.ref);

    return url;
  } catch (error) {
    console.error("Error uploading image:", error);
    return null;
  }
};

//function to fetch hospital
export const fetchHospitals = async () => {
  const hospitalsCollection = collection(db, "hospitals");
  const hospitalsSnapshot = await getDocs(hospitalsCollection);
  const hospitalsData = hospitalsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return hospitalsData
}

//function to fetch Doctors if not have hospital
export const fetchDoctorsWithoutHospitalId = async () => {
  try {
    const q = query(collection(db, "doctors"));
    const querySnapshot = await getDocs(q);
    const doctors = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(doctor => doctor.hospitalId === "N/A" || doctor.hospitalId === null || !("hospitalId" in doctor));
    
    return doctors;
  } catch (error) {
      throw new Error("Error fetching doctors: " + error.message);
  }
};

//function to fetch Doctors by hospitalId
export const fetchDoctorsByHospitalId = async (hospitalId) => {
  try {
    const q = query(collection(db, "doctors"), where("hospitalId", "==", hospitalId));
    const querySnapshot = await getDocs(q);
    const doctors = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return doctors;
  } catch (error) {
    throw new Error("Error fetching doctors: " + error.message);
  }
}

// Function to fetch doctors
export const fetchDoctors = async () => {
  // Fetch doctors
  const doctorsCollection = collection(db, "doctors");
  const doctorsSnapshot = await getDocs(doctorsCollection);
  const doctorsData = doctorsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // Fetch hospitals
  const hospitalsCollection = collection(db, "hospitals");
  const hospitalsSnapshot = await getDocs(hospitalsCollection);
  const hospitalsMap = hospitalsSnapshot.docs.reduce((acc, doc) => {
    const data = doc.data();
    acc[doc.id] = data.hospitalName;
    return acc;
  }, {});

  // Add hospital names to doctors data
  return doctorsData.map((doctor) => ({
    ...doctor,
    hospitalName: hospitalsMap[doctor.hospitalId] || "N/A",
  }));
};

// Function to fetch selected doctors
export const fetchSelectedDoctors = async (companyId) => {
  const selectedDoctorsCollection = collection(db, "selectedDoctors");
  const q = query(
    selectedDoctorsCollection,
    where("companyId", "==", companyId)
  );
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => doc.data().doctorId);
};

// Function to update selected doctors
export const updateSelectedDoctors = async (
  doctorId,
  companyId,
  selectedDoctors
) => {
  const selectedDoctorsCollection = collection(db, "selectedDoctors");
  const docId = `${doctorId}_${companyId}`; // Create document ID using doctorId and companyId
  const docRef = doc(selectedDoctorsCollection, docId);

  if (selectedDoctors.includes(doctorId)) {
    // Remove from selectedDoctors collection
    await deleteDoc(docRef);
    return selectedDoctors.filter((doc) => doc !== doctorId);
  } else {
    // Add to selectedDoctors collection
    await setDoc(docRef, { doctorId, companyId });
    return [...selectedDoctors, doctorId];
  }
};

//messages
//Function to send a reply message
export const sendReply = async (replyMessage, currentConversation, id) => {
  try {
    if (!currentConversation || !currentConversation.doctorID) {
      throw new Error("Conversation or doctor ID not found.");
    }

    const senderId = id;

    const replyData = {
      companyID: id,
      doctorID: currentConversation.doctorID,
      messageId: currentConversation.messages[0].messageId,
      messages: replyMessage,
      sentBy: "company",
      sentId: senderId,
      timestamp: new Date(),
    };

    const customId = `${id}_${currentConversation.doctorID}_${Date.now()}`;
    const customDocRef = doc(db, "messages", customId);
    await setDoc(customDocRef, replyData);

    const timestamp = replyData.timestamp;
    const date = format(timestamp, "dd/MM/yyyy");
    const time = format(timestamp, "hh:mm:ss a");

    const newMessage = {
      messageId: replyData.messageId,
      sentId: replyData.sentId,
      id: customId,
      message: replyData.messages,
      sentBy: replyData.sentBy,
      date,
      time,
    };

    return { newMessage, replyData };
  } catch (error) {
    throw new Error(`Error sending reply: ${error.message}`);
  }
};

// Function to fetch messages
export const fetchMessages = async (id, doctorIdFromState) => {
  try {
    const messageRef = collection(db, "messages");
    const q = query(messageRef, where("companyID", "==", id));
    const querySnapshot = await getDocs(q);

    const fetchDoctorData = async (doctorId) => {
      const doctorDocRef = doc(db, "doctors", doctorId);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      if (doctorDocSnapshot.exists()) {
        return doctorDocSnapshot.data();
      } else {
        console.error(`Doctor with ID ${doctorId} not found`);
        return null;
      }
    };

    const fetchAssignedData = async (messageId) => {
      let assignedName;

      try {
        const companyDocRef = doc(db, "companies", messageId);
        const companyDocSnapshot = await getDoc(companyDocRef);

        if (companyDocSnapshot.exists()) {
          assignedName = companyDocSnapshot.data().name;
        } else {
          const userDocRef = doc(db, "users", messageId);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            assignedName = `${userData.firstName} ${userData.lastName}`;
          } else {
            console.error(`No document found with ID ${messageId}`);
          }
        }
      } catch (error) {
        console.error("Error fetching assigned data:", error);
      }

      return assignedName;
    };

    const groupedMessages = {};
    const promises = querySnapshot.docs.map(async (doc) => {
      const messageData = doc.data();
      const assignedName = await fetchAssignedData(messageData.messageId);
      const doctorData = await fetchDoctorData(messageData.doctorID);
      const doctorName = doctorData ? doctorData.name : "Unknown Doctor";

      const key = `${messageData.doctorID}_${messageData.companyID}`;
      if (!groupedMessages[key]) {
        groupedMessages[key] = {
          doctorName,
          assignedName,
          doctorID: messageData.doctorID,
          companyID: messageData.companyID,
          messages: [],
          recentMessage: {
            text: "",
            isCompany: false,
            date: "",
            time: "",
          },
        };
      }

      const timestamp = messageData.timestamp?.toDate();
      const date = timestamp ? format(timestamp, "dd/MM/yyyy") : "N/A";
      const time = timestamp ? format(timestamp, "hh:mm:ss a") : "N/A";

      groupedMessages[key].messages.push({
        messageId: messageData.messageId,
        sentId: messageData.sentId,
        id: doc.id,
        message: messageData.messages,
        sentBy: messageData.sentBy,
        date,
        time,
      });

      // Check if the current message is the most recent one
      if (
        !groupedMessages[key].recentMessage.timestamp ||
        timestamp > groupedMessages[key].recentMessage.timestamp
      ) {
        groupedMessages[key].recentMessage = {
          text: messageData.messages,
          isCompany: messageData.sentBy === "company",
          date,
          time,
          timestamp,
        };
      }
    });

    await Promise.all(promises);

    // Convert groupedMessages object to array
    const messagesArray = Object.keys(groupedMessages).map(
      (key) => groupedMessages[key]
    );

    // Sort messages by recent message timestamp
    const sortedMessages = sortMessagesByTimestamp(messagesArray);

    // Find and open the specific conversation if needed
    const conversation = doctorIdFromState
      ? sortedMessages.find((conv) => conv.doctorID === doctorIdFromState)
      : null;

    return { sortedMessages, conversation };
  } catch (error) {
    console.error("Error fetching messages:", error);
  }
};

// Function to sort messages by timestamp
const sortMessagesByTimestamp = (messagesArray) => {
  return messagesArray.sort((a, b) => {
    const timestampA = a.recentMessage.timestamp;
    const timestampB = b.recentMessage.timestamp;
    if (timestampA && timestampB) {
      return timestampB - timestampA;
    }
    return 0;
  });
};

// Fetch meetings
export const fetchMeetings = async (id, searchDate, searchLocation) => {
  const meetingsRef = collection(db, "scheduleMeeting");
  const q = query(meetingsRef, where("companyID", "==", id));
  const querySnapshot = await getDocs(q);
  const now = new Date();

  // Fetch additional data functions
  const fetchDoctorData = async (doctorId) => {
    const doctorDocRef = doc(db, "doctors", doctorId);
    const doctorDocSnapshot = await getDoc(doctorDocRef);
    return doctorDocSnapshot.exists() ? doctorDocSnapshot.data() : null;
  };

  const fetchCompanyData = async (companyId) => {
    const companyDocRef = doc(db, "companies", companyId);
    const companyDocSnapshot = await getDoc(companyDocRef);
    return companyDocSnapshot.exists() ? companyDocSnapshot.data() : null;
  };

  const fetchAssignedData = async (assigned) => {
    let assignedName, assignedRole;
    try {
      const companyDocRef = doc(db, "companies", assigned);
      const companyDocSnapshot = await getDoc(companyDocRef);
      if (companyDocSnapshot.exists()) {
        assignedName = companyDocSnapshot.data().name;
        assignedRole = companyDocSnapshot.data().role;
      } else {
        const userDocRef = doc(db, "users", assigned);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          assignedName = `${userData.firstName} ${userData.lastName}`;
          assignedRole = userData.role;
        } else {
          console.error(`No document found with ID ${assigned}`);
        }
      }
    } catch (error) {
      console.error("Error fetching assigned data:", error);
    }
    return { assignedName, assignedRole };
  };

  // Fetch and process meetings
  const meetingsData = await Promise.all(
    querySnapshot.docs.map(async (docSnapshot) => {
      const meetingData = docSnapshot.data();
      const doctorData = await fetchDoctorData(meetingData.doctorID);
      const companyData = await fetchCompanyData(meetingData.companyID);
      const { assignedName, assignedRole } = await fetchAssignedData(
        meetingData.assigned
      );

      // Update meeting status if necessary
      const meetingDateTime = new Date(`${meetingData.date} ${meetingData.time}`);
      const timeDiff = now - meetingDateTime;
      const hoursDiff = timeDiff / (1000 * 60 * 60);

      if (hoursDiff > 24 && meetingData.status !== "completed") {
        meetingData.status = "missed";
        await updateDoc(doc(db, "scheduleMeeting", docSnapshot.id), {
          status: "missed",
        });
      }

      return {
        id: docSnapshot.id,
        ...meetingData,
        doctorName: doctorData ? doctorData.name : "Unknown Doctor",
        companyName: companyData ? companyData.companyName : "Unknown Company",
        location: doctorData ? doctorData.location : "Unknown location",
        assignedName,
        assignedRole,
      };
    })
  );

  // Filter meetings based on search criteria
  const filteredMeetings = meetingsData.filter((meeting) => {
    const matchesDate =
      !searchDate ||
      format(new Date(meeting.date), "yyyy-MM-dd") === searchDate;
    const matchesLocation =
      !searchLocation ||
      meeting.location.toLowerCase().includes(searchLocation.toLowerCase());
    return matchesDate && matchesLocation;
  });

  const filteredUpcomingMeetings = filteredMeetings.filter((meeting) => {
    return meeting.status !== "completed" && meeting.status !== "missed";
  });

  const filteredMissedMeetings = filteredMeetings.filter((meeting) => {
    return meeting.status === "missed";
  });

  const filteredCompletedMeetings = filteredMeetings.filter((meeting) => {
    return meeting.status === "completed";
  });

  const sortMeetings = (meetings) =>
    meetings.sort(
      (a, b) =>
        new Date(`${a.date} ${a.time}`) - new Date(`${b.date} ${b.time}`)
    );

  return {
    upcomingMeetings: sortMeetings(filteredUpcomingMeetings),
    missedMeetings: sortMeetings(filteredMissedMeetings),
    completedMeetings: sortMeetings(filteredCompletedMeetings),
  };
};

// Update meeting
export const updateMeeting = async (
  selectedMeetingId,
  selectedDate,
  selectedTime,
  meetingType,
  doctorName,
  id
) => {
  if (!selectedMeetingId) return;

  try {
    const meetingDocRef = doc(db, "scheduleMeeting", selectedMeetingId);
    const meetingDocSnapshot = await getDoc(meetingDocRef);
    if (!meetingDocSnapshot.exists()) {
      throw new Error(`Document with ID ${selectedMeetingId} does not exist`);
    }

    console.log(meetingDocSnapshot.data());

    const adjustedDate = new Date(selectedDate);
    const ISTOffset = 330;
    adjustedDate.setMinutes(adjustedDate.getMinutes() + ISTOffset);
    const formattedDate = adjustedDate.toISOString().split("T")[0];

    // const meetingLink = meetingType !== "offline"
    //   ? `https://meet.jit.si/${doctorName.replace(/\s+/g, "")}-${id}-${Date.now()}`
    //   : null;

    const updateSchedule = {
      date: formattedDate,
      time: selectedTime,
      status: "Rescheduled",
      meetingType: meetingType,
      // meetingLink: meetingLink,
    };

    // Remove meetingLink if updating to 'offline' and it exists
    // if (meetingType === "offline") {
    //   updateSchedule.meetingLink = deleteField();
    // }

    await updateDoc(meetingDocRef, updateSchedule);
  } catch (error) {
    console.error("Error updating schedule meeting:", error);
    throw error;
  }
};

// Delete meeting
export const deleteMeeting = async (meetingId) => {
  try {
    await deleteDoc(doc(db, "scheduleMeeting", meetingId));
  } catch (error) {
    console.error("Error deleting Meeting:", error);
    throw error;
  }
};

// Accept meeting
export const acceptMeeting = async (meetingId) => {
  try {
    const meetingDocRef = doc(db, "scheduleMeeting", meetingId);
    await updateDoc(meetingDocRef, { status: "Accepted" });
  } catch (error) {
    console.error("Error accepting meeting:", error);
    throw error;
  }
};

// Fetch Company
export const fetchCompany = async (id, storage) => {
  try {
    const docRef = doc(db, "companies", id);
    const companySnapshot = await getDoc(docRef);
    if (companySnapshot.exists()) {
      const companyData = { id: companySnapshot.id, ...companySnapshot.data() };
      let imageUrl = null;
      if (companyData.image) {
        try {
          imageUrl = await getDownloadURL(ref(storage, companyData.image));
        } catch (error) {
          if (error.code === "storage/object-not-found") {
            console.warn("Image not found, returning blank");
            imageUrl = ""; // Return blank or null if the image is not found
          } else {
            throw error;
          }
        }
      }
      return { companyData, imageUrl };
    } else {
      console.log("No such company");
      return { companyData: null, imageUrl: null };
    }
  } catch (error) {
    console.log("Error fetching company:", error);
    return { companyData: null, imageUrl: null };
  }
};

// Fetch Products
export const fetchProducts = async (id) => {
  try {
    const productsCollection = collection(db, "products");
    const q = query(productsCollection, where("companyId", "==", id));
    const querySnapshot = await getDocs(q);
    const productsList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return productsList;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

//Image Upload
export const handleImageUpload = async (file) => {
  try {
    if (file) {
      const imageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(imageRef, file);
      const downloadURL = await getDownloadURL(imageRef);
      return downloadURL;
    }
    return null;
  } catch (error) {
    console.error("Error uploading image:", error);
    return null;
  }
};

//Image Remove
export const handleImageRemove = async (image) => {
  try {
    if (image) {
      await deleteObject(ref(storage, image));
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error removing image:", error);
    return false;
  }
};

// Update Company Data
export const updateCompanyData = async (id, newData) => {
  try {
    const companyRef = doc(db, "companies", id);
    await updateDoc(companyRef, newData);
    console.log("Document successfully updated!");
    return true;
  } catch (error) {
    console.error("Error updating document:", error);
    return false;
  }
};

//Change Password
export const changePassword = async (oldPassword, newPassword) => {
  try {
    const user = auth.currentUser;
    if (user) {
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      return true;
    } else {
      console.error("No user is signed in.");
      return false;
    }
  } catch (error) {
    console.error("Error changing password:", error);
    return false;
  }
};

//meetings chart
export const fetchScheduledChart = async (id) => {
  try {
    const scheduleMeetingsRef = collection(db, "scheduleMeeting");
    const q = query(scheduleMeetingsRef, where("companyID", "==", id));
    const querySnapshot = await getDocs(q);

    const meetingsByMonth = {};
    const missedMeetingsByMonth = {};

    querySnapshot.forEach((doc) => {
      const meetingData = doc.data();
      const meetingDate = new Date(meetingData.date);
      const month = meetingDate.toLocaleString("default", { month: "short" });
      const year = meetingDate.getFullYear();
      const key = `${month} ${year}`;

      if (!meetingsByMonth[key]) {
        meetingsByMonth[key] = 0;
      }
      meetingsByMonth[key]++;

      // Check for missed meetings
      const now = new Date();
      if (meetingDate < now && meetingData.status !== "completed") {
        if (!missedMeetingsByMonth[key]) {
          missedMeetingsByMonth[key] = 0;
        }
        missedMeetingsByMonth[key]++;
      }
    });

    return { meetingsByMonth, missedMeetingsByMonth };
  } catch (error) {
    console.error("Error fetching meetings data:", error);
    return { chartData: {} };
  }
};

//meeting counts
export const fetchAssignedUserData = async (id) => {
  try {
    const scheduleMeetingsRef = collection(db, "scheduleMeeting");
    const q = query(scheduleMeetingsRef, where("companyID", "==", id));
    const querySnapshot = await getDocs(q);

    const assignedUsersCount = {};
    querySnapshot.forEach((doc) => {
      const meetingData = doc.data();
      const assigned = meetingData.assigned;

      if (!assignedUsersCount[assigned]) {
        assignedUsersCount[assigned] = 0;
      }

      assignedUsersCount[assigned]++;
    });

    const userPromises = Object.keys(assignedUsersCount).map(async (userId) => {
      let assignedName = "";
      let assignedRole = "";

      try {
        const companyDocRef = doc(db, "companies", userId);
        const companyDocSnapshot = await getDoc(companyDocRef);

        if (companyDocSnapshot.exists()) {
          assignedName = companyDocSnapshot.data().name;
          assignedRole = companyDocSnapshot.data().role;
        } else {
          const userDocRef = doc(db, "users", userId);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            assignedName = `${userData.firstName} ${userData.lastName}`;
            assignedRole = userData.role;
          } else {
            console.error(`No document found with ID ${userId}`);
          }
        }
      } catch (error) {
        console.error("Error fetching assigned data:", error);
      }

      return {
        userId,
        assignedName,
        assignedRole,
        count: assignedUsersCount[userId],
      };
    });

    return Promise.all(userPromises);
  } catch (error) {
    console.error("Error fetching assigned users data:", error);
    return { userData: {} };
  }
};

//fetch Doctors Profile
export const fetchDoctorProfile = async (doctorId) => {
  try {
    const doctorRef = doc(db, "doctors", doctorId);
    const doctorDoc = await getDoc(doctorRef);
    if (doctorDoc.exists()) {
      const doctorData = doctorDoc.data();

      let hospitalName = "N/A";
      if (doctorData.hospitalId && doctorData.hospitalId !== "N/A") {
        const hospitalRef = doc(db, "hospitals", doctorData.hospitalId);
        const hospitalSnapshot = await getDoc(hospitalRef);
  
        if (hospitalSnapshot.exists()) {
          hospitalName = hospitalSnapshot.data().hospitalName;
        }
      }
      return {
        ...doctorData,
        hospitalName,
      };
    } else {
      console.log("Doctor not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching doctor profile: ", error.message);
    return null;
  }
};

//function to schedule meetings
export const bookCompanyScheduleMeeting = async (scheduleData) => {
  try {
    const {
      doctorId,
      selectedTime,
      formattedDate,
      id,
      meetingType,
      assigned,
      doctorName,
    } = scheduleData;

    // Generate a unique meeting ID
    let meetingId = uuid().slice(0, 4);
    const meetingsRef = collection(db, "scheduleMeeting");
    let idExists = true;

    while (idExists) {
      const q = query(meetingsRef, where("meetingId", "==", meetingId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        idExists = false;
      } else {
        meetingId = uuid().slice(0, 4); // Generate a new ID if the current one is taken
      }
    }

    // const existingMeetingsQuery = query(
    //   meetingsRef,
    //   where("doctorID", "==", doctorId),
    //   where("date", "==", formattedDate),
    //   where("time", "==", selectedTime)
    // );
    // const existingMeetingsSnapshot = await getDocs(existingMeetingsQuery);

    // if (!existingMeetingsSnapshot.empty) {
    //   alert("Doctor already has a meeting scheduled at this time.");
    //   throw new Error("Doctor already has a meeting scheduled at this time.");
    // }

    const meetingLink = `https://meet.jit.si/${doctorName.replace(
      /\s+/g,
      ""
    )}-${id}-${Date.now()}`;

    const schedule = {
      companyID: id,
      doctorID: doctorId,
      date: formattedDate,
      time: selectedTime,
      meetingLink: meetingLink,
      status: "scheduled",
      assigned: assigned,
      meetingType: meetingType,
      meetingId: meetingId,
    };

    const customId = `${doctorId}_${assigned}`;
    const customDocRef = doc(db, "scheduleMeeting", customId);
    const docSnapshot = await getDoc(customDocRef);

    if (docSnapshot.exists()) {
      const existingMeeting = docSnapshot.data();

      if (existingMeeting.status === "completed") {
        // Create a new document for the completed meeting with a new ID
        const newCompletedDocRef = doc(
          db,
          "scheduleMeeting",
          `${customId}-${meetingId}`
        );
        await setDoc(newCompletedDocRef, existingMeeting);

        await setDoc(customDocRef, schedule);
      } else {
        await setDoc(customDocRef, {
          ...existingMeeting,
          date: formattedDate,
          time: selectedTime,
          meetingLink: meetingLink,
          meetingType: meetingType,
          status: "scheduled",
        });
      }
    } else {
      await setDoc(customDocRef, schedule);
    }

    return meetingLink;
  } catch (error) {
    console.error("Error scheduling meeting:", error);
    throw error;
  }
};

//function to send messages
export const sendCompanyMessages = async (messageData) => {
  try {
    const { doctorId, message, id } = messageData;

    const sendmessages = {
      companyID: id,
      doctorID: doctorId,
      messages: message,
      sentBy: "company",
      timestamp: new Date(),
      messageId: id,
      sentId: id,
    };

    const customId = `${doctorId}_${id}_${Date.now()}`;
    const customDocRef = doc(db, "messages", customId);
    await setDoc(customDocRef, sendmessages);
  } catch (error) {
    console.error("Error sending messages:", error);
    throw error;
  }
};

//my Doctors
//fetch Doctors of my doctors
export const fetchMyDoctors = async (id) => {
  try {
    const selectedDoctorsCollection = collection(db, "selectedDoctors");
    const q = query(selectedDoctorsCollection, where("companyId", "==", id));
    const selectedSnapshot = await getDocs(q);
    const selectedDoctorIds = selectedSnapshot.docs.map(
      (doc) => doc.data().doctorId
    );

    // Fetch meetings data
    const meetingsQuery = query(
      collection(db, "scheduleMeeting"),
      where("companyID", "==", id)
    );
    const meetingsSnapshot = await getDocs(meetingsQuery);
    const meetingsDoctorIds = meetingsSnapshot.docs.map(
      (doc) => doc.data().doctorID
    );

    // Fetch message data
    const messagesQuery = query(
      collection(db, "messages"),
      where("companyID", "==", id)
    );
    const messagesSnapshot = await getDocs(messagesQuery);
    const messagesDoctorIds = messagesSnapshot.docs.map(
      (doc) => doc.data().doctorID
    );

    // Combine doctor IDs from selectedDoctors and meetings, ensuring uniqueness
    const allRelevantDoctorIds = Array.from(
      new Set([
        ...selectedDoctorIds,
        ...meetingsDoctorIds,
        ...messagesDoctorIds,
      ])
    );

    // Fetch doctor details
    const doctorsData = await Promise.all(
      allRelevantDoctorIds.map(async (doctorId) => {
        const doctorRef = doc(db, "doctors", doctorId);
        const doctorSnapshot = await getDoc(doctorRef);
        if (doctorSnapshot.exists()) {
          const data = doctorSnapshot.data();

          let hospitalName = "N/A";
          if (data.hospitalId) {
            const hospitalRef = doc(db, "hospitals", data.hospitalId);
            const hospitalSnapshot = await getDoc(hospitalRef);
            hospitalName = hospitalSnapshot.exists()
              ? hospitalSnapshot.data().hospitalName
              : "N/A";
          }

          // Count meetings and messages for each doctor
          const meetingCount = meetingsDoctorIds.filter(
            (id) => id === doctorId
          ).length;
          const messageCount = messagesDoctorIds.filter(
            (id) => id === doctorId
          ).length;
          return {
            id: doctorSnapshot.id,
            ...data,
            name: data.name || "",
            specialist: data.specialist || "",
            location: data.location || "",
            hospitalName,
            meetingCount,
            messageCount,
          };
        }
        return null;
      })
    );

    // Filter out null values if any doctor data is missing
    return doctorsData.filter((doctor) => doctor !== null);
  } catch (error) {
    throw new Error("Error fetching doctors: " + error.message);
  }
};

//message my doctors
export const messageForMyDoctors = async (id, doctorId) => {
  const messagesQuery = query(
    collection(db, "messages"),
    where("companyID", "==", id),
    where("doctorID", "==", doctorId)
  );
  const messagesSnapshot = await getDocs(messagesQuery);
  return messagesSnapshot;
};


//check if meeting is exists with another company
export const checkConflictMeetings = async (doctorId, companyId) => {
  if (!doctorId || !companyId) {
    console.error('Invalid parameters: doctorId or companyId is undefined');
    return {};
  }
  const meetingsRef = collection(db, "scheduleMeeting");
  const q = query(meetingsRef, where("doctorID", "==", doctorId));
  const querySnapshot = await getDocs(q);
  const conflictDates = {};

  querySnapshot.forEach((doc) => {
    const meetingData = doc.data();
    const meetingDate = meetingData.date;
    const meetingTime = meetingData.time;
    const meetingCompanyId = meetingData.companyID;

    if (meetingCompanyId !== companyId) {
      if (!conflictDates[meetingDate]) {
        conflictDates[meetingDate] = [];
      }
      conflictDates[meetingDate].push(meetingTime);
    }

  });
  return conflictDates;
}

//schedule meeting for my doctors
export const scheduleMeetingMyDoctors = async (
  selectedDoctor,
  selectedTime,
  selectedDate,
  id,
  meetingType,
  assigned,
  doctorId,
) => {
  try {
    const adjustedDate = new Date(selectedDate);
    const ISTOffset = 330;
    adjustedDate.setMinutes(adjustedDate.getMinutes() + ISTOffset);
    const formattedDate = adjustedDate.toISOString().split("T")[0];
    const selectedDateTime = new Date(`${formattedDate} ${selectedTime}`);

    if (selectedDateTime < new Date()) {
      throw new Error(
        "You cannot schedule a meeting for a past date and time!"
      );
    }

    // Generate a unique meeting ID
    let meetingId = uuid().slice(0, 4);
    const meetingsRef = collection(db, "scheduleMeeting");
    let idExists = true;

    while (idExists) {
      const q = query(meetingsRef, where("meetingId", "==", meetingId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        idExists = false;
      } else {
        meetingId = uuid().slice(0, 4); // Generate a new ID if the current one is taken
      }
    }

    // const existingMeetingsQuery = query(
    //   meetingsRef,
    //   where("doctorID", "==", doctorId),
    //   where("date", "==", formattedDate),
    //   where("time", "==", selectedTime),
    //   where("assigned", "!=", id)
    // );
    // const existingMeetingsSnapshot = await getDocs(existingMeetingsQuery);

    // if (!existingMeetingsSnapshot.empty) {
    //   alert("Doctor already has a meeting scheduled at this time.");
    //   throw new Error("Doctor already has a meeting scheduled at this time.");
    // }

    const meetingLink = `https://meet.jit.si/${selectedDoctor.name.replace(
      /\s+/g,
      ""
    )}-${id}-${Date.now()}`;
    const customId = `${selectedDoctor.id}_${assigned}`;
    const customDocRef = doc(db, "scheduleMeeting", customId);

    // Check if a meeting already exists
    const existingMeetingDoc = await getDoc(customDocRef);

    const scheduleData = {
      companyID: id,
      doctorID: selectedDoctor.id,
      date: formattedDate,
      time: selectedTime,
      meetingLink: meetingLink,
      meetingType: meetingType,
      status: "scheduled",
      assigned: assigned,
      meetingId: meetingId,
    };

    if (existingMeetingDoc.exists()) {
      const existingMeetingData = existingMeetingDoc.data();

      if (existingMeetingData.status === "completed") {
        // Create a new document for the completed meeting with a new ID
        const newCompletedDocRef = doc(
          db,
          "scheduleMeeting",
          `${customId}-${meetingId}`
        );
        await setDoc(newCompletedDocRef, existingMeetingData);

        await setDoc(customDocRef, scheduleData);
      } else {
        await setDoc(customDocRef, {
          ...existingMeetingData,
          date: formattedDate,
          time: selectedTime,
          meetingLink: meetingLink,
          meetingType: meetingType,
          status: "scheduled",
        });
      }
    } else {
      await setDoc(customDocRef, scheduleData);
    }

    return true;
  } catch (error) {
    throw new Error("Error scheduling meeting: " + error.message);
  }
};

//delete my doctors
export const deleteMyDoctor = async (doctorId, companyId) => {
  try {
    const selectedDoctorsRef = collection(db, "selectedDoctors");
    const doctorQuery = query(
      selectedDoctorsRef,
      where("doctorId", "==", doctorId),
      where("companyId", "==", companyId)
    );
    const querySnapshot = await getDocs(doctorQuery);

    if (querySnapshot.empty) {
      throw new Error("Doctor not found in selectedDoctors collection");
    }

    const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));
    await Promise.all(deletePromises);

    return true;
  } catch (error) {
    throw new Error("Error deleting doctor: " + error.message);
  }
};

//Timeline
export const convertTo24HourFormat = (timeStr) => {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

const fetchAssignedData = async (db, assigned) => {
  let assignedName, assignedRole;

  try {
    const companyDocRef = doc(db, "companies", assigned);
    const companyDocSnapshot = await getDoc(companyDocRef);

    if (companyDocSnapshot.exists()) {
      assignedName = companyDocSnapshot.data().name;
      assignedRole = companyDocSnapshot.data().role;
    } else {
      const userDocRef = doc(db, "users", assigned);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        assignedName = `${userData.firstName} ${userData.lastName}`;
        assignedRole = userData.role;
      } else {
        console.error(`No document found with ID ${assigned}`);
      }
    }
  } catch (error) {
    console.error("Error fetching assigned data:", error);
  }

  return { assignedName, assignedRole };
};

export const fetchallMeetings = async (companyId, doctorId) => {
  try {
    const meetingsQuery = query(
      collection(db, "scheduleMeeting"),
      where("companyID", "==", companyId),
      where("doctorID", "==", doctorId)
    );
    const meetingsSnapshot = await getDocs(meetingsQuery);

    // Fetch doctor names and add details to meetings
    const fetchDoctorName = async (doctorID) => {
      const doctorDocRef = doc(db, "doctors", doctorID);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      return doctorDocSnapshot.exists()
        ? `${doctorDocSnapshot.data().name}`
        : "Unknown";
    };

    const meetingsData = await Promise.all(
      meetingsSnapshot.docs.map(async (doc) => {
        const data = doc.data();
        const { assignedName, assignedRole } = await fetchAssignedData(db, data.assigned);
        const doctorName = await fetchDoctorName(data.doctorID);

        // Convert time to 24-hour format
        const time24 = convertTo24HourFormat(data.time);

        // Combine date and time into a single Date object
        const meetingDateTime = new Date(`${data.date}T${time24}:00`);

        // Validate the meeting date
        if (isNaN(meetingDateTime)) {
          console.error("Invalid meeting date:", meetingDateTime);
          return null;
        }

        return {
          ...data,
          assignedName,
          assignedRole,
          doctorName,
          meetingDate: meetingDateTime, // Keep the Date object for sorting
          formattedDate: format(meetingDateTime, "dd/MM/yyyy"),
          formattedTime: format(meetingDateTime, "hh:mm a"),
          // isPast: meetingDateTime < new Date()
        };
      })
    );

    // Filter out invalid meetings
    const validMeetingsData = meetingsData.filter(
      (meeting) => meeting !== null
    );

    // Sort meetings by the Date object
    validMeetingsData.sort((a, b) => a.meetingDate - b.meetingDate);

    return validMeetingsData;
  } catch (error) {
    throw new Error("Error fetching meetings: " + error.message);
  }
};

// Function to fetch company data
export const fetchCompanysData = async (id) => {
  try {
    const db = getFirestore();
    const docRef = doc(db, "companies", id);
    const companySnapshot = await getDoc(docRef);
    if (companySnapshot.exists()) {
      return { id: companySnapshot.id, ...companySnapshot.data() };
    } else {
      console.log("No such company");
      return null;
    }
  } catch (error) {
    console.log("Error fetching company:", error);
    throw error;
  }
};

// Function to fetch products by company ID
export const fetchProductsByCompanyId = async (companyId) => {
  try {
    const productsCollection = collection(db, "products");
    const q = query(productsCollection, where("companyId", "==", companyId));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.log("Error fetching products:", error);
    throw error;
  }
};

//check if meeting exist for doctor
export const checkIfMeetingExists = async (doctorId, companyId) => {
  const meetingsCollection = collection(db, "scheduleMeeting");
  const now = new Date();
  const q = query(
    meetingsCollection,
    where("companyID", "==", companyId),
    where("doctorID", "==", doctorId),
    where("assigned", "==", companyId)
  );
  const querySnapshot = await getDocs(q);

  const meetings = querySnapshot.docs
    .map((doc) => ({ id: doc.id, ...doc.data() }))
    .filter((meeting) => {
      const meetingDateTime = new Date(`${meeting.date} ${meeting.time}`);
      return meetingDateTime > now && meeting.status !== "completed";
    });

  return meetings.length > 0 ? meetings : false;
};

export const updateMeetingStatus = async (
  selectedMeetingId,
  status,
  mrFeedback
) => {
  const meetingDocRef = doc(db, "scheduleMeeting", selectedMeetingId);
  const updateData = {
    status: status,
  };

  if (status === "completed") {
    updateData.mrFeedback = mrFeedback;
  }
  await updateDoc(meetingDocRef, updateData);
};
