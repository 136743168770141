import React, { useState } from "react";
import background from "../assets/img/Group4.png";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";

const ExpandableSection = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mt-5">
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={toggleOpen}
      >
        <div className="flex items-center text-blue-400">
          <img
            loading="lazy"
            alt="Protfolio"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/bfc929c4e17327763b5f719c427519ab5493b6b3ab39a57bd3948400ec46f1eb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfc929c4e17327763b5f719c427519ab5493b6b3ab39a57bd3948400ec46f1eb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfc929c4e17327763b5f719c427519ab5493b6b3ab39a57bd3948400ec46f1eb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfc929c4e17327763b5f719c427519ab5493b6b3ab39a57bd3948400ec46f1eb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfc929c4e17327763b5f719c427519ab5493b6b3ab39a57bd3948400ec46f1eb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfc929c4e17327763b5f719c427519ab5493b6b3ab39a57bd3948400ec46f1eb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfc929c4e17327763b5f719c427519ab5493b6b3ab39a57bd3948400ec46f1eb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w"
            className=" w-4 h-4 mr-2"
          />
          {title}
        </div>
        {isOpen ? (
          <AiOutlineUp className=" text-blue-400" />
        ) : (
          <AiOutlineDown className="text-blue-400" />
        )}
      </div>
      {isOpen && <div className="mt-3 text-neutral-600 pl-7">{content}</div>}
    </div>
  );
};

const DocProtfolio = () => {
  return (
    <div className="relative">
      <div
        className="relative flex flex-col items-center justify-center px-4 py-20 sm:px-8 md:px-16 sm:py-28 md:py-36 w-full border border-solid border-zinc-950"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "auto",
          minHeight: "680px",
        }}
      >
        <div className="flex flex-col md:flex-row gap-5 w-full max-w-[1021px]">
          <div className="flex flex-col w-full md:w-2/5">
            <img
              loading="lazy"
              srcSet="https://hips.hearstapps.com/hmg-prod/images/portrait-of-a-happy-young-doctor-in-his-clinic-royalty-free-image-1661432441.jpg?crop=0.66698xw:1xh;center,top&resize=1200:*"
              className="object-contain w-full rounded-full mt-10 md:mt-0"
              alt="Doctor's Portrait"
            />
          </div>
          <div className="flex flex-col mt-10 md:mt-0 md:ml-20 w-full md:w-3/5">
            <div className="flex flex-col w-full font-semibold text-slate-100">
              <div className="flex flex-col items-start pr-2.5 md:pr-10">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5966411f8e96f0f1cc1bc1cb925656e3512f58f8eb4766346a4322cc213165a9?width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5966411f8e96f0f1cc1bc1cb925656e3512f58f8eb4766346a4322cc213165a9?width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5966411f8e96f0f1cc1bc1cb925656e3512f58f8eb4766346a4322cc213165a9?width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5966411f8e96f0f1cc1bc1cb925656e3512f58f8eb4766346a4322cc213165a9?width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5966411f8e96f0f1cc1bc1cb925656e3512f58f8eb4766346a4322cc213165a9?width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5966411f8e96f0f1cc1bc1cb925656e3512f58f8eb4766346a4322cc213165a9?width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5966411f8e96f0f1cc1bc1cb925656e3512f58f8eb4766346a4322cc213165a9?width=2000 2000w"
                  className="object-contain max-w-full w-[106px]"
                  alt="Decorative Element"
                />
                <div className="mt-5 text-3xl sm:text-4xl md:text-5xl">
                  DR: SMITH DOE
                </div>
                <div className="self-stretch mt-5 md:mt-10 text-xs text-blue-200">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority are randomized words that don't
                  look even remotely similar.
                </div>
              </div>

              <div className="sm:flex-row mt-10 text-xs">
                <button className="flex-1 px-9 py-4 sm:py-6 text-slate-500 bg-white border border-solid border-slate-300 transform hover:-translate-y-2 transition duration-300">
                  Book Appointment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex overflow-hidden flex-col items-center px-16 bg-white max-md:px-5">
        <div className="w-full max-w-[1035px] max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            <div className="flex flex-col w-[64%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow items-start pt-16 pr-4  w-full text-xs font-semibold bg-black bg-opacity-0 max-md:pl-5 max-md:mt-10 max-md:max-w-full">
                <div className="text-xs text-stone-300">
                  Service has been start since 2014
                </div>
                <div className="mt-3.5 text-xl text-blue-500">
                  Welcome to Smith Medical Clinic
                </div>
                <div className="self-stretch mt-8 mr-7 text-zinc-400 max-md:mr-2.5 max-md:max-w-full">
                  Contrary to popular belief, Lorem lpsum is not simply random
                  text. It has roots in a piece of
                  <br />
                  the classical Latin literature from 45 BC, making it over 2000
                  years old. Richard McClintock,
                  <br />
                  a Latin professor at Hampden-Sydney College in Virginia looked
                  up one of the more obscure Latin
                  <br />
                  words, consectetur, from a Lorem Ipsum passage, and going
                  through the cites of the word in classical literature.
                </div>
                <div className="mt-7 text-zinc-400 max-md:max-w-full">
                  discovered the undoubtable source. Lorem Ipsum comes from
                  sections 1.10.32 and 1.10.33 p
                  <br />
                  offer &quot;de Finibus Bonorum et Malorum&quot; (The Extremes
                  of Good and Evil) by Cicero.
                </div>
              </div>

              <ExpandableSection
                title="Know my medical history"
                content="This section can contain more detailed information about your medical history. It could include any past surgeries, chronic conditions, and other relevant health information."
              />
              <ExpandableSection
                title="View my medical success"
                content="This section could highlight your medical achievements, like successful surgeries, patient recoveries, and other notable accomplishments."
              />
            </div>

            <div className="flex flex-col ml-5 w-[36%] max-md:ml-0 max-md:w-full">
              <div className="flex overflow-hidden flex-col justify-center px-px py-8 mt-20 w-full bg-sky-600 max-md:mt-10">
                <div className="flex flex-col pt-1 w-full bg-black bg-opacity-0">
                  <div className="flex gap-4 self-center max-w-full text-base font-black text-blue-100">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/cd278edba730487ce6a626134778d7bc63b53c17cdb9fb2e6aa2e835b303ebe4?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd278edba730487ce6a626134778d7bc63b53c17cdb9fb2e6aa2e835b303ebe4?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd278edba730487ce6a626134778d7bc63b53c17cdb9fb2e6aa2e835b303ebe4?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd278edba730487ce6a626134778d7bc63b53c17cdb9fb2e6aa2e835b303ebe4?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd278edba730487ce6a626134778d7bc63b53c17cdb9fb2e6aa2e835b303ebe4?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd278edba730487ce6a626134778d7bc63b53c17cdb9fb2e6aa2e835b303ebe4?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/cd278edba730487ce6a626134778d7bc63b53c17cdb9fb2e6aa2e835b303ebe4?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w"
                      className="object-contain mt-1 self-start w-4 aspect-square"
                      alt="Protfolio"
                    />
                    <div>Time For Meet</div>
                  </div>
                  <hr className="mt-2 ml-6 mr-6" />
                  <div className="flex gap-4 self-center max-w-full mt-2 font-semibold text-blue-100">
                    <FaCheck className="object-contain mt-2 self-start w-4 aspect-square" />
                    <div>
                      Monday - Friday
                      <br />
                      8:00 - 19:00
                    </div>
                  </div>
                  <hr className="mt-2 ml-6 mr-6" />
                  <div className="flex gap-4 self-center max-w-full mt-2 font-semibold text-blue-100">
                    <FaCheck className="object-contain mt-2 self-start w-4 aspect-square" />
                    <div>
                      Monday - Friday
                      <br />
                      8:00 - 19:00
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-black mt-6 bg-opacity-0">
        <div className="flex relative flex-col pl-20 w-full min-h-[857px] max-md:pl-5 max-md:max-w-full">
          <div className="flex relative flex-col items-center self-end px-1 pt-px pb-5 w-full font-semibold bg-black bg-opacity-0 max-w-[1326px] max-md:max-w-full">
            <div className="flex shrink-0 self-stretch h-0.5 bg-white max-md:max-w-full" />
            <div className="mt-2.5 text-xs text-zinc-400">Take a Look</div>
            <div className="mt-2 text-2xl text-blue-500">My Qualifications</div>
            <div className="mt-6 text-xs text-center text-stone-300 max-md:max-w-full">
              Contrary to popular belief, Lorem lpsum is not simply random text,
              It has roots in a piece open the classical Latin fager
              <br />
              literature from 45 BC over 2000 years old. a Latin professor at
              Hampden-Sydney College in Virginia
            </div>
          </div>
          <div className="relative self-center mt-7 ml-12 max-w-full w-[793px]">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex relative grow gap-1 max-md:mt-4">
                  <div className="flex flex-col grow shrink-0 basis-0 w-fit">
                    <div className="flex flex-col items-center px-1 pt-2 aspect-square bg-black bg-opacity-0">
                      <div className="flex relative flex-col items-start pt-7 pr-16 pb-12 pl-4 w-full aspect-[1.008] max-md:pr-5">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c4fd609a79cdb9f626b97663ad9fdebce893df8571077a5b9f7e91107fadc70c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4fd609a79cdb9f626b97663ad9fdebce893df8571077a5b9f7e91107fadc70c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4fd609a79cdb9f626b97663ad9fdebce893df8571077a5b9f7e91107fadc70c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4fd609a79cdb9f626b97663ad9fdebce893df8571077a5b9f7e91107fadc70c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4fd609a79cdb9f626b97663ad9fdebce893df8571077a5b9f7e91107fadc70c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4fd609a79cdb9f626b97663ad9fdebce893df8571077a5b9f7e91107fadc70c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4fd609a79cdb9f626b97663ad9fdebce893df8571077a5b9f7e91107fadc70c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c4fd609a79cdb9f626b97663ad9fdebce893df8571077a5b9f7e91107fadc70c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                          className="object-cover absolute inset-0 size-full"
                          alt="Protfolio"
                        />
                        <div className="flex relative gap-7 text-lg font-semibold text-blue-100">
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6461c714b69f68e16a8421d32717d55bae5844a1ec8fcf55f90646e9d0c9bdcc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6461c714b69f68e16a8421d32717d55bae5844a1ec8fcf55f90646e9d0c9bdcc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6461c714b69f68e16a8421d32717d55bae5844a1ec8fcf55f90646e9d0c9bdcc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6461c714b69f68e16a8421d32717d55bae5844a1ec8fcf55f90646e9d0c9bdcc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6461c714b69f68e16a8421d32717d55bae5844a1ec8fcf55f90646e9d0c9bdcc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6461c714b69f68e16a8421d32717d55bae5844a1ec8fcf55f90646e9d0c9bdcc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6461c714b69f68e16a8421d32717d55bae5844a1ec8fcf55f90646e9d0c9bdcc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6461c714b69f68e16a8421d32717d55bae5844a1ec8fcf55f90646e9d0c9bdcc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                            className="object-contain shrink-0 w-16 aspect-[1.56]"
                            alt="Protfolio"
                          />
                          <div className="my-auto basis-auto">
                            Academic Qualifications
                          </div>
                        </div>
                        <div className="flex relative gap-7 items-start mt-12 max-md:mt-10">
                          <div className="flex flex-col items-start text-xs font-semibold whitespace-nowrap">
                            <div className="self-stretch text-xs text-blue-300">
                              2002-2007
                            </div>
                            <div className="mt-16 text-blue-300 max-md:mt-10">
                              1997-1999
                            </div>
                            <div className="mt-24 text-indigo-300 max-md:mt-10">
                              1994-1997
                            </div>
                          </div>
                          <div className="flex flex-col mt-1.5 text-xs">
                            <div className="text-blue-200">
                              Doctor of Philosophy in Geography (Remote
                              <br />
                              Sensing & Gis), Highly commented thesis at Tamil
                              <br />
                              University,Tanjore,Tamilnadu, Bangldesh.
                            </div>
                            <div className="mt-12 text-xs font-semibold text-blue-200 w-[246px] max-md:mt-10 max-md:mr-2.5">
                              Master of Science in Envirment Remote
                              <br />
                              Sensing and Cartography,73%, First Class
                              <br />
                              CompletedatModuraiKamarajJUniverstyMadura
                              <br />
                              Bangldesh
                            </div>
                            <div className="mt-12 text-blue-200 w-[244px] max-md:mt-10 max-md:mr-2.5">
                              Bachelor Of Science in Botany,72%,First Class
                              <br />
                              Completed at Madurai Kamaraj University
                              <br />
                              Madurai, Bangldesh .
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center px-px py-1 mt-20 bg-black bg-opacity-0 max-md:mt-10 max-md:mr-1">
                      <div className="flex relative flex-col py-8 pr-16 pl-8 w-full aspect-[1.512] max-md:px-5">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7e1b8c662afa343776814be684b98adbef156e997d2e2ca03aef9f38db1a664f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7e1b8c662afa343776814be684b98adbef156e997d2e2ca03aef9f38db1a664f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7e1b8c662afa343776814be684b98adbef156e997d2e2ca03aef9f38db1a664f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7e1b8c662afa343776814be684b98adbef156e997d2e2ca03aef9f38db1a664f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7e1b8c662afa343776814be684b98adbef156e997d2e2ca03aef9f38db1a664f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7e1b8c662afa343776814be684b98adbef156e997d2e2ca03aef9f38db1a664f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7e1b8c662afa343776814be684b98adbef156e997d2e2ca03aef9f38db1a664f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7e1b8c662afa343776814be684b98adbef156e997d2e2ca03aef9f38db1a664f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                          className="object-cover absolute inset-0 size-full"
                          alt="Protfolio"
                        />
                        <div className="flex relative gap-5 self-start">
                          <div className="flex flex-col">
                            <img
                              loading="lazy"
                              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b2bf3ff11665d8487bfbafb3949107e73ba5010cd162fe67ea2285b3f4a03c96?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2bf3ff11665d8487bfbafb3949107e73ba5010cd162fe67ea2285b3f4a03c96?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2bf3ff11665d8487bfbafb3949107e73ba5010cd162fe67ea2285b3f4a03c96?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2bf3ff11665d8487bfbafb3949107e73ba5010cd162fe67ea2285b3f4a03c96?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2bf3ff11665d8487bfbafb3949107e73ba5010cd162fe67ea2285b3f4a03c96?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2bf3ff11665d8487bfbafb3949107e73ba5010cd162fe67ea2285b3f4a03c96?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2bf3ff11665d8487bfbafb3949107e73ba5010cd162fe67ea2285b3f4a03c96?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b2bf3ff11665d8487bfbafb3949107e73ba5010cd162fe67ea2285b3f4a03c96?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                              className="object-contain aspect-[0.94] w-[51px] max-md:mr-1.5"
                              alt="Protfolio"
                            />
                            <img
                              loading="lazy"
                              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3251f9ddeacd77d13c98a0747da4c178f11f6e36737651762da3167f1f1d3e46?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3251f9ddeacd77d13c98a0747da4c178f11f6e36737651762da3167f1f1d3e46?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3251f9ddeacd77d13c98a0747da4c178f11f6e36737651762da3167f1f1d3e46?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3251f9ddeacd77d13c98a0747da4c178f11f6e36737651762da3167f1f1d3e46?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3251f9ddeacd77d13c98a0747da4c178f11f6e36737651762da3167f1f1d3e46?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3251f9ddeacd77d13c98a0747da4c178f11f6e36737651762da3167f1f1d3e46?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3251f9ddeacd77d13c98a0747da4c178f11f6e36737651762da3167f1f1d3e46?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3251f9ddeacd77d13c98a0747da4c178f11f6e36737651762da3167f1f1d3e46?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                              className="object-contain self-end mt-7 w-2.5 aspect-square"
                              alt="Protfolio"
                            />
                          </div>
                          <div className="flex flex-col self-start mt-3 font-semibold">
                            <div className="text-lg text-blue-100">
                              License & Certifications
                            </div>
                            <div className="self-start mt-14 text-xs text-blue-200 max-md:mt-10">
                              National Board of Examiners .
                            </div>
                          </div>
                        </div>
                        <div className="flex relative gap-5 self-center mt-5 max-w-full text-xs font-semibold text-blue-200 w-[218px]">
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b24c2fc576412c893f57f1bd5b9b5db5709ab43a2f4e22a8cd4d67873ad49e8c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b24c2fc576412c893f57f1bd5b9b5db5709ab43a2f4e22a8cd4d67873ad49e8c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b24c2fc576412c893f57f1bd5b9b5db5709ab43a2f4e22a8cd4d67873ad49e8c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b24c2fc576412c893f57f1bd5b9b5db5709ab43a2f4e22a8cd4d67873ad49e8c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b24c2fc576412c893f57f1bd5b9b5db5709ab43a2f4e22a8cd4d67873ad49e8c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b24c2fc576412c893f57f1bd5b9b5db5709ab43a2f4e22a8cd4d67873ad49e8c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b24c2fc576412c893f57f1bd5b9b5db5709ab43a2f4e22a8cd4d67873ad49e8c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b24c2fc576412c893f57f1bd5b9b5db5709ab43a2f4e22a8cd4d67873ad49e8c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                            className="object-contain shrink-0 w-2.5 aspect-square"
                            alt="Protfolio"
                          />
                          <div className="grow shrink w-[186px]">
                            ABIM Certification Internal Medicine .
                          </div>
                        </div>
                        <div className="flex relative gap-5 self-end mt-5 text-xs font-semibold text-blue-200">
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c84b580c8851b769c7b24a4c199a4f69cd49118e2793136ce7c533bf18a6e29e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c84b580c8851b769c7b24a4c199a4f69cd49118e2793136ce7c533bf18a6e29e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c84b580c8851b769c7b24a4c199a4f69cd49118e2793136ce7c533bf18a6e29e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c84b580c8851b769c7b24a4c199a4f69cd49118e2793136ce7c533bf18a6e29e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c84b580c8851b769c7b24a4c199a4f69cd49118e2793136ce7c533bf18a6e29e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c84b580c8851b769c7b24a4c199a4f69cd49118e2793136ce7c533bf18a6e29e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c84b580c8851b769c7b24a4c199a4f69cd49118e2793136ce7c533bf18a6e29e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c84b580c8851b769c7b24a4c199a4f69cd49118e2793136ce7c533bf18a6e29e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                            className="object-contain shrink-0 w-2.5 aspect-square"
                            alt="Protfolio"
                          />
                          <div className="basis-auto">
                            ABIM Diploma Cardiovascular Disease .
                          </div>
                        </div>
                        <div className="flex relative gap-5 self-center mt-5 max-w-full text-xs font-semibold text-blue-200 w-[185px]">
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c457d60a71237132cc413b26a4bcec4ff3b3f017d73ee9a7cc5dea602c265f6f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c457d60a71237132cc413b26a4bcec4ff3b3f017d73ee9a7cc5dea602c265f6f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c457d60a71237132cc413b26a4bcec4ff3b3f017d73ee9a7cc5dea602c265f6f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c457d60a71237132cc413b26a4bcec4ff3b3f017d73ee9a7cc5dea602c265f6f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c457d60a71237132cc413b26a4bcec4ff3b3f017d73ee9a7cc5dea602c265f6f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c457d60a71237132cc413b26a4bcec4ff3b3f017d73ee9a7cc5dea602c265f6f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c457d60a71237132cc413b26a4bcec4ff3b3f017d73ee9a7cc5dea602c265f6f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c457d60a71237132cc413b26a4bcec4ff3b3f017d73ee9a7cc5dea602c265f6f?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                            className="object-contain shrink-0 self-start w-2.5 aspect-square"
                            alt="Protfolio"
                          />
                          <div className="grow shrink w-[153px]">
                            Conneecticut 027907 2-09-87
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/56972b8c3f3c744136e70d7f41f02eb09aabd24d0f6e0f8297dc60a95a0c3cf6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/56972b8c3f3c744136e70d7f41f02eb09aabd24d0f6e0f8297dc60a95a0c3cf6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/56972b8c3f3c744136e70d7f41f02eb09aabd24d0f6e0f8297dc60a95a0c3cf6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/56972b8c3f3c744136e70d7f41f02eb09aabd24d0f6e0f8297dc60a95a0c3cf6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/56972b8c3f3c744136e70d7f41f02eb09aabd24d0f6e0f8297dc60a95a0c3cf6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/56972b8c3f3c744136e70d7f41f02eb09aabd24d0f6e0f8297dc60a95a0c3cf6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/56972b8c3f3c744136e70d7f41f02eb09aabd24d0f6e0f8297dc60a95a0c3cf6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/56972b8c3f3c744136e70d7f41f02eb09aabd24d0f6e0f8297dc60a95a0c3cf6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                    className="object-contain shrink-0 self-start aspect-[0.01] w-[9px]"
                    alt="Protfolio"
                  />
                </div>
              </div>
              <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex relative flex-col justify-center items-center px-px mt-56 w-full aspect-square bg-black bg-opacity-0 max-md:mt-10">
                  <div className="flex relative flex-col items-start px-8 pt-7 pb-12 -mt-0.5 ml-12 w-full aspect-square max-md:px-5">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/658ffe59983fc6d97c923684a5da46cd9a08fd02d824378832abe00419df9f52?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/658ffe59983fc6d97c923684a5da46cd9a08fd02d824378832abe00419df9f52?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/658ffe59983fc6d97c923684a5da46cd9a08fd02d824378832abe00419df9f52?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/658ffe59983fc6d97c923684a5da46cd9a08fd02d824378832abe00419df9f52?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/658ffe59983fc6d97c923684a5da46cd9a08fd02d824378832abe00419df9f52?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/658ffe59983fc6d97c923684a5da46cd9a08fd02d824378832abe00419df9f52?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/658ffe59983fc6d97c923684a5da46cd9a08fd02d824378832abe00419df9f52?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/658ffe59983fc6d97c923684a5da46cd9a08fd02d824378832abe00419df9f52?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                      className="object-cover absolute inset-0 size-full"
                      alt="Protfolio"
                    />
                    <div className="flex relative gap-5 justify-between items-start self-stretch font-semibold">
                      <div className="flex flex-col text-xs text-blue-300 whitespace-nowrap">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b9807128cd826b376b3aecf382ddbae51b488a61375c857d44f669721d6cf875?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9807128cd826b376b3aecf382ddbae51b488a61375c857d44f669721d6cf875?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9807128cd826b376b3aecf382ddbae51b488a61375c857d44f669721d6cf875?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9807128cd826b376b3aecf382ddbae51b488a61375c857d44f669721d6cf875?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9807128cd826b376b3aecf382ddbae51b488a61375c857d44f669721d6cf875?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9807128cd826b376b3aecf382ddbae51b488a61375c857d44f669721d6cf875?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9807128cd826b376b3aecf382ddbae51b488a61375c857d44f669721d6cf875?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9807128cd826b376b3aecf382ddbae51b488a61375c857d44f669721d6cf875?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                          className="object-contain aspect-[1.02] w-[46px]"
                          alt="Protfolio"
                        />
                        <div className="mt-12 max-md:mt-10">2002-2007</div>
                      </div>
                      <div className="flex flex-col mt-5">
                        <div className="self-start text-lg text-blue-100">
                          Work and Training
                        </div>
                        <div className="mt-14 text-xs text-blue-200 max-md:mt-10">
                          Most Recent Experience (Title & Department)
                          <br />
                          Responsiblities of Position
                          <br />
                          Hospital ffliation City,State Bangladesh
                        </div>
                      </div>
                    </div>
                    <div className="flex relative gap-9 mt-12 max-md:mt-10">
                      <div className="self-start text-xs font-semibold text-blue-300">
                        1997-1999
                      </div>
                      <div className="text-xs text-blue-200">
                        Continue Experience (Title & Department)
                        <br />
                        Responsibilities of Position
                        <br />
                        Hospital Affiliation City,State Bangladesh
                      </div>
                    </div>
                    <div className="flex relative gap-9 mt-12 max-md:mt-10">
                      <div className="self-start text-xs font-semibold text-indigo-300">
                        1994-1997
                      </div>
                      <div className="text-xs text-blue-200">
                        Training Experience (Title & Department)
                        <br />
                        Responsibilities of Position
                        <br />
                        Hospital Afiliation City,State Bangldesh
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#F6FBFF] flex flex-col md:pl-4 mt-2">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="col-span-1 md:col-span-2 my-5 mx-auto">
            <div>
              <div className="mt-4 text-xl font-bold text-center text-blue-500">
                Services Departments
              </div>
              <div className="mt-4 px-8 text-xs text-center text-zinc-400">
                Contrary to popularbelief,Loremlpsum isnotsimply random <br />
                text.ithas roots in a pieceopen theclassicalLatiniterature{" "}
                <br />
                from45BC,making itover2000yearsold.Richard McClintock,a Latin{" "}
                <br />
                professor at Hampden-Sydney College in Virginia. <br />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-10 mt-5">
              <div className="flex flex-col items-center md:items-start">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fb233b45591df7758bafc00093520478ae9b227e29edbf79d538b4a38344aa70?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb233b45591df7758bafc00093520478ae9b227e29edbf79d538b4a38344aa70?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb233b45591df7758bafc00093520478ae9b227e29edbf79d538b4a38344aa70?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb233b45591df7758bafc00093520478ae9b227e29edbf79d538b4a38344aa70?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb233b45591df7758bafc00093520478ae9b227e29edbf79d538b4a38344aa70?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb233b45591df7758bafc00093520478ae9b227e29edbf79d538b4a38344aa70?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fb233b45591df7758bafc00093520478ae9b227e29edbf79d538b4a38344aa70?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w"
                  className="object-contain aspect-[1.07] w-[62px]"
                  alt="Protfolio"
                />
                <div className="mt-4 text-lg text-blue-500">
                  Electrophysiology Lab
                </div>
                <div className="mt-4 text-xs text-zinc-400">
                  Contrary to popular belief, Lorem Ipsum
                  <br />
                  is not simply random text.
                </div>
              </div>
              <div className="flex flex-col items-center md:items-start">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/bb26931d90dce9c6a52bd99d7a21d82834b850d568b245f26a95acd1307c64a5?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb26931d90dce9c6a52bd99d7a21d82834b850d568b245f26a95acd1307c64a5?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb26931d90dce9c6a52bd99d7a21d82834b850d568b245f26a95acd1307c64a5?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb26931d90dce9c6a52bd99d7a21d82834b850d568b245f26a95acd1307c64a5?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb26931d90dce9c6a52bd99d7a21d82834b850d568b245f26a95acd1307c64a5?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb26931d90dce9c6a52bd99d7a21d82834b850d568b245f26a95acd1307c64a5?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb26931d90dce9c6a52bd99d7a21d82834b850d568b245f26a95acd1307c64a5?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w"
                  className="object-contain aspect-[0.74] w-[43px]"
                  alt="Protfolio"
                />
                <div className="mt-4 text-lg text-blue-500">
                  Open Heart Surgery
                </div>
                <div className="mt-4 text-xs text-zinc-400">
                  Contrary to popular belief, Lorem Ipsum
                  <br />
                  is not simply random text.
                </div>
              </div>
              <div className="flex flex-col items-center md:items-start">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c29c4d435ecf3bd9eaf7dc22449d60b21feb17b5d64248bfc3ba19b1f5b2efe0?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c29c4d435ecf3bd9eaf7dc22449d60b21feb17b5d64248bfc3ba19b1f5b2efe0?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c29c4d435ecf3bd9eaf7dc22449d60b21feb17b5d64248bfc3ba19b1f5b2efe0?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c29c4d435ecf3bd9eaf7dc22449d60b21feb17b5d64248bfc3ba19b1f5b2efe0?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c29c4d435ecf3bd9eaf7dc22449d60b21feb17b5d64248bfc3ba19b1f5b2efe0?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c29c4d435ecf3bd9eaf7dc22449d60b21feb17b5d64248bfc3ba19b1f5b2efe0?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c29c4d435ecf3bd9eaf7dc22449d60b21feb17b5d64248bfc3ba19b1f5b2efe0?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w"
                  className="object-contain aspect-[1.2] w-[62px]"
                  alt="Protfolio"
                />
                <div className="mt-4 text-lg text-blue-500">Eye Surgery</div>
                <div className="mt-4 text-xs text-zinc-400">
                  Contrary to popular belief, Lorem Ipsum
                  <br />
                  is not simply random text.
                </div>
              </div>
              <div className="flex flex-col items-center md:items-start">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/906ac7ced56e198138e6deda3ffb2e7d5f2b96467209fbaa374ea0206230581e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/906ac7ced56e198138e6deda3ffb2e7d5f2b96467209fbaa374ea0206230581e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/906ac7ced56e198138e6deda3ffb2e7d5f2b96467209fbaa374ea0206230581e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/906ac7ced56e198138e6deda3ffb2e7d5f2b96467209fbaa374ea0206230581e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/906ac7ced56e198138e6deda3ffb2e7d5f2b96467209fbaa374ea0206230581e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/906ac7ced56e198138e6deda3ffb2e7d5f2b96467209fbaa374ea0206230581e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/906ac7ced56e198138e6deda3ffb2e7d5f2b96467209fbaa374ea0206230581e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/906ac7ced56e198138e6deda3ffb2e7d5f2b96467209fbaa374ea0206230581e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                  className="object-contain aspect-[1.18] w-[43px]"
                  alt="Protfolio"
                />
                <div className="mt-4 text-lg text-blue-500">Dental Implant</div>
                <div className="mt-4 text-xs text-zinc-400">
                  Contrary to popular belief, Lorem Ipsum
                  <br />
                  is not simply random text.
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1 md:col-span-1">
            <div className="flex flex-col items-center md:items-start">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8c7123f169820f97ec47e91809bd6eed74946dab4bee59b485eeb7f33a7e2682?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7123f169820f97ec47e91809bd6eed74946dab4bee59b485eeb7f33a7e2682?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7123f169820f97ec47e91809bd6eed74946dab4bee59b485eeb7f33a7e2682?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7123f169820f97ec47e91809bd6eed74946dab4bee59b485eeb7f33a7e2682?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7123f169820f97ec47e91809bd6eed74946dab4bee59b485eeb7f33a7e2682?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7123f169820f97ec47e91809bd6eed74946dab4bee59b485eeb7f33a7e2682?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7123f169820f97ec47e91809bd6eed74946dab4bee59b485eeb7f33a7e2682?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8c7123f169820f97ec47e91809bd6eed74946dab4bee59b485eeb7f33a7e2682?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                className="object-contain"
                alt="Protfolio"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex overflow-hidden flex-col bg-white">
        <div className="flex flex-col pt-px pr-5 pb-7 w-full bg-black bg-opacity-0 max-md:max-w-full">
          <div className="flex shrink-0 self-end max-w-full h-px bg-white max-md:mr-1.5" />
          <div className="flex flex-col pb-16 pl-14 mt-5 w-full bg-black bg-opacity-0 max-md:pl-5 max-md:max-w-full">
            <div className="flex flex-wrap items-start font-semibold max-md:max-w-full">
              <div className="w-full md:w-1/2 px-10">
                <div className="flex bg-[#F6FBFF] relative flex-col grow shrink-0 items-end self-end pt-7 pb-px pl-2 basis-0 w-full">
                  {/* <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c78af1f1a0ef27c0c280b01294c637fafc684cac1a4789d727997d37cd31b76c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c78af1f1a0ef27c0c280b01294c637fafc684cac1a4789d727997d37cd31b76c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c78af1f1a0ef27c0c280b01294c637fafc684cac1a4789d727997d37cd31b76c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c78af1f1a0ef27c0c280b01294c637fafc684cac1a4789d727997d37cd31b76c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c78af1f1a0ef27c0c280b01294c637fafc684cac1a4789d727997d37cd31b76c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c78af1f1a0ef27c0c280b01294c637fafc684cac1a4789d727997d37cd31b76c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c78af1f1a0ef27c0c280b01294c637fafc684cac1a4789d727997d37cd31b76c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c78af1f1a0ef27c0c280b01294c637fafc684cac1a4789d727997d37cd31b76c?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                    className="object-cover absolute inset-0 size-full"
                    alt="Protfolio"
                  /> */}
                  <div className="flex relative z-10 flex-col pb-3 mr-0 w-full max-md:max-w-full">
                    <div className="flex gap-5 justify-between self-center max-w-full">
                      <div className="flex flex-col self-start mt-2.5">
                        <div className="self-start text-xs text-neutral-400">
                          Know Our
                        </div>
                        <div className="mt-3.5 text-xl text-blue-500">
                          Regular News
                        </div>
                      </div>
                      {/* <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/037495b2553cc69031d8e6d4a9b56ceb4a4c78f7b1e8df7f794473c87e7dcab6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/037495b2553cc69031d8e6d4a9b56ceb4a4c78f7b1e8df7f794473c87e7dcab6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/037495b2553cc69031d8e6d4a9b56ceb4a4c78f7b1e8df7f794473c87e7dcab6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/037495b2553cc69031d8e6d4a9b56ceb4a4c78f7b1e8df7f794473c87e7dcab6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/037495b2553cc69031d8e6d4a9b56ceb4a4c78f7b1e8df7f794473c87e7dcab6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/037495b2553cc69031d8e6d4a9b56ceb4a4c78f7b1e8df7f794473c87e7dcab6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/037495b2553cc69031d8e6d4a9b56ceb4a4c78f7b1e8df7f794473c87e7dcab6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/037495b2553cc69031d8e6d4a9b56ceb4a4c78f7b1e8df7f794473c87e7dcab6?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                        className="object-contain shrink-0 max-w-full aspect-[1.35] w-[101px]"
                        alt="Protfolio"
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="mt-3 px-5">
                  <div className="flex flex-col w-full font-semibold max-md:mt-10">
                    <div className="mr-5 text-sm text-center text-zinc-400 max-md:mr-2.5 max-md:ml-1.5">
                      Here are many variations of passages of Lorem lpsum
                      available,fut
                      <br />
                      the majority have suffered alteration in some form.
                    </div>
                    <div className="flex flex-col justify-center p-1.5 mt-9 w-full bg-black bg-opacity-0">
                      <div className="flex gap-5 justify-between py-2.5 pr-12 pl-2.5 border-2 border-gray-100 border-solid bg-slate-50 max-md:pr-5">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/f8563a952dce40468c696cd52e9ee1701644405cc6d6ff41e0785f957a338753?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f8563a952dce40468c696cd52e9ee1701644405cc6d6ff41e0785f957a338753?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f8563a952dce40468c696cd52e9ee1701644405cc6d6ff41e0785f957a338753?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f8563a952dce40468c696cd52e9ee1701644405cc6d6ff41e0785f957a338753?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f8563a952dce40468c696cd52e9ee1701644405cc6d6ff41e0785f957a338753?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f8563a952dce40468c696cd52e9ee1701644405cc6d6ff41e0785f957a338753?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f8563a952dce40468c696cd52e9ee1701644405cc6d6ff41e0785f957a338753?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f8563a952dce40468c696cd52e9ee1701644405cc6d6ff41e0785f957a338753?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                          className="object-contain shrink-0 w-24 aspect-[1.16]"
                          alt="Protfolio"
                        />
                        <div className="flex flex-col items-start my-auto">
                          <div className="text-xs text-blue-500">
                            26 JANUARY-2017
                          </div>
                          <div className="flex gap-3.5 mt-2.5 text-xs">
                            <div className="text-stone-300">Publish By</div>
                            <div className="text-blue-300">
                              Daily Pothom Alo
                            </div>
                          </div>
                          <div className="mt-4 text-xs text-gray-400">
                            here are many variations of passages
                            <br />
                            of Lorem Ipsum available fut the ...
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center px-3.5 py-1 mt-1.5 w-full bg-black bg-opacity-0">
                      <div className="flex gap-5 justify-between py-2.5 pr-12 pl-0.5 border border-solid bg-slate-50 border-slate-100 max-md:pr-5">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/dd3798179bcad4b6218fbc6bf2fba72851d891be52e63ed66a7a9b99fd615bbc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd3798179bcad4b6218fbc6bf2fba72851d891be52e63ed66a7a9b99fd615bbc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd3798179bcad4b6218fbc6bf2fba72851d891be52e63ed66a7a9b99fd615bbc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd3798179bcad4b6218fbc6bf2fba72851d891be52e63ed66a7a9b99fd615bbc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd3798179bcad4b6218fbc6bf2fba72851d891be52e63ed66a7a9b99fd615bbc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd3798179bcad4b6218fbc6bf2fba72851d891be52e63ed66a7a9b99fd615bbc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd3798179bcad4b6218fbc6bf2fba72851d891be52e63ed66a7a9b99fd615bbc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd3798179bcad4b6218fbc6bf2fba72851d891be52e63ed66a7a9b99fd615bbc?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                          className="object-contain shrink-0 w-24 aspect-[1.16]"
                          alt="Protfolio"
                        />
                        <div className="flex flex-col items-start my-auto">
                          <div className="text-xs text-blue-500">
                            15 AUGUST-2017
                          </div>
                          <div className="flex gap-3.5 mt-2.5 text-xs">
                            <div className="text-gray-400">Publish By</div>
                            <div className="text-blue-300">
                              The-etiquette-dally
                            </div>
                          </div>
                          <div className="mt-4 text-xs text-zinc-400">
                            here are many variations of passages
                            <br />
                            of Lorem Ipsum available fut the ..
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-center px-1.5 py-1 mt-2 w-full bg-black bg-opacity-0">
                      <div className="flex gap-5 justify-between py-2.5 pr-12 pl-2.5 border-2 border-gray-100 border-solid bg-slate-50 max-md:pr-5">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/563c76c56be88e390d49e8b1fa8b36d446113d5056c4bbc7757a682ac1e1d179?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/563c76c56be88e390d49e8b1fa8b36d446113d5056c4bbc7757a682ac1e1d179?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/563c76c56be88e390d49e8b1fa8b36d446113d5056c4bbc7757a682ac1e1d179?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/563c76c56be88e390d49e8b1fa8b36d446113d5056c4bbc7757a682ac1e1d179?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/563c76c56be88e390d49e8b1fa8b36d446113d5056c4bbc7757a682ac1e1d179?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/563c76c56be88e390d49e8b1fa8b36d446113d5056c4bbc7757a682ac1e1d179?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/563c76c56be88e390d49e8b1fa8b36d446113d5056c4bbc7757a682ac1e1d179?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/563c76c56be88e390d49e8b1fa8b36d446113d5056c4bbc7757a682ac1e1d179?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                          className="object-contain shrink-0 aspect-[1.16] w-[95px]"
                          alt="Protfolio"
                        />
                        <div className="flex flex-col items-start my-auto">
                          <div className="text-xs text-blue-500">
                            30 DECEMBER-2016
                          </div>
                          <div className="flex gap-3.5 mt-2.5 text-xs">
                            <div className="text-zinc-400">Publish By</div>
                            <div className="text-blue-300">
                              Daily Pothom Alo
                            </div>
                          </div>
                          <div className="mt-4 text-xs text-zinc-400">
                            here are many variations of passages
                            <br />
                            of Lorem Ipsum available fut the ..
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-6 self-end mt-8 mr-10 text-xs text-zinc-400 max-md:mr-2.5">
                      <div>View All</div>
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1001c9e601c6e1e263f08176983346f3dc88b663f0526c5a2b2ecc5ad7809cfb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1001c9e601c6e1e263f08176983346f3dc88b663f0526c5a2b2ecc5ad7809cfb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1001c9e601c6e1e263f08176983346f3dc88b663f0526c5a2b2ecc5ad7809cfb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1001c9e601c6e1e263f08176983346f3dc88b663f0526c5a2b2ecc5ad7809cfb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1001c9e601c6e1e263f08176983346f3dc88b663f0526c5a2b2ecc5ad7809cfb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1001c9e601c6e1e263f08176983346f3dc88b663f0526c5a2b2ecc5ad7809cfb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1001c9e601c6e1e263f08176983346f3dc88b663f0526c5a2b2ecc5ad7809cfb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1001c9e601c6e1e263f08176983346f3dc88b663f0526c5a2b2ecc5ad7809cfb?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                        className="object-contain shrink-0 self-start w-2.5 aspect-[2.5]"
                        alt="Protfolio"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2 px-10">
                <div className="flex bg-[#F6FBFF] relative flex-col grow shrink-0 items-start self-start pt-7 pb-px pl-2 basis-0 w-full">
                  {/* <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3ad124c4608e03f03c8e36ead2334cd80560be42af8823b4bcbdc5f9fb93f447?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ad124c4608e03f03c8e36ead2334cd80560be42af8823b4bcbdc5f9fb93f447?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ad124c4608e03f03c8e36ead2334cd80560be42af8823b4bcbdc5f9fb93f447?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ad124c4608e03f03c8e36ead2334cd80560be42af8823b4bcbdc5f9fb93f447?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ad124c4608e03f03c8e36ead2334cd80560be42af8823b4bcbdc5f9fb93f447?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ad124c4608e03f03c8e36ead2334cd80560be42af8823b4bcbdc5f9fb93f447?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ad124c4608e03f03c8e36ead2334cd80560be42af8823b4bcbdc5f9fb93f447?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/3ad124c4608e03f03c8e36ead2334cd80560be42af8823b4bcbdc5f9fb93f447?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                    className="object-cover absolute inset-0 size-full"
                    alt="Protfolio"
                  /> */}
                  <div className="flex relative z-10 flex-col pb-3 mr-0 w-full max-md:max-w-full">
                    <div className="flex gap-5 justify-between self-center max-w-full">
                      <div className="flex flex-col self-start mt-2.5">
                        <div className="self-start text-xs text-neutral-400">
                          What My
                        </div>
                        <div className="mt-3.5 text-xl text-blue-500">
                          Patient Say,s
                        </div>
                      </div>
                      {/* <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/e46c05705424a05b952d664a8dffd7c649bd0bd23500c5ef58e33ae4cf38a8d2?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e46c05705424a05b952d664a8dffd7c649bd0bd23500c5ef58e33ae4cf38a8d2?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e46c05705424a05b952d664a8dffd7c649bd0bd23500c5ef58e33ae4cf38a8d2?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e46c05705424a05b952d664a8dffd7c649bd0bd23500c5ef58e33ae4cf38a8d2?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e46c05705424a05b952d664a8dffd7c649bd0bd23500c5ef58e33ae4cf38a8d2?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e46c05705424a05b952d664a8dffd7c649bd0bd23500c5ef58e33ae4cf38a8d2?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e46c05705424a05b952d664a8dffd7c649bd0bd23500c5ef58e33ae4cf38a8d2?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e46c05705424a05b952d664a8dffd7c649bd0bd23500c5ef58e33ae4cf38a8d2?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                        className="object-contain shrink-0 max-w-full aspect-[1.35] w-[101px]"
                        alt="Protfolio"
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="mt-3 px-5">
                  <div className="flex flex-col grow max-md:mt-10">
                    <div className="mr-7 text-sm text-center font-semibold text-zinc-400 max-md:mr-2.5">
                      They are many variations of passages of Lorem lpsum
                      available
                      <br />
                      the majority have suffered alteration in some form .
                    </div>
                    <div className="flex flex-col pl-1.5 mt-16 w-full max-md:mt-10">
                      <div className="flex flex-col px-14 pb-14 text-xs font-semibold text-center border-2 border-solid bg-slate-50 border-slate-100 text-zinc-400 max-md:px-5">
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b45542c53d9e3819916ba92322a3a74a3891c3d1de1170f9be9dcc5c3095381e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b45542c53d9e3819916ba92322a3a74a3891c3d1de1170f9be9dcc5c3095381e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b45542c53d9e3819916ba92322a3a74a3891c3d1de1170f9be9dcc5c3095381e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b45542c53d9e3819916ba92322a3a74a3891c3d1de1170f9be9dcc5c3095381e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b45542c53d9e3819916ba92322a3a74a3891c3d1de1170f9be9dcc5c3095381e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b45542c53d9e3819916ba92322a3a74a3891c3d1de1170f9be9dcc5c3095381e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b45542c53d9e3819916ba92322a3a74a3891c3d1de1170f9be9dcc5c3095381e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b45542c53d9e3819916ba92322a3a74a3891c3d1de1170f9be9dcc5c3095381e?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                          className="object-contain z-10 self-center -mt-8 aspect-square rounded-[31px] w-[65px]"
                          alt="Protfolio"
                        />
                        <div className="mt-8">
                          I attended Sarah Dr: Smith and Signs of Soreness
                          Seminar
                          <br />
                          at Equidays on Saturday and it was one of the best
                          <br />
                          and most informative.
                        </div>
                      </div>
                      <div className="flex gap-10 self-end mt-7 max-w-full w-[309px]">
                        <div className="flex flex-col flex-1 my-auto font-semibold">
                          <div className="self-start text-sm text-blue-500">
                            MD Sammad Hasan
                          </div>
                          <div className="self-end mt-1.5 text-xs text-zinc-300">
                            Gov. service holder
                          </div>
                        </div>
                        <div className="flex flex-col flex-1">
                          <img
                            loading="lazy"
                            srcSet="https://hips.hearstapps.com/hmg-prod/images/portrait-of-a-happy-young-doctor-in-his-clinic-royalty-free-image-1661432441.jpg?crop=0.66698xw:1xh;center,top&resize=1200:*"
                            className="object-contain aspect-square rounded-[67px] w-[132px]"
                            alt="Protfolio"
                          />
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a25d83b1ff3a0f554650f3ae48e3c02fdc5368529bb252a09723879ed51c89e7?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a25d83b1ff3a0f554650f3ae48e3c02fdc5368529bb252a09723879ed51c89e7?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a25d83b1ff3a0f554650f3ae48e3c02fdc5368529bb252a09723879ed51c89e7?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a25d83b1ff3a0f554650f3ae48e3c02fdc5368529bb252a09723879ed51c89e7?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a25d83b1ff3a0f554650f3ae48e3c02fdc5368529bb252a09723879ed51c89e7?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a25d83b1ff3a0f554650f3ae48e3c02fdc5368529bb252a09723879ed51c89e7?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a25d83b1ff3a0f554650f3ae48e3c02fdc5368529bb252a09723879ed51c89e7?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a25d83b1ff3a0f554650f3ae48e3c02fdc5368529bb252a09723879ed51c89e7?placeholderIfAbsent=true&apiKey=dca5bb78f600420093ee24125166c201"
                            className="object-contain self-end mt-8 mr-8 w-2 aspect-square max-md:mr-2.5"
                            alt="Protfolio"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocProtfolio;
