import React, { useState } from "react";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import { useNavigate, Navigate } from "react-router-dom";
import "firebase/storage";
import { IoMdArrowBack } from "react-icons/io";
import { useAuth } from "../AuthContext";
import { addManager } from "./AdminFirebase";

const AddManager = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [location, setLocation] = useState("");
  const navigate = useNavigate();
  const { isAdminLoggedIn } = useAuth();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const managerData = {
      name,
      email,
      location,
      createdAt: new Date().toISOString(),
    }

    try {
      const successMessage = await addManager(email, password, managerData);

      if(successMessage === true){
        alert("Growth Manager Added Successfully");
        navigate("/admin/manager");
      }else{
        alert("error adding document");
        return;
      }
    } catch (error) {
      console.log("Error adding document :", error);
    }
  };
  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container max-w-full text-black mx-auto my-5 px-10">
            <div className="w-full">
              <form
                onSubmit={handleSubmit}
                className="bg-white shadow rounded px-8 pt-6 pb-8 mb-4"
              >
                <div className="flex items-center justify-between mb-4">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                  >
                    <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
                  </button>
                  <h2 className="flex-grow text-2xl mb-5 font-bold text-center uppercase">
                    Add Growth Manager
                  </h2>
                </div>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div class="mb-3">
                    <label
                      htmlFor="name"
                      class="block mb-1 px-2 text-md font-bold text-gray-900"
                    >
                      Name :
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter Growth Manager name"
                    />
                  </div>
                  <div class="mb-3">
                    <label
                      htmlFor="location"
                      class="block mb-1 px-2 text-md font-bold text-gray-900"
                    >
                      Location :
                    </label>
                    <input
                      type="text"
                      name="location"
                      className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      placeholder="Enter Location"
                    />
                  </div>
                  <div class="mb-3">
                    <label
                      htmlFor="email"
                      class="block mb-1 px-2 text-md font-bold text-gray-900"
                    >
                      Email :
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your Email"
                    />
                  </div>
                  <div class="mb-3">
                    <label
                      htmlFor="password"
                      class="block mb-1 px-2 text-md font-bold text-gray-900"
                    >
                      Password :
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your Password"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="text-white bg-[#8697C4] hover:bg-[#8595C2] focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddManager;
