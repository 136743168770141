import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import HospitalNavbar from "./HospitalNavbar";
import HospitalSide from "./HospitalSide";
import "../style/Company.css";
import { auth } from "../firebase";
import { fetchHospitalData, updateHospitalData, updateHospitalPassword } from "./HospitalFirebase";

const HospitalSettings = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [hospital, setHospital] = useState(null);
  const [hospitalName, setHospitalName] = useState(false);
  const [hospitalDetails, setHospitalDetails] = useState(false);
  const [hospitalAbout, setHospitalAbout] = useState(false);
  const [hospitalPassword, setHospitalPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  const toggleHospitalName = () => {
    setHospitalName(!hospitalName);
  };

  const toggleHospitalDetails = () => {
    setHospitalDetails(!hospitalDetails);
  };

  const toggleHospitalAbout = () => {
    setHospitalAbout(!hospitalAbout);
  };

  const toggleHospitalPassword = () => {
    setHospitalPassword(!hospitalPassword);
  };

  useEffect(() => {
    const fetchHospital = async () => {
      try {
        const hospitalData = await fetchHospitalData(id);
        setHospital(hospitalData);
      } catch (error) {
        console.log("Error fetching hospital :", error);
      }
    };
    fetchHospital();
  }, [id]);

  const handleHospitalName = async (e) => {
    e.preventDefault();
    try {
      const newData = { ...hospital };

      await updateHospitalData(id, newData);
      alert("Data successfully updated!");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleHospitalDetails = async (e) => {
    e.preventDefault();
    try {
      const newData = { ...hospital };

      await updateHospitalData(id, newData);
      alert("Data successfully updated!");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleHospitalAbout = async (e) => {
    e.preventDefault();
    try {
      const newData = { ...hospital };

      await updateHospitalData(id, newData);
      alert("Data successfully updated!");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleHospitalPassword = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (newPassword !== confirmPassword) {
      setError("New passwords do not match");
      return;
    }

    const user = auth.currentUser;

    if (user) {
      const success = await updateHospitalPassword(user, oldPassword, newPassword);
      if (success) {
        setSuccess("Password reset successfully!");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        setError("Error updating password.");
      }
    } else {
      setError("No user is signed in.");
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const profile = () => {
    navigate(`/hospital/hospitalprofile/${id}`);
  };

  return (
    <div className="flex flex-col h-screen">
      <HospitalNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <HospitalSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-all duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          {hospital && (
            <div className="container px-4 mx-auto my-10">
              <div className="overflow-hidden px-10 md:px-5">
                <div className="border shadow rounded-lg">
                  <div id="hospitaldetails" className="md:h-auto">
                    <div className="flex items-center justify-between my-5">
                      <h1 className="text-xl font-bold uppercase px-6 md:px-2 ">
                        Profile Information
                      </h1>
                    </div>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2"
                      onClick={toggleHospitalName}
                    >
                      <p className="cursor-pointer">Name, Hospital-Name </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 p-0 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleHospitalDetails}
                    >
                      <p className="cursor-pointer">
                        Email, Location, Category{" "}
                      </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleHospitalAbout}
                    >
                      <p className="cursor-pointer">About Hospital </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                    <hr className="mb-3 border-gray-300"></hr>
                    <div
                      className="flex items-center justify-between mb-3 font-medium px-6 md:px-2 "
                      onClick={toggleHospitalPassword}
                    >
                      <p className="cursor-pointer">Change Password </p>
                      <button type="button">
                        <IoIosArrowRoundForward size={30} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* edit content */}

          {hospitalName && (
            <div className="overlay">
              <div className="overlay-content" id="editablecontent">
                <div className="rounded-md">
                  <p className="text-xl font-bold mb-4">Edit Hospital Name</p>
                  <form onSubmit={handleHospitalName}>
                    <div className="mb-4">
                      <label className="block text-gray-700">Hospital Name</label>
                      <input
                        type="text"
                        value={hospital.name || ""}
                        onChange={(e) =>
                          setHospital({ ...hospital, name: e.target.value })
                        }
                        className="w-full mt-1 p-2 border rounded"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <button
                        type="submit"
                        className="p-2 bg-[#0D47A1] text-white rounded"
                      >
                        Save Changes
                      </button>
                      <button
                        type="button"
                        onClick={toggleHospitalName}
                        className="ml-2 p-2 bg-gray-500 text-white rounded"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {hospitalDetails && (
            <div className="overlay">
              <div className="overlay-content" id="editablecontent">
                <div className="rounded-md">
                  <p className="text-xl font-bold mb-4">Edit Hospital Details</p>
                  <form onSubmit={handleHospitalDetails}>
                    <div className="mb-4">
                      <label className="block text-gray-700">Email</label>
                      <input
                        type="email"
                        value={hospital.email || ""}
                        onChange={(e) =>
                          setHospital({ ...hospital, email: e.target.value })
                        }
                        className="w-full mt-1 p-2 border rounded cursor-not-allowed"
                        disabled
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">Location</label>
                      <input
                        type="text"
                        value={hospital.location || ""}
                        onChange={(e) =>
                          setHospital({ ...hospital, location: e.target.value })
                        }
                        className="w-full mt-1 p-2 border rounded"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">Category</label>
                      <input
                        type="text"
                        value={hospital.category || ""}
                        onChange={(e) =>
                          setHospital({ ...hospital, category: e.target.value })
                        }
                        className="w-full mt-1 p-2 border rounded"
                      />
                    </div>
                    <div className="mb-4">
                      <button
                        type="submit"
                        className="p-2 bg-[#0D47A1] text-white rounded"
                      >
                        Save Changes
                      </button>
                      <button
                        type="button"
                        onClick={toggleHospitalDetails}
                        className="ml-2 p-2 bg-gray-500 text-white rounded"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {hospitalAbout && (
            <div className="overlay">
              <div className="overlay-content" id="editablecontent">
                <div className="rounded-md">
                  <p className="text-xl font-bold mb-4">Edit Hospital About</p>
                  <form onSubmit={handleHospitalAbout}>
                    <div className="mb-4">
                      <label className="block text-gray-700">About</label>
                      <textarea
                        rows="4"
                        value={hospital.about || ""}
                        onChange={(e) =>
                          setHospital({ ...hospital, about: e.target.value })
                        }
                        className="w-full mt-1 p-2 border rounded"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <button
                        type="submit"
                        className="p-2 bg-[#0D47A1] text-white rounded"
                      >
                        Save Changes
                      </button>
                      <button
                        type="button"
                        onClick={toggleHospitalAbout}
                        className="ml-2 p-2 bg-gray-500 text-white rounded"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {hospitalPassword && (
            <div className="overlay">
              <div className="overlay-content" id="editablecontent">
                <div className="rounded-md">
                  <p className="text-xl font-bold mb-4">Change Password</p>
                  <form onSubmit={handleHospitalPassword}>
                    <div className="mb-4">
                      <label className="block text-gray-700">Old Password</label>
                      <input
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        className="w-full mt-1 p-2 border rounded"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">New Password</label>
                      <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="w-full mt-1 p-2 border rounded"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">Confirm New Password</label>
                      <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="w-full mt-1 p-2 border rounded"
                        required
                      />
                    </div>
                    {error && (
                      <div className="mb-4 text-red-500">{error}</div>
                    )}
                    {success && (
                      <div className="mb-4 text-green-500">{success}</div>
                    )}
                    <div className="mb-4">
                      <button
                        type="submit"
                        className="p-2 bg-[#0D47A1] text-white rounded"
                      >
                        Change Password
                      </button>
                      <button
                        type="button"
                        onClick={toggleHospitalPassword}
                        className="ml-2 p-2 bg-gray-500 text-white rounded"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HospitalSettings;
