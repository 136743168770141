import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Navigate, Link } from "react-router-dom";
import AdminSide from "./AdminSide";
import AdminNavbar from "./AdminNavbar";
import Calendar from "react-calendar";
import Swal from "sweetalert2";
import { IoMdArrowBack } from "react-icons/io";
import { FaRegEdit, FaCheck, FaClock, FaCalendarAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { SiGooglemeet } from "react-icons/si";
import { format } from "date-fns";
import { useAuth } from "../AuthContext";
import {
  fetchMeetingsByDoctorId,
  fetchDoctorDataByID,
  modifyMeeting,
  acceptMeeting,
  deleteMeeting,
} from "./AdminFirebase";

const ViewDoctorsMeetings = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { id } = useParams();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedMeetingId, setSelectedMeetingId] = useState(null);
  const [doctorName, setDoctorName] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [tab, setTab] = useState("upcoming");
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [missedMeetings, setMissedMeetings] = useState([]);
  const [completedMeetings, setCompletedMeetings] = useState([]);
  const [meetingType, setMeetingType] = useState("virtual");
  const navigate = useNavigate();
  const { isAdminLoggedIn } = useAuth();
  const [calendarTab, setCalendarTab] = useState("date");

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const times = [
    "09:00 AM", "09:30 AM", "10:00 AM", "10:30 AM", "11:00 AM", "11:30 AM",
    "12:00 PM", "12:30 PM", "01:00 PM", "01:30 PM", "02:00 PM", "02:30 PM",
    "03:00 PM", "03:30 PM", "04:00 PM", "04:30 PM", "05:00 PM", "05:30 PM",
    "06:00 PM", "06:30 PM", "07:00 PM", "07:30 PM", "08:00 PM", "08:30 PM", "09:00 PM",
  ];

  const toggleCalendar = (meeting = null) => {
    if (meeting) {
      setSelectedMeetingId(meeting.id);
      setSelectedDate(new Date(meeting.date));
      setSelectedTime(meeting.time);
      setMeetingType(meeting.meetingType);
      setCalendarTab("date");
    } else {
      setSelectedMeetingId(null);
      setSelectedDate(new Date());
      setSelectedTime(null);
      setMeetingType("virtual");
      setCalendarTab("date");
    }
    setShowCalendar(!showCalendar);
  };

  useEffect(() => {
    const fetchData = async () => {
      const doctorData = await fetchDoctorDataByID(id);
      setDoctorName(doctorData.name);

      const {
        AllMeetings,
        upcomingMeetings,
        missedMeetings,
        completedMeetings,
      } = await fetchMeetingsByDoctorId(id);
      setMeetings(AllMeetings);
      setUpcomingMeetings(upcomingMeetings);
      setMissedMeetings(missedMeetings);
      setCompletedMeetings(completedMeetings);
    };
    fetchData();
  }, [id]);

  const handleModify = async () => {
    if (!selectedMeetingId) return;

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, save it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await modifyMeeting(
            selectedMeetingId,
            selectedDate,
            selectedTime,
            meetingType
          );

          const updateMeeting = upcomingMeetings.map((meeting) =>
            meeting.id === selectedMeetingId
              ? {
                ...meeting,
                date: selectedDate.toISOString().split("T")[0],
                time: selectedTime,
                status: "Rescheduled",
                meetingType: meetingType,
              }
              : meeting
          );

          //   setMeetings(updateMeeting);
          setUpcomingMeetings(updateMeeting);

          toggleCalendar();

          setTimeout(() => {
            window.location.reload();
          }, 900);
        } catch (error) {
          console.error("Error updating schedule meeting:", error);
          Swal.fire({
            title: "Error",
            text: "An error occurred while updating the schedule. Please try again later.",
            icon: "error",
          });
        }
      }
    });
  };

  const handleAccept = async (meetingId) => {
    try {
      await acceptMeeting(meetingId);

      Swal.fire({
        title: "Accepted",
        text: "Meeting has been accepted.",
        icon: "success",
        timer: 2000,
      });

      const acceptedMeeting = upcomingMeetings.map((meeting) =>
        meeting.id === meetingId ? { ...meeting, status: "Accepted" } : meeting
      );

      //   setMeetings(acceptedMeeting);
      setUpcomingMeetings(acceptedMeeting);
    } catch (error) {
      console.error("Error accepting meeting:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while accepting the meeting. Please try again later.",
        icon: "error",
      });
    }
  };

  const handleDeleteMeeting = async (meetingId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this meeting?"
    );

    if (confirmed) {
      try {
        await deleteMeeting(meetingId);

        setMeetings((prevMeetings) =>
          prevMeetings.filter(
            (scheduleMeetings) => scheduleMeetings.id !== meetingId
          )
        );
        setTimeout(() => {
          window.location.reload();
        }, 900);
      } catch (error) {
        console.error("Error deleting Meeting:", error);
      }
    }
  };

  if (!isAdminLoggedIn) {
    return <Navigate to="/admin" />;
  }

  const onChange = (date) => {
    setSelectedDate(date);
    if (calendarTab === "date") {
      setCalendarTab("time");
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <AdminNavbar />
      <div className="flex flex-1 mt-[4.2rem]">
        <AdminSide open={sidebarOpen} toggleSidebar={toggleSidebar} />
        <div
          className={`overflow-y-auto flex-1 transition-margin duration-300 ${sidebarOpen ? "ml-60" : "ml-20"
            }`}
        >
          <div className="container mx-auto px-5 md:px-3 h-full">
            <div className="overflow-hidden px-10 md:px-5" id="companyedits">
              <div className="flex items-center justify-between mb-4">
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className="w-8 h-8 bg-white shadow border rounded-full flex items-center justify-center"
                >
                  <IoMdArrowBack className="h-6 w-6 font-bold text-[#8697C4]" />
                </button>
                <h2 className="flex-grow text-xl mt-2 font-bold text-center uppercase">
                  {doctorName} Meetings
                </h2>
              </div>

              <div className="overflow-auto shadow-md sm:rounded-lg mt-3 mb-5 border table-container">
                <div className="flex justify-center my-3">
                  <button
                    onClick={() => setTab("upcoming")}
                    className={`px-4 py-2 font-semibold ${tab === "upcoming"
                        ? "bg-gray-500 text-white"
                        : "bg-gray-100"
                      } border-r`}
                  >
                    Upcoming
                  </button>
                  <button
                    onClick={() => setTab("missed")}
                    className={`px-4 py-2 font-semibold ${tab === "missed"
                        ? "bg-gray-500 text-white"
                        : "bg-gray-100"
                      } border-r`}
                  >
                    Missed
                  </button>
                  <button
                    onClick={() => setTab("completed")}
                    className={`px-4 py-2 font-semibold ${tab === "completed"
                        ? "bg-gray-500 text-white"
                        : "bg-gray-100"
                      }`}
                  >
                    Completed
                  </button>
                </div>

                <table className="divide-y divide-gray-300 w-full text-left rtl:text-right">
                  <thead className="text-sm text-gray-700 uppercase">
                    <tr>
                      <th
                        scope="col"
                        className="px-2 py-3 tracking-wider bg-gray-50"
                      >
                        S.N.
                      </th>
                      <th scope="col" className="px-6 py-3 tracking-wider">
                        Doctor Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 tracking-wider bg-gray-50"
                      >
                        Company Name
                      </th>
                      <th scope="col" className="px-6 py-3 tracking-wider">
                        Assigned
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 tracking-wider bg-gray-50"
                      >
                        Date
                      </th>
                      <th scope="col" className="px-6 py-3 tracking-wider">
                        Time
                      </th>
                      {tab === "upcoming" && (
                        <th
                          scope="col"
                          className="px-6 py-3 bg-gray-50 capitalize tracking-wider"
                        >
                          Status
                        </th>
                      )}
                      <th
                        scope="col"
                        className={`px-6 py-3 tracking-wider ${tab !== "upcoming" ? "bg-gray-50" : ""
                          }`}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  {/* upcoming meetings */}
                  {tab === "upcoming" && (
                    <tbody>
                      {upcomingMeetings.length === 0 ? (
                        <tr className="bg-white border-b">
                          <td colSpan="7" className="text-center py-4">
                            <p className="text-lg">
                              No Upcoming Meetings Found.
                            </p>
                          </td>
                        </tr>
                      ) : (
                        upcomingMeetings.map((meeting, index) => (
                          <tr key={meeting.id} className="bg-white border-b">
                            <td
                              scope="row"
                              className="px-2 py-3 bg-gray-50 font-medium"
                            >
                              {index + 1}.
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {meeting.doctorName}
                            </td>
                            <td className="px-2 py-3 bg-gray-50">
                              {meeting.companyName}
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {meeting.assignedName}
                            </td>
                            <td className="px-2 py-3 bg-gray-50">
                              {format(new Date(meeting.date), "dd/MM/yyyy")}
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {meeting.time}
                            </td>
                            <td className="px-2 py-3 capitalize bg-gray-50">
                              <p>{meeting.status}</p>
                              <p>({meeting.meetingType})</p>
                            </td>
                            <td className="px-2 py-3">
                              <button
                                onClick={() => toggleCalendar(meeting)}
                                type="button"
                                className="text-white bg-[#8697C4] rounded-lg px-2 py-2 me-1 mb-2 text-center"
                              >
                                <FaRegEdit />
                              </button>
                              {meeting.status !== 'Accepted' ? (
                                <button
                                  type="button"
                                  onClick={() => handleAccept(meeting.id)}
                                  className="text-white bg-[#8697C4] rounded-lg px-2 py-2 me-1 mb-2 text-center"
                                >
                                  <FaCheck />
                                </button>

                              ) : (
                                <button
                                  className="text-white bg-gray-400 rounded-lg px-2 py-2 me-1 mb-2 text-center cursor-not-allowed"
                                  disabled
                                >
                                  <FaCheck />
                                </button>
                              )}
                              {meeting.meetingType !== "offline" ? (
                                <button className="text-white bg-[#8697C4] rounded-lg px-2 py-2 me-1 mb-2 text-center">
                                  <Link
                                    to={meeting.meetingLink}
                                    target="_blank"
                                  >
                                    <SiGooglemeet />
                                  </Link>
                                </button>
                              ) : (
                                <button
                                  className="text-white bg-gray-400 rounded-lg px-2 py-2 me-1 mb-2 text-center cursor-not-allowed"
                                  disabled
                                >
                                  <Link
                                    to={meeting.meetingLink}
                                    target="_blank"
                                  >
                                    <SiGooglemeet />
                                  </Link>
                                </button>
                              )}
                              <button
                                onClick={() => handleDeleteMeeting(meeting.id)}
                                type="button"
                                className="text-white bg-[#8697C4] rounded-lg px-2 py-2 me-1 mb-2 text-center"
                              >
                                <MdDelete />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                      {showCalendar && (
                        <div className="overlay">
                          <div className="overlay-content">
                            <div>
                              <div className="tabs flex border-b-2">
                                <button
                                  className={`tab w-1/2 text-center px-4 py-2 text-lg font-bold focus:outline-none ${calendarTab === "date"
                                      ? "active border-b-2 border-indigo-500 text-indigo-500"
                                      : "text-gray-600"
                                    }`}
                                  onClick={() => setCalendarTab("date")}
                                >
                                  <FaCalendarAlt className="m-auto" />
                                </button>
                                <button
                                  className={`tab w-1/2 text-center px-4 py-2 text-lg font-bold focus:outline-none ${calendarTab === "time"
                                      ? "active border-b-2 border-indigo-500 text-indigo-500"
                                      : "text-gray-600"
                                    }`}
                                  onClick={() => setCalendarTab("time")}
                                >
                                  <FaClock className="m-auto" />
                                </button>
                              </div>

                              {calendarTab === "date" && (
                                <div className="date-picker">
                                  <Calendar
                                    onChange={onChange}
                                    value={selectedDate}
                                    minDate={new Date()}
                                    className="custom-calendar"
                                    tileClassName={({ date, view }) => {
                                      if (view === "month") {
                                        const today = new Date();
                                        today.setHours(0, 0, 0, 0); // Normalize today's date to avoid time issues
                                        if (date < today) {
                                          return "react-calendar__tile--passed"; // Apply passed date class
                                        }
                                      }
                                      return null;
                                    }}
                                  />
                                </div>
                              )}
                              {calendarTab === "time" && (
                                <div className="time-picker mt-2 p-2 shadow-lg">
                                  <div className="grid grid-cols-5 gap-2">
                                    {times.map((time) => (
                                      <button
                                        key={time}
                                        onClick={() => setSelectedTime(time)}
                                        className={`py-3 px-1 rounded-md ${selectedTime === time
                                            ? "bg-[#11c911] text-white"
                                            : "bg-gray-200"
                                          }`}
                                      >
                                        {time}
                                      </button>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="flex justify-between mt-4">
                              <div className="flex">
                                <label className="inline-flex items-center mr-4">
                                  <input
                                    type="checkbox"
                                    name="meetingType"
                                    value="virtual"
                                    checked={meetingType === "virtual"}
                                    onChange={(e) =>
                                      setMeetingType(e.target.value)
                                    }
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                  />
                                  <span className="ml-2 font-bold text-gray-700">
                                    Virtual
                                  </span>
                                </label>
                                <label className="inline-flex items-center">
                                  <input
                                    type="checkbox"
                                    name="meetingType"
                                    value="offline"
                                    checked={meetingType === "offline"}
                                    onChange={(e) =>
                                      setMeetingType(e.target.value)
                                    }
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                  />
                                  <span className="ml-2 font-bold text-gray-700">
                                    Offline
                                  </span>
                                </label>
                              </div>
                              <div className="flex justify-end ">
                                <button
                                  onClick={() => toggleCalendar(null)}
                                  className="px-4 py-2 mr-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:bg-gray-400 transition-all duration-300 ease-in-out"
                                >
                                  Cancel
                                </button>
                                <button
                                  onClick={handleModify}
                                  className="px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 transition-all duration-300 ease-in-out"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </tbody>
                  )}

                  {/* missed meetings */}
                  {tab === "missed" && (
                    <tbody>
                      {missedMeetings.length === 0 ? (
                        <tr className="bg-white border-b">
                          <td colSpan="7" className="text-center py-4">
                            <p className="text-lg">No Missed Meetings Found.</p>
                          </td>
                        </tr>
                      ) : (
                        missedMeetings.map((meeting, index) => (
                          <tr key={meeting.id} className="bg-white border-b">
                            <td
                              scope="row"
                              className="px-2 py-3 bg-gray-50 font-medium"
                            >
                              {index + 1}.
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {meeting.doctorName}
                            </td>
                            <td className="px-2 py-3 bg-gray-50">
                              {meeting.companyName}
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {meeting.assignedName}
                            </td>
                            <td className="px-2 py-3 bg-gray-50">
                              {format(new Date(meeting.date), "dd/MM/yyyy")}
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {meeting.time}
                            </td>
                            <td className="px-2 py-3 bg-gray-50">
                              <button
                                type="button"
                                className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2"
                              >
                                <FaRegEdit />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteMeeting(meeting.id)}
                                className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2"
                              >
                                <MdDelete />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  )}

                  {/* completed meetings */}
                  {tab === "completed" && (
                    <tbody>
                      {completedMeetings.length === 0 ? (
                        <tr className="bg-white border-b">
                          <td colSpan="7" className="text-center py-4">
                            <p className="text-lg">
                              No Complted Meetings Found.
                            </p>
                          </td>
                        </tr>
                      ) : (
                        completedMeetings.map((meeting, index) => (
                          <tr key={meeting.id} className="bg-white border-b">
                            <td
                              scope="row"
                              className="px-2 py-3 bg-gray-50 font-medium"
                            >
                              {index + 1}.
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {meeting.doctorName}
                            </td>
                            <td className="px-2 py-3 bg-gray-50">
                              {meeting.companyName}
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {meeting.assignedName}
                            </td>
                            <td className="px-2 py-3 bg-gray-50">
                              {format(new Date(meeting.date), "dd/MM/yyyy")}
                            </td>
                            <td className="px-2 py-3 font-medium">
                              {meeting.time}
                            </td>
                            <td className="px-2 py-3 bg-gray-50">
                              <button
                                type="button"
                                className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2"
                              >
                                <FaRegEdit />
                              </button>
                              <button
                                type="button"
                                onClick={() => handleDeleteMeeting(meeting.id)}
                                className="text-white bg-[#8697C4] focus:ring-4 focus:outline-none focus:ring-[#8697C4] font-medium rounded-lg text-sm px-3 py-2 text-center me-2"
                              >
                                <MdDelete />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDoctorsMeetings;
