import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { NavLink } from "react-router-dom";
import MedivirtLogo from '../../assets/img/Medivirt.png';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const HospitalNavbar = () => {

    const [hospital, setHospital] = useState(null);
    const { id } = useParams();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const { from } = location.state || {};

    useEffect(() => {
        const fetchHospital = async () => {
            try {
                const db = getFirestore();
                const docRef = doc(db, "hospitals", id);
                const hospitalSnapshot = await getDoc(docRef);
                if (hospitalSnapshot.exists()) {
                    setHospital({ id: hospitalSnapshot.id, ...hospitalSnapshot.data() });
                } else {
                    console.log("No such hospital");
                }
            } catch (error) {
                console.log("Error fetching hospital :", error);
            }
        };
        fetchHospital();

        if (from) {
            sessionStorage.setItem("returnFrom", from);
        }
    }, [id, from]);

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                console.log('User logged out successfully.');
                navigate('/');
            })
            .catch(error => {
                console.error('Error logging out:', error);
            });
    };

    const handleReturnToDashboard = () => {
        const storedFrom = sessionStorage.getItem("returnFrom");
      
        if (storedFrom === "admin") {
          toast.success("Returning to Admin Dashboard!", {
            autoClose: 2000, // Duration for Toast
          });
      
          setTimeout(() => {
            navigate(`/admin/dash`);
          }, 2000); 
        }
      
        sessionStorage.removeItem("returnFrom");
      };

    return (
        <nav className="bg-[#1E2955] border-b w-full fixed top-0 left-0 z-40">
            <div className="flex items-center justify-between py-3 px-6">
                <NavLink to="/" className="flex-shrink-0">
                    <img id="logo" className="h-8" alt="Medivirt" src={MedivirtLogo} />
                </NavLink>
                {hospital && (
                    <div className="flex items-center space-x-4">
                        {/* <button id='hospitalimage' className="w-10 h-10 outline-none rounded-full">
                            {hospital.image ? (
                                <img
                                    src={hospital.image}
                                    alt={`Profile of ${hospital.name}`}
                                    className="w-full h-full rounded-full"
                                />
                            ) : (
                                <div className="w-10 h-10 outline-none rounded-full">
                                    <img src={Image} className='w-full h-full rounded-full' alt="User Profile" />
                                </div>
                            )}
                        </button> */}
                        <div className="text-white">
                            <p className="font-bold">{hospital.hospitalName}</p>
                            <p className="text-sm">{hospital.name}</p>
                        </div>
                        <button className="w-7 h-7 text-white border rounded-full" onClick={() => setDropdownOpen(!dropdownOpen)}>
                            <MdOutlineKeyboardArrowDown size={24} />
                        </button>
                        {dropdownOpen && (
                            <div className="absolute top-full right-0 mt-2 w-40 bg-white rounded shadow z-10">
                                <button className="block w-full text-left p-2 hover:bg-gray-200" onClick={handleLogout}>Logout</button>
                                {(from || sessionStorage.getItem("returnFrom")) && (
                                    <button onClick={handleReturnToDashboard} className="block w-full text-left py-2 hover:bg-gray-200">
                                        Return to Dashboard
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                )}

            </div>
            <ToastContainer />
        </nav>
    );
};

export default HospitalNavbar;
