import { getFirestore, collection, query, where, getDocs, doc, getDoc, setDoc, updateDoc, deleteDoc,} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL,} from "firebase/storage";
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword, createUserWithEmailAndPassword, sendEmailVerification,} from "firebase/auth";
import { auth, db, storage } from "../firebase";
import { format } from "date-fns";
import { v4 as uuid } from "uuid";

//fetch company name
export const fetchCompanyName = async (id) => {
  try {
    const userDoc = await getDoc(doc(db, "users", id));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const fetchedCompanyId = userData.companyId;

      const companyDoc = await getDoc(doc(db, "companies", fetchedCompanyId));
      if (companyDoc.exists()) {
        const companyData = companyDoc.data();
        return {
          companyId: fetchedCompanyId,
          companyName: companyData.companyName,
          companyData,
        };
      } else {
        console.log("Company not found");
        return null;
      }
    } else {
      console.log("Sales head not found");
      return null;
    }
  } catch (error) {
    console.log("Error fetching company:", error);
    return null;
  }
};

//add User
export const addUser = async (email, password, userData) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    await sendEmailVerification(user);
    const customId = `${userData.firstName}_${userData.lastName}_${userData.companyId}`;
    const customDocRef = doc(db, "users", customId);
    await setDoc(customDocRef, {
      ...userData,
    });
    return true;
  } catch (error) {
    console.error("Error adding document :", error);
    throw error;
  }
};

//fetch User
export const fetchUser = async (companyId, role) => {
  try {
    const q = query(
      collection(db, "users"),
      where("companyId", "==", companyId),
      where("role", "==", role)
    );
    const querySnapshot = await getDocs(q);
    const usersList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return usersList;
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
};

//add Product
export const addProduct = async (productData) => {
  try {
    const customId = `${productData.productName}_${productData.companyId}`;
    const customDocRef = doc(db, "products", customId);
    await setDoc(customDocRef, {
      ...productData,
    });
    return true;
  } catch (error) {
    console.error("Error adding document :", error);
    throw error;
  }
};

//upload Product Image
export const uploadProductImage = async (file) => {
  try {
    const storageRef = ref(storage, `images/products/${file.name}`);
    const uploadTaskSnapshot = await uploadBytes(storageRef, file);
    const url = await getDownloadURL(uploadTaskSnapshot.ref);
    return url;
  } catch (error) {
    console.error("Error uploading image:", error);
    return null;
  }
};

//fetch Product according to companyId
export const fetchProduct = async (companyId) => {
  try {
    const q = query(
      collection(db, "products"),
      where("companyId", "==", companyId)
    );
    const querySnapshot = await getDocs(q);
    const productsList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return productsList;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

//My Doctors
//Fetch My Doctors Data
export const fetchMyDoctorsData = async (id) => {
  try {
    // Fetch selectedDoctor data
    const selectedDoctorsCollection = collection(db, "selectedDoctors");
    const q = query(selectedDoctorsCollection, where("selectedId", "==", id));
    const selectedSnapshot = await getDocs(q);
    const selectedDoctorIds = selectedSnapshot.docs.map(
      (doc) => doc.data().doctorId
    );

    // Fetch meetings data for the current user
    const meetingsQuery = query(
      collection(db, "scheduleMeeting"),
      where("assigned", "==", id)
    );
    const meetingsSnapshot = await getDocs(meetingsQuery);
    const meetingsDoctorIds = meetingsSnapshot.docs.map(
      (doc) => doc.data().doctorID
    );

    // Fetch users with the same companyId and role of medical representative
    const companyInfo = await fetchCompanyName(id);

    const usersQuery = query(
      collection(db, "users"),
      where("companyId", "==", companyInfo.companyId),
      where("role", "==", "Medical Representative")
    );
    const usersSnapshot = await getDocs(usersQuery);
    const userIds = usersSnapshot.docs.map((doc) => doc.id);

    const userMeetingsQuery = query(
      collection(db, "scheduleMeeting"),
      where("assigned", "in", userIds)
    );
    const userMeetingsSnapshot = await getDocs(userMeetingsQuery);
    const userMeetingsDoctorIds = userMeetingsSnapshot.docs.map(
      (doc) => doc.data().doctorID
    );

    // Fetch message data
    const messagesQuery = query(
      collection(db, "messages"),
      where("companyID", "==", companyInfo.companyId)
    );
    const messagesSnapshot = await getDocs(messagesQuery);
    const messagesDoctorIds = messagesSnapshot.docs.map(
      (doc) => doc.data().doctorID
    );

    // Combine doctor IDs from selectedDoctors, meetings, userMeetings, and messages, ensuring uniqueness
    const allRelevantDoctorIds = Array.from(
      new Set([
        ...selectedDoctorIds,
        ...meetingsDoctorIds,
        ...userMeetingsDoctorIds,
        ...messagesDoctorIds,
      ])
    );

    // Fetch doctor details
    const doctorsData = await Promise.all(
      allRelevantDoctorIds.map(async (doctorId) => {
        const doctorRef = doc(db, "doctors", doctorId);
        const doctorSnapshot = await getDoc(doctorRef);
        if (doctorSnapshot.exists()) {
          const data = doctorSnapshot.data();

          let hospitalName = "N/A";
          if (data.hospitalId) {
            const hospitalRef = doc(db, "hospitals", data.hospitalId);
            const hospitalSnapshot = await getDoc(hospitalRef);
            hospitalName = hospitalSnapshot.exists()
              ? hospitalSnapshot.data().hospitalName
              : "N/A";
          }
          // Count meetings and messages for each doctor
          const meetingCount =
            meetingsDoctorIds.filter((id) => id === doctorId).length +
            userMeetingsDoctorIds.filter((id) => id === doctorId).length;
          const messageCount = messagesDoctorIds.filter(
            (id) => id === doctorId
          ).length;
          return {
            id: doctorSnapshot.id,
            ...data,
            name: data.name || "",
            hospitalName,
            specialist: data.specialist || "",
            location: data.location || "",
            meetingCount,
            messageCount,
          };
        }
        return null;
      })
    );

    // Filter out null values if any doctor data is missing
    return doctorsData.filter((doctor) => doctor !== null);
  } catch (error) {
    throw new Error("Error fetching doctors: " + error.message);
  }
};

//Delete My Doctor
export const deleteMyDoctorsData = async (doctorId, id) => {
  try {
    const companyInfo = await fetchCompanyName(id);
    const selectedDoctorsRef = collection(db, "selectedDoctors");
    const doctorQuery = query(
      selectedDoctorsRef,
      where("doctorId", "==", doctorId),
      where("companyId", "==", companyInfo.companyId)
    );
    const querySnapshot = await getDocs(doctorQuery);

    if (querySnapshot.empty) {
      throw new Error("Doctor not found in selectedDoctors collection");
    }

    const deletePromises = querySnapshot.docs.map((doc) => deleteDoc(doc.ref));
    await Promise.all(deletePromises);
    return true;
  } catch (error) {
    throw new Error("Error deleting doctor: " + error.message);
  }
};

//check if meeting is exists with another company
export const checkConflictMeetings = async(doctorId, companyId) => {
  if (!doctorId || !companyId) {
    console.error('Invalid parameters: doctorId or companyId is undefined');
    return {};
  }
  const meetingsRef = collection(db, "scheduleMeeting");
  const q = query(meetingsRef, where("doctorID", "==", doctorId));
  const querySnapshot = await getDocs(q);
  const conflictDates = {};

  querySnapshot.forEach((doc) => {
    const meetingData = doc.data();
    const meetingDate = meetingData.date; 
    const meetingTime = meetingData.time; 
    const meetingCompanyId = meetingData.companyID;

    if(meetingCompanyId !== companyId){
      if (!conflictDates[meetingDate]) {
        conflictDates[meetingDate] = [];
      }
      conflictDates[meetingDate].push(meetingTime);
    }

  });
  return conflictDates;
}

//Schedule a meeting for my doctors
export const bookScheduleMyMeeting = async (
  selectedDoctor,
  selectedTime,
  selectedDate,
  id,
  meetingType,
  assigned
) => {
  try {
    const companyInfo = await fetchCompanyName(id);
    const adjustedDate = new Date(selectedDate);
    const ISTOffset = 330;
    adjustedDate.setMinutes(adjustedDate.getMinutes() + ISTOffset);
    const formattedDate = adjustedDate.toISOString().split("T")[0];
    const selectedDateTime = new Date(`${formattedDate} ${selectedTime}`);

    if (selectedDateTime < new Date()) {
      throw new Error(
        "You cannot schedule a meeting for a past date and time!"
      );
    }

    // Generate a unique meeting ID
    let meetingId = uuid().slice(0, 4);
    const meetingsRef = collection(db, "scheduleMeeting");
    let idExists = true;

    while (idExists) {
      const q = query(meetingsRef, where("meetingId", "==", meetingId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        idExists = false;
      } else {
        meetingId = uuid().slice(0, 4); // Generate a new ID if the current one is taken
      }
    }

    const meetingLink = `https://meet.jit.si/${selectedDoctor.name.replace(
      /\s+/g,
      ""
    )}-${id}-${Date.now()}`;
    const customId = `${selectedDoctor.id}_${assigned}`;
    const customDocRef = doc(db, "scheduleMeeting", customId);

    // Check if a meeting already exists
    const existingMeetingDoc = await getDoc(customDocRef);

    const scheduleData = {
      companyID: companyInfo.companyId || id,
      doctorID: selectedDoctor.id,
      date: formattedDate,
      time: selectedTime,
      meetingLink: meetingLink,
      meetingType: meetingType,
      status: "scheduled",
      assigned: assigned,
      meetingId: meetingId,
    };

    if (existingMeetingDoc.exists()) {
      const existingMeetingData = existingMeetingDoc.data();

      if (existingMeetingData.status === "completed") {
        // Create a new document for the completed meeting with a new ID
        const newCompletedDocRef = doc(db, "scheduleMeeting", `${customId}-${meetingId}`);
        await setDoc(newCompletedDocRef, existingMeetingData);

        await setDoc(customDocRef, scheduleData);
      } else {
        await setDoc(customDocRef, {
          ...existingMeetingData,
          date: formattedDate,
          time: selectedTime,
          meetingLink: meetingLink,
          meetingType: meetingType,
          status: "scheduled",
        });
      }
    } else {
      await setDoc(customDocRef, scheduleData);
    }

    return true;
  } catch (error) {
    throw new Error("Error scheduling meeting: " + error.message);
  }
};

//messages for my doctors
export const messagesForMyDoctors = async (id, doctorId) => {
  const companyInfo = await fetchCompanyName(id);
  const messagesQuery = query(
    collection(db, "messages"),
    where("companyID", "==", companyInfo.companyId),
    where("doctorID", "==", doctorId)
  );
  const messagesSnapshot = await getDocs(messagesQuery);
  return messagesSnapshot;
};

//function to fetch hospital
export const fetchHospitals = async () => {
  const hospitalsCollection = collection(db, "hospitals");
  const hospitalsSnapshot = await getDocs(hospitalsCollection);
  const hospitalsData = hospitalsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return hospitalsData
}

//function to fetch Doctors if not have hospital
export const fetchDoctorsWithoutHospitalId = async () => {
  try {
    const q = query(collection(db, "doctors"));
    const querySnapshot = await getDocs(q);
    const doctors = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(doctor => doctor.hospitalId === "N/A" || doctor.hospitalId === null || !("hospitalId" in doctor));
    
    return doctors;
  } catch (error) {
      throw new Error("Error fetching doctors: " + error.message);
  }
};

//function to fetch Doctors by hospitalId
export const fetchDoctorsByHospitalId = async (hospitalId) => {
  try {
    const q = query(collection(db, "doctors"), where("hospitalId", "==", hospitalId));
    const querySnapshot = await getDocs(q);
    const doctors = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return doctors;
  } catch (error) {
    throw new Error("Error fetching doctors: " + error.message);
  }
}

//All Doctors
//fetch Doctors
export const fetchDoctors = async () => {
  // Fetch doctors
  const doctorsCollection = collection(db, "doctors");
  const doctorsSnapshot = await getDocs(doctorsCollection);
  const doctorsData = doctorsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // Fetch hospitals
  const hospitalsCollection = collection(db, "hospitals");
  const hospitalsSnapshot = await getDocs(hospitalsCollection);
  const hospitalsMap = hospitalsSnapshot.docs.reduce((acc, doc) => {
    const data = doc.data();
    acc[doc.id] = data.hospitalName;
    return acc;
  }, {});

  // Add hospital names to doctors data
  return doctorsData.map((doctor) => ({
    ...doctor,
    hospitalName: hospitalsMap[doctor.hospitalId] || "N/A",
  }));
};

//fetch selected Doctors
export const fetchSelectedDoctors = async (id) => {
  try {
    const selectedDoctorsCollection = collection(db, "selectedDoctors");
    const q = query(selectedDoctorsCollection, where("selectedId", "==", id));
    const selectedSnapshot = await getDocs(q);
    return selectedSnapshot.docs.map((doc) => doc.data().doctorId);
  } catch (error) {
    throw new Error("Error fetching selected doctors: " + error.message);
  }
};

//selection of doctors
export const toggleDoctorSelection = async (
  doctorId,
  id,
  companyId,
  selectedDoctors
) => {
  try {
    const selectedDoctorsCollection = collection(db, "selectedDoctors");
    const docId = `${doctorId}_${id}`;
    const docRef = doc(selectedDoctorsCollection, docId);

    if (selectedDoctors.includes(doctorId)) {
      await deleteDoc(docRef);
      return { action: "removed", doctorId };
    } else {
      await setDoc(docRef, { doctorId, companyId, selectedId: id });
      return { action: "added", doctorId };
    }
  } catch (error) {
    throw new Error("Error updating selected doctors: " + error.message);
  }
};

//fetch Doctors Profile
export const fetchDoctorProfile = async (doctorId) => {
  try {
    const doctorRef = doc(db, "doctors", doctorId);
    const doctorDoc = await getDoc(doctorRef);
    if (doctorDoc.exists()) {
      const doctorData = doctorDoc.data();

      let hospitalName = "N/A";
      if (doctorData.hospitalId && doctorData.hospitalId !== "N/A") {
        const hospitalRef = doc(db, "hospitals", doctorData.hospitalId);
        const hospitalSnapshot = await getDoc(hospitalRef);
  
        if (hospitalSnapshot.exists()) {
          hospitalName = hospitalSnapshot.data().hospitalName;
        }
      }
      return {
        ...doctorData,
        hospitalName,
      };
    } else {
      console.log("Doctor not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching doctor profile: ", error.message);
    return null;
  }
};

//Book Schedule Meeting
export const bookScheduleMeeting = async (scheduleData) => {
  try {
    const { doctorId, companyId, selectedTime, formattedDate, id, meetingType, assigned} =
      scheduleData;

    // Generate a unique meeting ID
    let meetingId = uuid().slice(0, 4);
    const meetingsRef = collection(db, "scheduleMeeting");
    let idExists = true;

    while (idExists) {
      const q = query(meetingsRef, where("meetingId", "==", meetingId));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        idExists = false;
      } else {
        meetingId = uuid().slice(0, 4); // Generate a new ID if the current one is taken
      }
    }

    const meetingLink = `https://meet.jit.si/${scheduleData.doctorName.replace(
      /\s+/g,
      ""
    )}-${id}-${Date.now()}`;

    const schedule = {
      companyID: companyId || id,
      doctorID: doctorId,
      date: formattedDate,
      time: selectedTime,
      meetingLink: meetingLink,
      status: "scheduled",
      assigned: id,
      meetingType: meetingType,
      meetingId: meetingId,
      assigned: assigned,
    };

    const customId = `${doctorId}_${assigned}`;
    const customDocRef = doc(db, "scheduleMeeting", customId);
    const docSnapshot = await getDoc(customDocRef);

    if (docSnapshot.exists()) {
      const existingMeeting = docSnapshot.data();

      if (existingMeeting.status === "completed") {
        // Create a new document for the completed meeting with a new ID
        const newCompletedDocRef = doc(db, "scheduleMeeting", `${customId}-${meetingId}`);
        await setDoc(newCompletedDocRef, existingMeeting);

        await setDoc(customDocRef, schedule);
      } else {
        await setDoc(customDocRef, {
          ...existingMeeting,
          date: formattedDate,
          time: selectedTime,
          meetingLink: meetingLink,
          meetingType: meetingType,
          status: "scheduled",
        });
      }
    } else {
      await setDoc(customDocRef, schedule);
    }

    return meetingLink;
  } catch (error) {
    console.error("Error scheduling meeting:", error);
    throw error;
  }
};

//send message
export const sendMessage = async (messageData) => {
  try {
    const { doctorId, companyId, message, id } = messageData;

    const messagePayload = {
      companyID: companyId || id,
      doctorID: doctorId,
      messages: message,
      sentBy: "company",
      timestamp: new Date(),
      messageId: id,
      sentId: id,
    };

    const customId = `${doctorId}_${id}_${Date.now()}`;
    const customDocRef = doc(db, "messages", customId);
    await setDoc(customDocRef, messagePayload);
  } catch (error) {
    console.error("Error sending messages:", error);
    throw error;
  }
};

//meeting
//fetch Meeting
export const fetchSalesMeetings = async (id, searchDate, searchLocation) => {
  const now = new Date();

  try {
    // Fetch user data
    const userDocRef = doc(db, "users", id);
    const userDocSnapshot = await getDoc(userDocRef);

    if (!userDocSnapshot.exists()) {
      throw new Error("User not found");
    }

    const userData = userDocSnapshot.data();
    const companyId = userData.companyId;

    // Fetch meetings assigned to the user
    const scheduleMeetingsRef = collection(db, "scheduleMeeting");
    let q = query(scheduleMeetingsRef, where("assigned", "==", id));
    const querySnapshot = await getDocs(q);

    // Fetch medical representatives in the same company
    const medicalRepQuery = query(
      collection(db, "users"),
      where("companyId", "==", companyId),
      where("role", "==", "Medical Representative")
    );

    const medicalRepQuerySnapshot = await getDocs(medicalRepQuery);
    const medicalRepIds = medicalRepQuerySnapshot.docs.map((doc) => doc.id);

    // Fetch additional meetings for all medical representatives in the same company
    let additionalQuery = query(
      scheduleMeetingsRef,
      where("assigned", "in", medicalRepIds)
    );

    const additionalQuerySnapshot = await getDocs(additionalQuery);
    // const additionalMeetings = additionalQuerySnapshot.docs.map((doc) => ({
    //   id: doc.id,
    //   ...doc.data(),
    // }));

    // Fetch meeting data
    const fetchDoctorData = async (doctorId) => {
      const doctorDocRef = doc(db, "doctors", doctorId);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      if (doctorDocSnapshot.exists()) {
        return doctorDocSnapshot.data();
      } else {
        console.error(`Doctor with ID ${doctorId} not found`);
        return null;
      }
    };

    const fetchAssignedData = async (assigned) => {
      let assignedName;
      let assignedRole;

      try {
        const companyDocRef = doc(db, "companies", assigned);
        const companyDocSnapshot = await getDoc(companyDocRef);

        if (companyDocSnapshot.exists()) {
          assignedName = companyDocSnapshot.data().name;
          assignedRole = companyDocSnapshot.data().role;
        } else {
          const userDocRef = doc(db, "users", assigned);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            assignedName = `${userData.firstName} ${userData.lastName}`;
            assignedRole = userData.role;
          } else {
            console.error(`No document found with ID ${assigned}`);
          }
        }
      } catch (error) {
        console.error("Error fetching assigned data:", error);
      }

      return { assignedName, assignedRole };
    };

    const fetchCompanyData = async (companyId) => {
      const companyDocRef = doc(db, "companies", companyId);
      const companyDocSnapshot = await getDoc(companyDocRef);
      if (companyDocSnapshot.exists()) {
        return companyDocSnapshot.data();
      } else {
        return null;
      }
    };

    const meetingsData = await Promise.all(
      [...querySnapshot.docs, ...additionalQuerySnapshot.docs].map(
        async (docSnapshot) => {
          const meetingData = docSnapshot.data();
          const doctorData = await fetchDoctorData(meetingData.doctorID);
          const companyData = await fetchCompanyData(meetingData.companyID);
          const { assignedName, assignedRole } = await fetchAssignedData(
            meetingData.assigned
          );

          // Update meeting status if necessary
          const meetingDateTime = new Date(`${meetingData.date} ${meetingData.time}`);
          const timeDiff = now - meetingDateTime;
          const hoursDiff = timeDiff / (1000 * 60 * 60);
            
          if (hoursDiff > 24 && meetingData.status !== "completed") {
            meetingData.status = "missed";
            await updateDoc(doc(db, "scheduleMeeting", docSnapshot.id), {
              status: "missed",
            });
          }

          return {
            id: docSnapshot.id,
            ...meetingData,
            doctorName: doctorData ? doctorData.name : "Unknown Doctor",
            companyName: companyData
              ? companyData.companyName
              : "Unknown Company",
            location: doctorData ? doctorData.location : "Unknown location",
            assignedName,
            assignedRole,
          };
        }
      )
    );

    // Filter and sort meetings
    const filteredMeetings = meetingsData.filter((meeting) => {
      const matchesDate =
        !searchDate ||
        format(new Date(meeting.date), "yyyy-MM-dd") === searchDate;
      const matchesLocation =
        !searchLocation ||
        meeting.location.toLowerCase().includes(searchLocation.toLowerCase());

      return matchesDate && matchesLocation;
    });

    const filteredUpcomingMeetings = filteredMeetings.filter((meeting) => {
      return meeting.status !== "missed" && meeting.status !== "completed";
    });

    const filteredMissedMeetings = filteredMeetings.filter((meeting) => {
      return meeting.status === "missed";
    });

    const filteredCompletedMeetings = filteredMeetings.filter((meeting) => {
      return meeting.status === "completed"
    });

    const sortMeetings = (meetings) =>
      meetings.sort(
        (a, b) =>
          new Date(`${a.date} ${a.time}`) - new Date(`${b.date} ${b.time}`)
      );

    return {
      upcomingMeetings: sortMeetings(filteredUpcomingMeetings),
      missedMeetings: sortMeetings(filteredMissedMeetings),
      completedMeetings: sortMeetings(filteredCompletedMeetings),
    };
  } catch (error) {
    console.error("Error fetching meetings:", error);
    return { upcomingMeetings: [], missedMeetings: [], completedMeetings: [] };
  }
};

//update schedule meeting
export const updateSalesMeetings = async (
  selectedMeetingId,
  selectedDate,
  selectedTime,
  meetingType,
) => {
  if (!selectedMeetingId) return;
  try {
    const meetingDocRef = doc(db, "scheduleMeeting", selectedMeetingId);

    const meetingDocSnapshot = await getDoc(meetingDocRef);
    if (!meetingDocSnapshot.exists()) {
      throw new Error(`Document with ID ${selectedMeetingId} does not exist`);
    }

    const adjustedDate = new Date(selectedDate);
    const ISTOffset = 330;
    adjustedDate.setMinutes(adjustedDate.getMinutes() + ISTOffset);
    const formattedDate = adjustedDate.toISOString().split("T")[0];

    await updateDoc(meetingDocRef, {
      date: formattedDate,
      time: selectedTime,
      status: "Rescheduled",
      meetingType: meetingType,
    });
  } catch (error) {
    console.error("Error updating schedule meeting:", error);
    throw error;
  }
};

//accept schedule meeting
export const acceptSalesMeetings = async (meetingId) => {
  try {
    const meetingDocRef = doc(db, "scheduleMeeting", meetingId);
    await updateDoc(meetingDocRef, { status: "Accepted" });
  } catch (error) {
    console.error("Error accepting meeting:", error);
    throw error;
  }
};

//messages
//send reply
export const sendSalesReply = async (replyMessage, currentConversation, id) => {
  try {
    if (!currentConversation || !currentConversation.doctorID) {
      throw new Error("Conversation or doctor ID not found.");
    }

    const senderId = id;

    const replyData = {
      companyID: currentConversation.companyID,
      doctorID: currentConversation.doctorID,
      messageId: currentConversation.messages[0].messageId,
      messages: replyMessage,
      sentBy: "company",
      sentId: senderId,
      timestamp: new Date(),
    };

    const customId = `${id}_${currentConversation.doctorID}_${Date.now()}`;
    const customDocRef = doc(db, "messages", customId);
    await setDoc(customDocRef, replyData);

    const timestamp = replyData.timestamp;
    const date = format(timestamp, "dd/MM/yyyy");
    const time = format(timestamp, "hh:mm:ss a");

    const newMessage = {
      messageId: replyData.messageId,
      sentId: replyData.sentId,
      id: customId,
      message: replyData.messages,
      sentBy: replyData.sentBy,
      date,
      time,
    };

    return { newMessage, replyData };
  } catch (error) {
    throw new Error(`Error sending reply: ${error.message}`);
  }
};

//fetch Message
export const fetchSalesMessage = async (id, doctorIdFromState) => {
  try {
    const userDocRef = doc(db, "users", id);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();

      const companyId = userData.companyId;

      const messageRef = collection(db, "messages");
      const q = query(messageRef, where("companyID", "==", companyId));
      const querySnapshot = await getDocs(q);

      const fetchDoctorData = async (doctorId) => {
        const doctorDocRef = doc(db, "doctors", doctorId);
        const doctorDocSnapshot = await getDoc(doctorDocRef);
        if (doctorDocSnapshot.exists()) {
          return doctorDocSnapshot.data();
        } else {
          console.error(`Doctor with ID ${doctorId} not found`);
          return null;
        }
      };
      const fetchAssignedData = async (messageId) => {
        let assignedName;

        try {
          const companyDocRef = doc(db, "companies", messageId);
          const companyDocSnapshot = await getDoc(companyDocRef);

          if (companyDocSnapshot.exists()) {
            assignedName = companyDocSnapshot.data().name;
          } else {
            const userDocRef = doc(db, "users", messageId);
            const userDocSnapshot = await getDoc(userDocRef);

            if (userDocSnapshot.exists()) {
              const userData = userDocSnapshot.data();
              assignedName = `${userData.firstName} ${userData.lastName}`;
            } else {
              console.error(`No document found with ID ${messageId}`);
            }
          }
        } catch (error) {
          console.error("Error fetching assigned data:", error);
        }

        return assignedName;
      };

      const groupedMessages = {};
      const promises = querySnapshot.docs.map(async (doc) => {
        const messageData = doc.data();
        const assignedName = await fetchAssignedData(messageData.messageId);
        const doctorData = await fetchDoctorData(messageData.doctorID);
        const doctorName = doctorData ? doctorData.name : "Unknown Doctor";

        const key = `${messageData.doctorID}_${messageData.companyID}`;
        if (!groupedMessages[key]) {
          groupedMessages[key] = {
            doctorName,
            assignedName,
            doctorID: messageData.doctorID,
            companyID: messageData.companyID,
            messages: [],
            recentMessage: {
              text: "",
              isCompany: false,
              date: "",
              time: "",
            },
          };
        }

        const timestamp = messageData.timestamp?.toDate();
        const date = timestamp ? format(timestamp, "dd/MM/yyyy") : "N/A";
        const time = timestamp ? format(timestamp, "hh:mm:ss a") : "N/A";

        groupedMessages[key].messages.push({
          messageId: messageData.messageId,
          sentId: messageData.sentId,
          id: doc.id,
          message: messageData.messages,
          sentBy: messageData.sentBy,
          date,
          time,
        });

        // Check if the current message is the most recent one
        if (
          !groupedMessages[key].recentMessage.timestamp ||
          timestamp > groupedMessages[key].recentMessage.timestamp
        ) {
          groupedMessages[key].recentMessage = {
            text: messageData.messages,
            isCompany: messageData.sentBy === "company",
            date,
            time,
            timestamp,
          };
        }
      });

      await Promise.all(promises);

      // Convert groupedMessages object to array
      const messagesArray = Object.keys(groupedMessages).map(
        (key) => groupedMessages[key]
      );
      // Sort messages by recent message timestamp
      const sortedMessages = sortMessagesByTimestamp(messagesArray);

      if (doctorIdFromState) {
        const conversation = sortedMessages.find(
          (conv) => conv.doctorID === doctorIdFromState
        );
        if (conversation) {
          return { sortedMessages, conversation };
        }
      }
      return { sortedMessages };
    }
  } catch (error) { }
};

//sorted meetings
const sortMessagesByTimestamp = (messagesArray) => {
  return messagesArray.sort((a, b) => {
    const timestampA = a.recentMessage.timestamp;
    const timestampB = b.recentMessage.timestamp;
    if (timestampA && timestampB) {
      return timestampB - timestampA;
    }
    return 0;
  });
};

//fetch meetings chart
export const fetchMeetingsChart = async (id) => {
  const db = getFirestore();
  try {
    const userDocRef = doc(db, "users", id);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const users = userDocSnapshot.data();
      const companyId = users.companyId;

      const scheduleMeetingsRef = collection(db, "scheduleMeeting");
      const q = query(scheduleMeetingsRef, where("companyID", "==", companyId));
      const querySnapshot = await getDocs(q);

      const meetingsByMonth = {};
      const missedMeetingsByMonth = {};

      querySnapshot.forEach((doc) => {
        const meetingData = doc.data();
        const meetingDate = new Date(meetingData.date);
        const month = meetingDate.toLocaleString("default", { month: "short" });
        const year = meetingDate.getFullYear();
        const key = `${month} ${year}`;

        if (!meetingsByMonth[key]) {
          meetingsByMonth[key] = 0;
        }
        meetingsByMonth[key]++;

        // Check for missed meetings
        const now = new Date();
        if (meetingDate < now && meetingData.status !== "completed") {
          if (!missedMeetingsByMonth[key]) {
            missedMeetingsByMonth[key] = 0;
          }
          missedMeetingsByMonth[key]++;
        }
      });

      return { meetingsByMonth, missedMeetingsByMonth };
    }
  } catch (error) {
    console.error("Error fetching schedule meetings:", error);
    throw error;
  }
};

//fetch par users meeting count chart
export const fetchUsersDataCountChart = async (id) => {
  const db = getFirestore();
  try {
    const userDocRef = doc(db, "users", id);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const users = userDocSnapshot.data();
      const companyId = users.companyId;

      const scheduleMeetingsRef = collection(db, "scheduleMeeting");
      const q = query(scheduleMeetingsRef, where("companyID", "==", companyId));
      const querySnapshot = await getDocs(q);

      const assignedUsersCount = {};
      querySnapshot.forEach((doc) => {
        const meetingData = doc.data();
        const assigned = meetingData.assigned;

        if (!assignedUsersCount[assigned]) {
          assignedUsersCount[assigned] = 0;
        }

        assignedUsersCount[assigned]++;
      });

      const userPromises = Object.keys(assignedUsersCount).map(
        async (userId) => {
          let assignedName = "";
          let assignedRole = "";

          try {
            const companyDocRef = doc(db, "companies", userId);
            const companyDocSnapshot = await getDoc(companyDocRef);

            if (companyDocSnapshot.exists()) {
              assignedName = companyDocSnapshot.data().name;
              assignedRole = companyDocSnapshot.data().role;
            } else {
              const userDocRef = doc(db, "users", userId);
              const userDocSnapshot = await getDoc(userDocRef);

              if (userDocSnapshot.exists()) {
                const userData = userDocSnapshot.data();
                assignedName = `${userData.firstName} ${userData.lastName}`;
                assignedRole = userData.role;
              } else {
                console.error(`No document found with ID ${userId}`);
              }
            }
          } catch (error) {
            console.error("Error fetching assigned data:", error);
          }

          return {
            userId,
            assignedName,
            assignedRole,
            count: assignedUsersCount[userId],
          };
        }
      );

      return Promise.all(userPromises);
    }
  } catch (error) {
    console.error("Error fetching assigned users data:", error);
    throw error;
  }
};

//update password
export const updateSalesPassword = async (user, oldPassword, newPassword) => {
  try {
    const credential = EmailAuthProvider.credential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
    return true;
  } catch (error) {
    console.error("Error updating password:", error);
    return false;
  }
};

//Timeline
const convertTo24HourFormat = (timeStr) => {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};

export const fetchTimelineData = async (doctorId, id) => {
  try {
    const userDocRef = doc(db, "users", id);
    const userDocSnapshot = await getDoc(userDocRef);

    if (!userDocSnapshot.exists()) {
      throw new Error(`No document found with ID ${id}`);
    }

    const userData = userDocSnapshot.data();
    const companyId = userData.companyId;

    // Fetch current user's meetings
    const meetingsQueryForCurrentUser = query(
      collection(db, "scheduleMeeting"),
      where("assigned", "==", id),
      where("doctorID", "==", doctorId)
    );
    const meetingsSnapshotForCurrentUser = await getDocs(
      meetingsQueryForCurrentUser
    );
    const currentUserMeetings = meetingsSnapshotForCurrentUser.docs.map((doc) =>
      doc.data()
    );

    // Fetch other users' meetings
    const usersQuery = query(
      collection(db, "users"),
      where("companyId", "==", companyId),
      where("role", "==", "Medical Representative")
    );
    const usersSnapshot = await getDocs(usersQuery);
    const userIds = usersSnapshot.docs.map((doc) => doc.id);

    let userMeetings = [];
    if (userIds.length > 0) {
      const userMeetingsQuery = query(
        collection(db, "scheduleMeeting"),
        where("assigned", "in", userIds),
        where("doctorID", "==", doctorId)
      );
      const userMeetingsSnapshot = await getDocs(userMeetingsQuery);
      userMeetings = userMeetingsSnapshot.docs.map((doc) => doc.data());
    }

    const meetingsData = [...currentUserMeetings, ...userMeetings];

    // Remove duplicates
    const uniqueMeetingsMap = new Map();
    meetingsData.forEach((meeting) => {
      const key = `${meeting.date}_${meeting.time}_${meeting.assigned}`;
      if (!uniqueMeetingsMap.has(key)) {
        uniqueMeetingsMap.set(key, meeting);
      }
    });
    const uniqueMeetingsData = Array.from(uniqueMeetingsMap.values());

    // Fetch assigned names and add details to meetings
    const fetchAssignedName = async (assignedId) => {
      let assignedName, assignedRole;
      const userDocRef = doc(db, "users", assignedId);
      const userDocSnapshot = await getDoc(userDocRef);
      if(userDocSnapshot.exists()){
        const userData = userDocSnapshot.data();
        assignedName = `${userData.firstName} ${userData.lastName}`;
        assignedRole = userData.role;
      }
      return {assignedName, assignedRole};
    };

    // Fetch doctor names and add details to meetings
    const fetchDoctorName = async (doctorID) => {
      const doctorDocRef = doc(db, "doctors", doctorID);
      const doctorDocSnapshot = await getDoc(doctorDocRef);
      return doctorDocSnapshot.exists()
        ? `${doctorDocSnapshot.data().name}`
        : "Unknown";
    };

    const meetingsWithDetails = await Promise.all(
      uniqueMeetingsData.map(async (data) => {
        const {assignedName, assignedRole} = await fetchAssignedName(data.assigned);
        const doctorName = await fetchDoctorName(data.doctorID);
        const time24 = convertTo24HourFormat(data.time);
        const meetingDateTime = new Date(`${data.date}T${time24}:00`);

        if (isNaN(meetingDateTime)) {
          console.error("Invalid meeting date:", meetingDateTime);
          return null;
        }

        return {
          ...data,
          assignedName,
          assignedRole,
          doctorName,
          meetingDate: meetingDateTime,
          formattedDate: format(meetingDateTime, "dd/MM/yyyy"),
          formattedTime: format(meetingDateTime, "hh:mm a"),
          isPast: meetingDateTime < new Date(),
        };
      })
    );

    return meetingsWithDetails
      .filter((meeting) => meeting !== null)
      .sort((a, b) => a.meetingDate - b.meetingDate);
  } catch (error) {
    throw new Error("Error fetching meetings: " + error.message);
  }
};

//check if meeting exist for doctor
export const checkIfMeetingExists = async (doctorId, companyId, id) => {
  const meetingsCollection = collection(db, "scheduleMeeting");
  const now = new Date();
  const q = query(meetingsCollection, where("companyID", "==", companyId), where("doctorID", "==", doctorId), where("assigned", '==', id));
  const querySnapshot = await getDocs(q);

  const meetings = querySnapshot.docs
    .map((doc) => ({ id: doc.id, ...doc.data() }))
    .filter((meeting) => {
      const meetingDateTime = new Date(`${meeting.date} ${meeting.time}`);
      return meetingDateTime > now && meeting.status !== "completed";
    });

  return meetings.length > 0 ? meetings : false;
}

export const updateMeetingStatus = async (
  selectedMeetingId,
  status,
  mrFeedback
) => {
  const meetingDocRef = doc(db, "scheduleMeeting", selectedMeetingId);
  const updateData = {
    status: status,
  };

  if (status === "completed") {
    updateData.mrFeedback = mrFeedback;
  }
  await updateDoc(meetingDocRef, updateData);
};
